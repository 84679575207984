import dayjs from 'dayjs';
import RedirectLink from '#/components/common/RedirectLink.component';
import { SSS_LOGO } from '#/constants/common/images.constants';
import {
  HeaderTitle,
  InfoText,
  LogoImg,
  LogoWrapper,
  PageContainer,
} from '../ResponsibleGambling.styled';
import { TimeoutPeriodState } from '#/utils/slices/responsibleGamblingSlice';
import { RESPONSIBLE_GAMBLING_URL } from '#/components/Footer/Footer.constants';

export default function TimeOutStates({
  appliedAt,
  duration,
}: TimeoutPeriodState) {
  // Calculate the expiration date when the user can access the app again
  const timeoutExpiryDate =
    appliedAt && duration
      ? dayjs(appliedAt).add(duration, 'second').format('DD/MM/YYYY') // Format the date
      : null;

  return (
    <div className={PageContainer}>
      <div className="w-full">
        <div className={LogoWrapper}>
          <img className={LogoImg} src={SSS_LOGO} alt="Sixsigmasports Logo" />
        </div>
        <h2 className={HeaderTitle}>
          TIME
          <br />
          OUT
        </h2>
        <div className="mt-6">
          <p className={`${InfoText} mb-4`}>
            {timeoutExpiryDate
              ? `Remember you asked us for a Time Out from the application. You will be able to access the application again on `
              : `It looks like your Time Out has expired or no Time Out is currently active.`}
            {timeoutExpiryDate && (
              <span className="text-text-brand-1">{timeoutExpiryDate}</span>
            )}
            .
          </p>
          <p className={`${InfoText} !font-light`}>
            Always gamble responsibly and take a moment to review our
            responsible gambling resources we have available here:
            <br />
            <RedirectLink
              className="text-text-brand-1"
              href={RESPONSIBLE_GAMBLING_URL}
              target="_blank"
              rel="noreferrer"
              dataId="responsible_gambling_resource_link"
            >
              {RESPONSIBLE_GAMBLING_URL}
            </RedirectLink>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
