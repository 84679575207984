export interface BetOddSelected {
  $isSelected?: boolean;
  $hasCapacityReached?: boolean;
  $isUpdated?: boolean;
  $isMainMarketCard: boolean;
}
export const OddValueTextHovered = `hidden lg:group-hover:block`;
//  Style for V2
export const OddValueStyledMobile = `group rounded-lg w-24 px-2 pt-1.5 pb-1 flex justify-center items-center text-sm bg-card-bg-2 text-text-gray-2 font-semibold mr-0`;
export const OddValueFlexText = `flex items-center`;
export const ArrowIcon = `size-2.5 3xl:size-3.5 mr-1`;
export const OddButtonWrapper = `flex items-center lg:cursor-pointer relative flex-1 w-full`

export const OddLabelButtonStyleMobile = ({
  $isSelected, $isDisabled }: { $isSelected: boolean, $isDisabled: boolean }) => `rounded-md w-full px-2 pt-2 pb-1.5 xl:py-3 flex flex-col justify-center items-center text-sm 3xl:text-base 4xl:text-lg 5xl:text-xl 6xl:text-2xl bg-black text-white font-semibold mr-0 flex-1 ${$isSelected ? 'bg-selected-color-1' : ''} ${$isDisabled ? 'opacity-25' : ''}`;
export const OddButtonStyleMobile =
  'rounded-md w-full xs:w-20 h-10 px-3 xs:px-2 pt-1.5 pb-1 flex flex-col justify-center items-center text-sm bg-black text-text-gray-2 font-semibold mr-0';
