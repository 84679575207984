import {
  HouseParamContainer,
  HouseParamItems,
  MarketCardContainer,
  MarketHouseButton,
  ParamIcon,
  ParamIconBet,
  ParamItemsText,
  ParamValueItemsText,
  ParamValueWrapper,
  SgeText,
} from '../MatchCard/MatchCard.styled';
import Button from '#/components/common/Buttons/Button/Button.component';
import {
  BET_AMOUNT_ICON_BRAND,
  LIQUIDITY_ICON_BRAND,
  NO_OF_BETS_ICON,
} from '#/constants/common/images.constants';
// import { useNavigate } from 'react-router-dom';
import { FixtureSummary, MarketLiveData } from '#/utils/backend';
import { useAppSelector } from '#/utils/store';

type MatchHouseDetailsProps = {
  market: FixtureSummary;
  addHouseSlip: (market: FixtureSummary) => void;
};

export default function MatchHouseDetails({
  market,
  addHouseSlip,
}: MatchHouseDetailsProps) {
  // const navigate = useNavigate();
  const markets = useAppSelector((state) => state.markets.markets);
  const marketData : MarketLiveData | undefined = markets[market.id];
  const marketStatus = marketData ? marketData.status : 'Aborted';

  const isMarketDisabled = marketStatus !== 'Operational';

  return (
    <div className={MarketCardContainer}>
      <div className={HouseParamContainer}>
        <div className={HouseParamItems}>
          <div className="flex items-center">
            <span className="mr-2">
              <img className={ParamIconBet} src={NO_OF_BETS_ICON} alt="" />
            </span>
            <span className={ParamItemsText}>Number of Bets</span>
            <span className={`${ParamValueItemsText} ml-2`}>
              {market.houseProvision.TotalBets}
            </span>
          </div>
        </div>

        <div className={HouseParamItems}>
          <div className="flex items-center">
            <span className="mr-2">
              <img
                className={ParamIcon}
                src={BET_AMOUNT_ICON_BRAND}
                alt=""
              />
            </span>
            <span className={ParamItemsText}>Bet Amount</span>
            <div className={`${ParamValueWrapper} ml-2`}>
              <span className={ParamValueItemsText}>
                {market.houseProvision.TotalBetAmount}
              </span>
              <span className={SgeText}>SGE</span>
            </div>
          </div>
        </div>
        <div className={HouseParamItems}>
          <div className="flex items-center">
            <span className="mr-2">
              <img
                className={ParamIcon}
                src={LIQUIDITY_ICON_BRAND}
                alt=""
              />
            </span>
            <span className={ParamItemsText}>Committed Liquidity</span>
            <div className={`${ParamValueWrapper} ml-2`}>
              <span className={ParamValueItemsText}>
                {market.houseProvision.TotalLiquidity}
              </span>
              <span className={SgeText}>SGE</span>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:flex lg:space-x-4">
        <div className="mb-4 xl:mb-0">
          <Button
            className={`${MarketHouseButton} ripple`}
            disabled={isMarketDisabled}
            onClick={() => addHouseSlip(market)}
            dataId="add_house_slip"
          >
            BE THE HOUSE
          </Button>
        </div>
        {/* <div>
          <Button
            className={MarketHouseButton}
            onClick={() => navigate(`/match-detail/${fixtureID}`)}
            dataId="view_match_details"
          >
            VIEW ALL MARKETS
          </Button>
        </div> */}
      </div>
    </div>
  );
}
