import { useCallback, useEffect, useState, useMemo } from 'react';
import SportsFilter from '#/components/SportsFilter';
import ExploreMatchSpan from '#/components/common/ExploreButton/ExploreButton.component';
import Search from '#/components/common/Search/Search.component';
import SearchResults from '#/components/common/Search/SearchResults';
import { SPORTS_LIST } from '#/constants/common/global-constants';
import Filters, { ExploreFilterContents } from '../Filters/Filters.component';
import ScheduleFilters from '../common/ScheduleFilters';
import { useAppDispatch, useAppSelector } from '#/utils/store';
import {
  removeLeague,
  setDateRange,
  setSearch,
  setSearchSport,
} from '#/utils/slices/searchSlice';
import { setSport } from '#/utils/slices/sportSlice';
import AppliedFilters from '../AppliedFiltersPanel';
import { getTodayRange } from '#/utils/datetime/datetime';
import { Helmet } from 'react-helmet-async';
import { stringToSport } from '#/utils/backend';
import FilterButton from '../common/FilterButton.component';
import {
  DropDownInnerWrapper,
  DropDownStyle,
  FilterBtn,
  FiltersWrapper,
} from '../Filters/FiltersButton.styled';
import { useLocation } from 'react-router-dom';
import { DEFAULT_SPORT } from '#/App';
import useClickOutside from '#/hooks/useClickOutside';
import { isProductionEnv } from '#/utils/env';
import { gtmEvent } from '#/utils/appUtils';

export const FILTER_TYPES = {
  ALL: 'all',
  TODAY: 'today',
  TOMORROW: 'tomorrow',
  UPCOMING: 'upcoming',
};

export default function Explore() {
  const [showFilters, setShowFilters] = useState(false);
  const [showLeaguesDropdown, setShowLeaguesDropdown] = useState(false);

  const dispatch = useAppDispatch();
  const searchQuery = useAppSelector((state) => state.search);
  const currentSport = useAppSelector((state) => state.sport.sport) || DEFAULT_SPORT;

  const { pathname } = useLocation();

  const sport = useMemo(() => {
    const pathSegments = pathname.split('/').filter(Boolean);
    const sports = SPORTS_LIST.map((sport) => sport.sport_uid.toLowerCase());
    return sports.some((s) => pathname.includes(s))
      ? stringToSport(pathSegments[1]) || DEFAULT_SPORT
      : currentSport;
  }, [pathname, currentSport]);

  useEffect(() => {
    if (sport && (sport === 'Cricket' || sport === 'Soccer')) {
      dispatch(setSearchSport(sport));
      dispatch(setSport(sport));
    }
  }, [sport, dispatch]);

  // Set default filter to 'today' when component mounts
  useEffect(() => {
    const now = new Date();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { startsAfter, startsBefore } = getTodayRange(now, timezone);
    if (searchQuery.filters.leagueIds.length > 0) {
      dispatch(
        setDateRange({
          startsAfter,
          startsBefore: null,
        }),
      );
    } else {
      dispatch(
        setDateRange({
          startsAfter,
          startsBefore,
        }),
      );
    }
  }, [dispatch, searchQuery.filters.leagueIds]);


  const filters = useMemo(() => searchQuery.leagues.map((league) => ({
    id: league.Id,
    label: league.Name,
  })), [searchQuery.leagues]);

  const handleRemoveFilter = useCallback(
    (id: number) => {
      dispatch(removeLeague(id));
    },
    [dispatch]
  );

  const setSearchInput = useCallback(
    (input: string) => {
      dispatch(setSearch(input));
    },
    [dispatch],
  );

  const sportTitle = useMemo(() => (
    searchQuery.sport?.replace('Soccer', 'Football') || 'Football'
  ), [searchQuery.sport]);

  const toggleLeaguesDropdown = () => {
    setShowLeaguesDropdown((prev) => !prev);
    gtmEvent({ action: "toggle_leagues_clicked"})
  };

  const filtersDropdownRef = useClickOutside<HTMLDivElement>(
    () => setShowLeaguesDropdown(false)
  );

  return (
    <div>
      <Helmet>
        <title>{`Explore ${sportTitle} | SixSigmaSports`}</title>
        {!isProductionEnv() && <meta name="robots" content="noindex" />}
      </Helmet>
      <div className="px-4 pb-4">
        <SportsFilter
          sportlist={SPORTS_LIST}
          selectedSport={sport}
          basePath={`/${pathname.split('/').filter(Boolean)[0]}`}
        />
      </div>
      <div className="px-4 pb-4">
        <ExploreMatchSpan />
      </div>
      <div className="px-4 pb-4">
        <div className="flex items-center mb-4 lg:justify-end">
          <Search
            search={searchQuery.search}
            setSearch={setSearchInput}
            showSearchResults={true}
          />
          <span className="lg:hidden">
            <FilterButton onClick={() => {setShowFilters(!showFilters); gtmEvent({ action: "filter_button_clicked"});}} />
          </span>
          <div className={FiltersWrapper}>
            <span className="relative">
              <span
                className={FilterBtn}
                tabIndex={0}
                role="button"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    toggleLeaguesDropdown();
                  }
                }}
                onClick={toggleLeaguesDropdown}
              >
                Leagues
              </span>
              {showLeaguesDropdown && (
                <div className={DropDownStyle} ref={filtersDropdownRef}>
                  <div className={DropDownInnerWrapper}>
                    <ExploreFilterContents onClose={() => setShowFilters(false)} />
                  </div>
                </div>
              )}
            </span>
          </div>
        </div>
        {searchQuery.search.length ? (
          <SearchResults />
        ) : (
          <>
            <ScheduleFilters />
            <AppliedFilters
              filters={filters}
              onRemoveFilter={handleRemoveFilter}
            />
            <div className="pt-4">
              <SearchResults />
            </div>
          </>
        )}
      </div>
      {showFilters && (
        <Filters isOpen={showFilters} onClose={() => setShowFilters(false)} />
      )}
    </div>
  );
}
