import { useInfiniteQuery } from '@tanstack/react-query';
import { HouseActivityOutcomes, loadInfiniteLiquidityData } from '../backend';

export interface BetFilters {
  ids?: string[];
  marketTypes?: number[];
  states?: HouseActivityOutcomes[];
  sports?: string[];
}
interface UseLiquidityDataLoaderParams {
  userId: string;
  sgeAddress: string;
  filters?: BetFilters;
  search?: string;
}
const useInfiniteLiquidityDataLoader = ({
  userId,
  sgeAddress,
  search = '',
  filters,
}: UseLiquidityDataLoaderParams) => {
  return useInfiniteQuery({
    queryKey: ['liquidityDataLoader', userId, filters, search],
    enabled: !!userId && !!sgeAddress,
    queryFn: async ({ pageParam = 0 }) => {
      if (userId && sgeAddress) {
        return loadInfiniteLiquidityData({ userId, offset: pageParam, filters, search });
      }
      return { provisions: [], total: 0, offset: pageParam };
    },
    getNextPageParam: (lastPage, allPages) => {
      const totalLoaded = allPages.reduce((acc, page) => acc + page.provisions.length, 0);
      if (totalLoaded < lastPage.total) {
        return totalLoaded;
      } else {
        return undefined;
      }
    },
    initialPageParam: 0,
  });
};

export default useInfiniteLiquidityDataLoader;
