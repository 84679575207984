import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type State = {
  open: boolean
};

const initialState: State = {
  open: false,
};

const slice = createSlice({
  name: 'kado',
  initialState,
  reducers: {
    setOpen: (state, data: PayloadAction<boolean>) => {
      state.open = data.payload
    },
  },
});

export const { setOpen } = slice.actions;
export default slice.reducer;
