import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useAppSelector } from '#/utils/store';
import env from '#/utils/env';
import { MAIN_MARKET_BANNER_TEXT } from '#/constants/common/global-constants';
import { MAINNET_CHAIN_ID } from '#/utils/chains';
import useBackendUserData from '#/utils/dataLoaders/useBackendUserData';
import useAuth0UserData from '#/utils/dataLoaders/useAuth0UserData';

const useSentryUser = () => {
  const { data : user } = useBackendUserData()
  const { data : auth0User } = useAuth0UserData()

  useEffect(() => {
    if(user && auth0User) {
      const defaultSentryUser = {
        id: user.id,
        username: auth0User.nickname || '',
        name: auth0User.name || '',
        sgeAddress: user.walletAddr || '',
        oddsFormat: user.oddsFormat,
        kycStatus: user.kycStatus,
        isEmailVerified: !!auth0User.email_verified,
      };

      // redact email on mainnet
      const sentryUser =
        env.chain?.chain_id === MAINNET_CHAIN_ID
          ? defaultSentryUser
          : {
              email: auth0User.email || '',
              ...defaultSentryUser,
            };

      Sentry.setUser(sentryUser);
    } else {
      Sentry.setUser(null);
    }
  }, [user, auth0User]);
};

export default useSentryUser;
