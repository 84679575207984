/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  CheckInput,
  HeaderWrapper,
  Title,
  HeadDivider,
  FilterCardContainer,
  MarketItemContainer,
  MarketLabel,
} from '#/components/FilterCards/FilterCards.styled';
import { SPORTS_LIST } from '#/constants/common/global-constants';
import ActionTag from '../common/ActionTags.component';
import { SportEnumValues, stringToSportEnum } from '#/utils/backend';
import { isMobile } from '#/utils/platform';
import { useDispatch } from 'react-redux';
import { setSportFilters, toggleSportFilterAndSynchUrl } from '#/utils/slices/activityFiltersSlice';
import { useSearchParams } from 'react-router-dom';

// Convert SPORTS_LIST to SportEnums values
const sportsOptions = SPORTS_LIST.map((sport) =>
  stringToSportEnum(sport.name),
).filter((sport): sport is SportEnumValues => sport !== undefined);

const SportsFilterModal = ({
  localSportFilters,
  setLocalSportFilters,
}: {
  localSportFilters: SportEnumValues[];
  setLocalSportFilters: (filters: SportEnumValues[]) => void;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch()
  const handleSportChange = (sport: SportEnumValues) => {
    const updatedFilters = localSportFilters.includes(sport)
      ? localSportFilters.filter((s) => s !== sport)
      : [...localSportFilters, sport];
    if (isMobile) {
      setLocalSportFilters(updatedFilters);
    } else {
      dispatch(setSportFilters(updatedFilters));
      dispatch(
        toggleSportFilterAndSynchUrl({
          sport: updatedFilters,
          searchParams,
          setSearchParams,
        })
      );
    }
  };

  return (
    <>
      <div className={HeaderWrapper}>
        <span className={Title}>Sport</span>
      </div>
      <hr className={HeadDivider} />
      <div className={FilterCardContainer}>
        <ul>
          {sportsOptions.map((sport) => (
            <ActionTag
              type="li"
              dataId="sport_filter_change"
              className={MarketItemContainer}
              key={sport}
              onClick={() => handleSportChange(sport)}
            >
              <input
                className={`${CheckInput} !mr-0`}
                type="checkbox"
                id={sport}
                checked={localSportFilters.includes(sport)}
                onChange={() => { }} // No need to handle change directly here
              />
              <span className={MarketLabel}>{sport}</span>
            </ActionTag>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SportsFilterModal;
