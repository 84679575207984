export const convertTimeLimitToMs = (
  timeLimitOption: string | undefined,
): number | null => {
  switch (timeLimitOption) {
    case 'Every 30 Mins':
      return 30 * 60 * 1000; // 30 minutes in milliseconds
    case 'Every 1 Hour':
      return 60 * 60 * 1000; // 1 hour in milliseconds
    case '1 Day':
      return 24 * 60 * 60 * 1000; // 1 day in milliseconds
    case 'None':
      return null; // Return null for 'None' (no limit)
    default:
      return null; // Fallback for unexpected values
  }
};

export const convertTimeoutToSeconds = (
  timeout: string | null,
): number | null => {
  switch (timeout) {
    case '15 Days':
      return 15 * 24 * 60 * 60;
    case '1 Month':
      return 30 * 24 * 60 * 60;
    case '1 Year':
      return 365 * 24 * 60 * 60;
    case 'None':
      return null;
    default:
      return null;
  }
};

export const convertSecondsToTimeout = (ms: number | null): string | null => {
  switch (ms) {
    case 15 * 24 * 60 * 60:
      return '15 Days';
    case 30 * 24 * 60 * 60:
      return '1 Month';
    case 365 * 24 * 60 * 60:
      return '1 Year';
    case null:
      return 'None';
    default:
      return null;
  }
};

export const mapNumberToFrequency = (value: number | null): string => {
  switch (value) {
    case 30 * 60 * 1000: // 30 minutes in ms
      return 'Every 30 Mins';
    case 60 * 60 * 1000: // 1 hour in ms
      return 'Every 1 Hour';
    case 24 * 60 * 60 * 1000: // 1 day in ms
      return '1 Day';
    case null:
      return 'None'; // None case
    default:
      return 'None'; // Default if value is not recognized
  }
};

export const mapMessagesToFrequency = (value: number | null): string => {
  switch (value) {
    case 30 * 60 * 1000: // 30 minutes in ms
      return '30 Mins';
    case 60 * 60 * 1000: // 1 hour in ms
      return '1 Hour';
    case 24 * 60 * 60 * 1000: // 1 day in ms
      return '1 Day';
    case null:
      return 'None'; // None case
    default:
      return 'None'; // Default if value is not recognized
  }
};
