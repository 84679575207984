import env from '../utils/env';

const csp = {
  defaultSrc: [
    "'self'",
    'https://verify.synaps.io',
    'https://static.zdassets.com',
    'https://ekr.zdassets.com',
    'https://ekr.zendesk.com',
    'https://*.zopim.com',
    'https://zendesk-eu.my.sentry.io',
    'wss://*.zopim.com',
    'https://saagelabslimited.zendesk.com',
    'wss://saagelabslimited.zendesk.com',
    'https://api.smooch.io',
    'https://media.smooch.io',
    'https://*.twilio.com',
    'wss://api.smooch.io',
    'wss://voice-js.roaming.twilio.com',
    'https://fonts.googleapis.com',
    'https://verify.walletconnect.org',
    'https://*.cookiebot.com/',
    'https://fonts.gstatic.com',
    'https://app.kado.money',
  ],
  imgSrc: [
    "'self'",
    'https://*.gravatar.com',
    'https://*.wp.com',
    'https://v2assets.zopim.io',
    'https://static.zdassets.com',
    'data:',
    'https://accounts.zendesk.com',
    'https://saagelabslimited.zendesk.com',
    'https://media.smooch.io',
    'https://*.zdusercontent.com',
    'https://*.snippet.anjouangaming.org',
    'blob:',
    'https://*.googletagmanager.com', 
    'https://ssl.gstatic.com', 
    'https://www.gstatic.com',
    'https://fonts.gstatic.com/',
    'https://*.cookiebot.com/'
  ],
  scriptSrc: [
    "'unsafe-eval'",
    "'unsafe-inline'",
    "'self'",
    env.apiServer,
    env.auth0.domain,
    ...(env.chain?.apis?.rest?.map((rest) => rest.address) ?? []),
    ...(env.chain?.apis?.rpc?.map((rpc) => rpc.address) ?? []),
    'https://browser.sentry-cdn.com',
    'https://js.sentry-cdn.com',
    'https://static.zdassets.com',
    'https://ekr.zdassets.com',
    'https://ekr.zendesk.com',
    'https://*.zopim.com',
    'https://*.snippet.anjouangaming.org',
    "https://tagmanager.google.com",
    "https://*.googletagmanager.com",
    'https://*.cookiebot.com/'
  ],
  styleSrc: [
    "'self'", 
    "'unsafe-inline'", 
    'https://fonts.googleapis.com', 
    'https://tagmanager.google.com', 
    "https://*.googletagmanager.com"
  ],
  connectSrc: [
    "'self'",
    'https://rpc.cosmos.directory',
    env.apiServer,
    env.auth0.domain,
    ...(env.chain?.apis?.rest?.map((rest) => rest.address) ?? []),
    ...(env.chain?.apis?.rpc?.map((rpc) => rpc.address) ?? []),
    '*.sentry.io',
    'https://explorer-api.walletconnect.com',
    'https://static.zdassets.com',
    'https://ekr.zdassets.com',
    'https://ekr.zendesk.com',
    'https://*.zopim.com',
    'wss://*.zopim.com',
    'https://saagelabslimited.zendesk.com',
    'https://api.smooch.io',
    'https://media.smooch.io',
    'https://*.twilio.com',
    'wss://api.smooch.io',
    'wss://voice-js.roaming.twilio.com',
    'wss://relay.walletconnect.org',
    'https://*.google-analytics.com',
    'https://*.googletagmanager.com',
    'https://*.cookiebot.com'
  ],
};

export default csp;
