import Bowser from 'bowser';

const bowserData = Bowser.parse(window.navigator.userAgent);

export const isMobile = bowserData.platform.type === 'mobile';

export default bowserData;

export const LEAP_CHROME_EXTENSION =
  'https://chromewebstore.google.com/detail/leap-cosmos-wallet/fcfcfllfndlomdhbehjjcoimbgofdncg';

export const KEPLR_CHROME_EXTENSION =
  'https://chromewebstore.google.com/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap';
export const KEPLR_FIREFOX_EXTENSION =
  'https://addons.mozilla.org/en-US/firefox/addon/keplr/';

export const KEPLR_ANDROID_APP =
  'https://play.google.com/store/apps/details?id=com.chainapsis.keplr';
export const KEPLR_IOS_APP =
  'https://apps.apple.com/us/app/keplr-wallet/id1567851089';

export const LEAP_ANDROID_APP =
  'https://play.google.com/store/apps/details?id=io.leapwallet.cosmos';
export const LEAP_IOS_APP =
  'https://apps.apple.com/us/app/leap-cosmos/id1642465549';
