import { useSearchParams } from 'react-router-dom';
import { ActivityTabIcon, TabsItems, TabsWrapper } from './ActivityTab.styled';
import {
  BETS_ICON,
  BETS_ICON_DARK,
  HOUSE_ICON_DARK,
  HOUSE_ICON_LIGHT,
} from '#/constants/common/images.constants';
import Button from '#/components/common/Buttons/Button/Button.component';
import { BET_ACTIVITY, HOUSE_ACTIVITY, OUTCOME_OPTIONS, Tab } from '#/components/routes/MyBets';
import { useAppDispatch } from '#/utils/store';
import { setOutcomeFilters } from '#/utils/slices/activityFiltersSlice';

export default function ActivityTab() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get('tab') || BET_ACTIVITY;

  const handleTabClick = (tab: Tab) => {
    setSearchParams({
      tab,
      subItem: 'active'
    });
    dispatch(setOutcomeFilters(OUTCOME_OPTIONS[tab]['active'])); // No need to toggle here.
  };

  return (
    <>
      <div className={`${TabsWrapper} mb-4`}>
        <Button
          className={TabsItems({ isActive: activeTab === BET_ACTIVITY })}
          onClick={() => handleTabClick(BET_ACTIVITY)}
          dataId="bet_activity_tab"
        >
          <span>
            <img
              className={ActivityTabIcon}
              src={activeTab === BET_ACTIVITY ? BETS_ICON_DARK : BETS_ICON}
              alt="Bet Activity Icon"
            />
          </span>
          <span>Bet Activity</span>
        </Button>
        <Button
          className={TabsItems({ isActive: activeTab === HOUSE_ACTIVITY })}
          onClick={() => handleTabClick(HOUSE_ACTIVITY)}
          dataId="house_activity_tab"
        >
          <span>
            <img
              className={ActivityTabIcon}
              src={
                activeTab === HOUSE_ACTIVITY
                  ? HOUSE_ICON_DARK
                  : HOUSE_ICON_LIGHT
              }
              alt="House Activity Icon"
            />
          </span>
          <span>House Activity</span>
        </Button>
      </div>
    </>
  );
}
