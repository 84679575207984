import { toast } from 'react-toastify';
import { AppToast } from '../common/ToastNotification/ToastConfig';
import Deposit from '../Deposit/Deposit.component';
import { copyToClipboard } from '#/utils/string/string';
import EmptyList from '../common/EmptyStates/EmptyState.component';
import useCosmosKitWallet from '#/hooks/useCosmosKitWallet';
import { Helmet } from 'react-helmet-async';
import { isProductionEnv } from '#/utils/env';

export default function WalletDeposit() {
  const { account } = useCosmosKitWallet();

  async function copyAddress(address: string) {
    await copyToClipboard(address);
    toast.error(<AppToast id="AddressCopiedSuccess" />, { icon: false });
  }

  if (!account) {
    return <EmptyList id="walletDeposit" />;
  }

  return (
    <div className="px-4">
      <Helmet>
        <title>{`Deposit | SixSigmaSports`}</title>
        {!isProductionEnv() ? <meta name="robots" content="noindex" /> : null}
      </Helmet>
      <Deposit address={account.address} copyAddress={copyAddress} />
    </div>
  );
}
