import { MEDIUM_LOGO, TELEGRAM_LOGO, TWITTER_LOGO } from "#/constants/common/images.constants";

export const footerText = (year: number): string => {
  return `© ${year} SixSigmaSports and affiliated companies. All rights reserved.`;
};

export const IMG_ALT_TEXT = 'Icon';
export const REQUIRMENT_TEXT = 'Requirements';
export const LICENSE_TEXT = 'License';
export const USEFUL_LINK = 'Useful Links';
export const JOIN_COMMUNITY = 'Join the community';
export const HELP_TEXT = 'Help';
export const CONTACT_TEXT = 'Contact Us';
export const RESPONSIBLE_GAMBLING = 'Responsible Gambling';
export const PRIVACY_POLICY = 'Privacy Policy';
export const COOKIES_TEXT = 'Cookies Settings';
export const TERMS_TEXT = 'Terms & Conditions';
export const FAQ_TEXT = 'FAQ';
export const BETTING_TEXT = 'Betting Rules';
export const BETTING_RULES_URL =
  'https://support.sixsigmasports.app/hc/en-us/articles/4521811266719--Soccer-Betting-Rules';
export const TERMS_URL =
  'https://support.sixsigmasports.app/hc/en-us/articles/4536312825119-Terms-Conditions';
export const RESPONSIBLE_GAMBLING_URL =
  'https://support.sixsigmasports.app/hc/en-us/articles/4521860086303-Responsible-Gambling-Resources';
export const CONTACT_URL = 'https://forms.gle/ccXaW4U4q5C3zvN1A';
export const DOCS_TEXT = 'Docs';
export const DOCS_URL = 'https://support.sixsigmasports.app/hc/en-us';
export const FAQS_URL = 'https://support.sixsigmasports.app/hc/en-us/articles/4537085201183-FAQ';
export const PRIVACY_POLICY_URL =
  'https://support.sixsigmasports.app/hc/en-us/articles/4536290821279-Privacy-Policy';

export const SocialLinksData = [
  {
    href: 'https://sixsigmasports.medium.com/',
    dark_image: MEDIUM_LOGO,
    is_button: false,
    label: 'medium',
  },
  {
    href: 'https://t.me/SixSigmaSports',
    dark_image: TELEGRAM_LOGO,
    isButton: false,
    label: 'telegram',
  },
  {
    href: 'https://twitter.com/SixSigmaSports',
    dark_image: TWITTER_LOGO,
    isButton: false,
    label: 'twitter',
  }
];
