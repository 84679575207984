import { SportEnums, loadHomepageData, outcomeId } from '#/utils/backend';
import { useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '../store';
import { Outcomes, updateOutcomes } from '../slices/outcomesSlice';
import { Markets, updateMarkets } from '../slices/marketsSlice';

const useHomepageData = ({
  sport,
  enabled = true,
}: {
  sport: SportEnums;
  enabled?: boolean;
}) => {
  const dispatch = useAppDispatch();

  return useQuery({
    queryKey: ['homepage', sport],
    queryFn: async () => {
      return loadHomepageData(sport).then((homepageData) => {
        const newOutcomes: Outcomes = {};
        const newMarkets: Markets = {};

        if(homepageData.marketOfTheDay) {
          newMarkets[homepageData.marketOfTheDay.id] = {
            status: homepageData.marketOfTheDay.marketStatus,
            acceptable_provision_size: homepageData.marketOfTheDay.acceptableProvisionSize
          }
        }

        Object.values(homepageData.marketOfTheDay?.odds ?? {}).forEach(
          (odds) => {
            newOutcomes[outcomeId(odds)] = {
              value: odds.value,
              movement: odds.movement,
              acceptable_bet_size: odds.acceptableBetSize,
            };
          },
        );

        homepageData.featured.forEach((featured) => {
          newMarkets[featured.id] = {
            status: featured.marketStatus,
            acceptable_provision_size: featured.acceptableProvisionSize
          }
          
          Object.values(featured.odds).forEach((odds) => {
            newOutcomes[outcomeId(odds)] = {
              value: odds.value,
              movement: odds.movement,
              acceptable_bet_size: odds.acceptableBetSize,
            };
          });
        });

        homepageData.trending.forEach((trending) => {
          newMarkets[trending.id] = {
            status: trending.marketStatus,
            acceptable_provision_size: trending.acceptableProvisionSize
          }

          Object.values(trending.odds).forEach((odds) => {
            newOutcomes[outcomeId(odds)] = {
              value: odds.value,
              movement: odds.movement,
              acceptable_bet_size: odds.acceptableBetSize,
            };
          });
        });

        homepageData.today.forEach((today) => {
          newMarkets[today.id] = {
            status: today.marketStatus,
            acceptable_provision_size: today.acceptableProvisionSize
          }

          Object.values(today.odds).forEach((odds) => {
            newOutcomes[outcomeId(odds)] = {
              value: odds.value,
              movement: odds.movement,
              acceptable_bet_size: odds.acceptableBetSize,
            };
          });
        });

        dispatch(updateMarkets(newMarkets));
        dispatch(updateOutcomes(newOutcomes));

        return homepageData;
      });
    },
    enabled,
  });
};

export default useHomepageData;
