/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  CheckInput,
  FilterCardContainer,
  HeadDivider,
  HeaderWrapper,
  MarketItemContainer,
  MarketLabel,
  Title,
} from '#/components/FilterCards/FilterCards.styled';
import { ActivityOutcome } from '#/utils/backend';
import { isMobile } from '#/utils/platform';
import { useDispatch } from 'react-redux';
import ActionTag from '../ActionTags.component';
import { setOutcomeFilters, toggleOutcomeFilterAndSynchUrl } from '#/utils/slices/activityFiltersSlice';
import { useSearchParams } from 'react-router-dom';
import { BET_ACTIVITY, OUTCOME_OPTIONS } from '#/components/routes/MyBets';

const OutcomeFilter = ({
  localOutcomeFilters,
  setLocalOutcomeFilters,
}: {
  localOutcomeFilters: ActivityOutcome[];
  setLocalOutcomeFilters: (filters: ActivityOutcome[]) => void;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch()
  const handleOutcomeChange = (outcome: ActivityOutcome) => {
    const updatedFilters = localOutcomeFilters.includes(outcome)
      ? localOutcomeFilters.filter((o) => o !== outcome)
      : [...localOutcomeFilters, outcome];
    if (isMobile) {
      setLocalOutcomeFilters(updatedFilters);
    } else {
      dispatch(setOutcomeFilters(updatedFilters));
      dispatch(
        toggleOutcomeFilterAndSynchUrl({
          outcomeFilters: updatedFilters,
          searchParams,
          setSearchParams,
        })
      );
    }
  };



  return (
    <>
      <div className={HeaderWrapper}>
        <span className={Title}>Outcome</span>
      </div>
      <hr className={HeadDivider} />
      <div className={FilterCardContainer}>
        <ul>
          {OUTCOME_OPTIONS[BET_ACTIVITY].settled.map((outcome) => (
            <ActionTag
              type="li"
              className={MarketItemContainer}
              key={outcome}
              onClick={() => handleOutcomeChange(outcome)}
              dataId="outcome_filter_option"
            >
              <input
                className={`${CheckInput} !mr-0`}
                type="checkbox"
                id={outcome}
                checked={localOutcomeFilters.includes(outcome)}
                onChange={() => { }} // No need to handle change directly here
              />
              <span className={MarketLabel}>{outcome}</span>
            </ActionTag>
          ))}
        </ul>
      </div>
    </>
  );
};

export default OutcomeFilter;
