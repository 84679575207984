/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { loadNotifications } from '#/utils/backend';
// import { DEFAULT_PAGE_SIZE } from '#/constants/common/global-constants';
import { toast } from 'react-toastify';
import NotificationsFilterTabs from '../Notifications/NotificationsFilterTabs.component';
import NotificationsCard from '../Notifications/NotificationsCard.component';
import { AppToast } from '../common/ToastNotification/ToastConfig';

import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet-async';
import useAccessToken from '#/utils/dataLoaders/useAccessToken';
import useBackendUserData from '#/utils/dataLoaders/useBackendUserData';
import { isProductionEnv } from '#/utils/env';

export default function Notifications() {
  const { data: accessToken } = useAccessToken()
  const { data: user } = useBackendUserData()

  const [selectedFilter, setSelectedFilter] = useState<string>('');

  const { ref, inView } = useInView();

  const queryClient = useQueryClient();

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['notifications', user?.id, selectedFilter],
    enabled: !!user,
    queryFn: async (ctx) => {
      if (user && accessToken) {
        return loadNotifications(accessToken, ctx.pageParam, selectedFilter)
          .then((notificationsData) => {
            const { notifications, offset, remaining } = notificationsData;

            return {
              rows: notifications,
              nextOffset:
                notifications.length === 0 || remaining === 0
                  ? undefined
                  : offset + notifications.length,
            };
          });
      } else {
        return {
          rows: [],
          nextOffset: 0
        }
      }
    },
    getNextPageParam: (lastGroup) => lastGroup.nextOffset,
    initialPageParam: 0,
  });

  const notifications = data ? data.pages.flatMap((d) => d.rows) : [];

  useEffect(() => {
    if (error?.message) {
      toast.error(<AppToast id={'UnableToFetch'} />, { icon: false });
    }
  }, [error?.message]);

  useEffect(() => {
    queryClient.setQueryData(
      ['notifications'],
      (oldData: Notification[] | undefined) => {
        if (!oldData) return undefined;

        return {
          ...oldData,
          pages: [],
          pageParams: [],
        };
      },
    );

    refetch();
  }, [selectedFilter]);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView, hasNextPage, isFetchingNextPage]);

  return (
    <div className="px-4">
      <Helmet>
        <title>{`Notifications | SixSigmaSports`}</title>
        {!isProductionEnv() ? <meta name="robots" content="noindex" /> : null}
      </Helmet>
      <NotificationsFilterTabs
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
      <NotificationsCard notifications={notifications} isLoading={isFetching} />
      <div ref={ref} />
    </div>
  );
}
