import { assets } from 'chain-registry';
import env from './env';
import { MAINNET_CHAIN_NAME } from './chains';

const chainAssets: typeof assets = [];
const chain = env.chain;

if (chain) {
  const chainAsset = assets.find(
    (asset) => asset.chain_name === chain.chain_name,
  );

  if (chainAsset) {
    chainAssets.push(chainAsset);
  } else {
    const mainnetAsset = assets.find(
      (asset) => asset.chain_name === MAINNET_CHAIN_NAME,
    );

    if (mainnetAsset) {
      chainAssets.push({
        ...mainnetAsset,
        chain_name: chain.chain_name,
      });
    }
  }
}

export default chainAssets;
