import { SigningStargateClient } from '@cosmjs/stargate';
import { ChainWalletBase } from '@cosmos-kit/core';
import { useQuery } from '@tanstack/react-query';

// Needed so that cosmos kit works properly. DO NOT REMOVE
SigningStargateClient;

const useSigningStargateClient = ({
  wallet,
}: {
  wallet: ChainWalletBase | undefined;
}) => {
  return useQuery({
    queryKey: ['signingStargateClient', wallet?.walletName],
    queryFn: async () => {
      if (wallet) {
        return wallet.getSigningStargateClient();
      }
    },
    enabled: !!wallet,
    structuralSharing: false,
  });
};

export default useSigningStargateClient;
