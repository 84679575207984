import { useSearchParams } from 'react-router-dom';
import Button from '#/components/common/Buttons/Button/Button.component';
import {
  NEXT_BRAND_ICON,
} from '#/constants/common/images.constants';
import AccountDeactivate from './Components/AccountDeactivate.component';
import BetLimit from './Components/BetLimit.component';
import TimeLimit from './Components/TimeLimit.component';
import TimeOut from './Components/TimeOut.component';
import {
  PageContainer,
  Header,
  PageTitle,
  ContentContainer,
  ContentText,
  TabsContainer,
  TabTitleText,
  ArrowIcon,
  TabItems,
  TabContentText,
} from './ResponsibleGambling.styled';
import { Helmet } from 'react-helmet-async';
import { isProductionEnv } from '#/utils/env';

export default function ResponsibleGambling() {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('tab');

  const handleTabClick = (tabName: string) => {
    setSearchParams({ tab: tabName });
  };

  const getTabContent = () => {
    switch (activeTab) {
      case 'betLimit':
        return <BetLimit />;
      case 'timeLimit':
        return <TimeLimit />;
      case 'timeOut':
        return <TimeOut />;
      case 'accountDeactivation':
        return <AccountDeactivate />;
      default:
        return null;
    }
  };

  return (
    <div className={PageContainer}>
      <Helmet>
        <title>{`Responsible Gambling | SixSigmaSports`}</title>
        {!isProductionEnv() ? <meta name="robots" content="noindex" /> : null}
      </Helmet>
      {activeTab ? (
        <>{getTabContent()}</>
      ) : (
        <div className={ContentContainer}>
          <div className={Header}>
            <h2 className={PageTitle}>Responsible Gambling</h2>
          </div>
          <p className={ContentText}>
            {`It's crucial for us to ensure that your betting experience remains enjoyable and safe. We're here to help you if you want to change your gambling habits.`}
          </p>
          <p className={ContentText}>
            {`Simply choose a phrase below that best describes your gambling habits, and we'll provide you with the right tool to help:`}
          </p>
          <div className={TabsContainer}>
            <Button
              className={TabItems}
              onClick={() => handleTabClick('betLimit')}
              dataId="bet_limit_tab"
            >
              <h2 className={TabTitleText}>BET LIMIT</h2>
              <p className={TabContentText}>
                I often end up betting more than I would like to
              </p>
              <img className={ArrowIcon} src={NEXT_BRAND_ICON} alt="Icon" />
            </Button>
            <Button
              className={TabItems}
              onClick={() => handleTabClick('timeLimit')}
              dataId="time_limit_tab"
            >
              <h2 className={TabTitleText}>TIME LIMIT</h2>
              <p className={TabContentText}>
                I find myself spending too much time on the platform
              </p>
              <img className={ArrowIcon} src={NEXT_BRAND_ICON} alt="Icon" />
            </Button>
            <Button
              className={TabItems}
              onClick={() => handleTabClick('timeOut')}
              dataId="time_out_tab"
            >
              <h2 className={TabTitleText}>TIME OUT</h2>
              <p className={TabContentText}>
                I need to take a break from betting altogether
              </p>
              <img className={ArrowIcon} src={NEXT_BRAND_ICON} alt="Icon" />
            </Button>
            <Button
              className={TabItems}
              onClick={() => handleTabClick('accountDeactivation')}
              dataId="deactivate_account_tab"
            >
              <h2 className={TabTitleText}>ACCOUNT DEACTIVATION</h2>
              <p className={TabContentText}>
                I want to permanently deactivate my account
              </p>
              <img className={ArrowIcon} src={NEXT_BRAND_ICON} alt="Icon" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
