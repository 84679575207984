import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { CLOSE_ICON } from '#/constants/common/images.constants';
import { CloseIcon } from '../NavBar/SideMenuPanel/SideMenu.styled';
import Button from '#/components/common/Buttons/Button/Button.component';

// CSS Classes
const MODAL_CONTAINER_CLASS = `fixed inset-0 z-50 flex items-end justify-center bg-white-82 lg:bg-black bg-opacity-50 lg:bg-opacity-[0.8]
lg:top-0 lg:left-0 lg:items-center lg:w-full lg:h-full lg:py-12 
`;
const MODAL_CONTENT_CLASS = `bg-primary-bg-1 lg:bg-primary-bg-4 rounded-t-2xl shadow-lg w-full max-w-3xl lg:max-w-xl relative animate-slide-up max-h-[95%] overflow-y-auto
lg:mx-auto lg:w-auto lg:max-h-full lg:rounded-2xl lg:animate-none
`;
const MODAL_HEADER_CLASS = (hasPadding?: boolean) =>
  `relative flex justify-between ${!hasPadding ? 'px-5 pt-4' : ''}`;
const MODAL_TITLE_CLASS = `text-xl tracking-wide text-white font-bold flex w-full`;
const CLOSE_BUTTON_CLASS = `absolute top-5 right-4 text-gray-500 hover:text-gray-700`;

interface AnimatedModalProps {
  title: string | ReactNode;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  showCloseIcon?: boolean;
  backgroundClassName?: string;
  containerClassName?: string;
  headerHasPadding?: boolean;
  modalId?: string;
}

const AnimatedModal: React.FC<AnimatedModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
  showCloseIcon = true,
  backgroundClassName = '',
  containerClassName = '',
  headerHasPadding = false,
  modalId = "modal",
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isOpen) {
      document.body.style.overflow = 'hidden';
      setIsAnimating(true);
    } else {
      setIsAnimating(false);
      timer = setTimeout(() => {
        document.body.style.overflow = 'auto';
      }, 300);
    }

    return () => {
      document.body.style.overflow = 'auto';
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOpen &&
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose, isOpen]);

  if (!isOpen && !isAnimating) {
    return null;
  }

  return createPortal(
    <div className={`${MODAL_CONTAINER_CLASS} ${containerClassName}`}>
      <div
        className={`${MODAL_CONTENT_CLASS} ${backgroundClassName} ${isOpen ? 'modal-open' : 'modal-exit'}`}
        ref={modalRef}
        data-testid={modalId}
        id="modal_title"
      >
        <div className={MODAL_HEADER_CLASS(headerHasPadding)}>
          {title && <span className={MODAL_TITLE_CLASS}>{title}</span>}
          {showCloseIcon && (
            <Button className={CLOSE_BUTTON_CLASS} onClick={onClose} dataId={`close_${modalId}`} id="modal_close">
              <img className={CloseIcon} src={CLOSE_ICON} alt="Close Icon" />
            </Button>
          )}
        </div>
        {children}
      </div>
    </div>,
    document.getElementById('portal-root')!,
  );
};

export default AnimatedModal;
