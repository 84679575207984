import useSigningCosmWasmClient from '#/utils/dataLoaders/useSigningCosmWasmClient';
import useSigningStargateClient from '#/utils/dataLoaders/useSigningStargateClient';
import useTendermintClient from '#/utils/dataLoaders/useTendermintClient';
import { useAppSelector } from '#/utils/store';
import useCosmosKitConnect from './useCosmosKitConnect';

const useCosmosKitWallet = () => {
  const account = useAppSelector(
    (state) => state.cosmosKitManager.walletName 
      ? state.cosmosKitManager.walletData[state.cosmosKitManager.walletName] 
      : undefined
  );

  const { wallet, isConnecting, isDisconnecting, connect, disconnect } =
    useCosmosKitConnect();

  const { data: stargateClient } = useSigningStargateClient({ wallet });
  const { data: cosmWasmClient } = useSigningCosmWasmClient({ wallet });
  const { data: tendermintClient } = useTendermintClient({ wallet });

  return {
    wallet,
    account,
    connect,
    disconnect,
    isConnecting,
    isDisconnecting,
    cosmWasmClient,
    stargateClient,
    tendermintClient,
  };
};

export default useCosmosKitWallet;
