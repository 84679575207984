import { ChainWalletBase } from '@cosmos-kit/core';
import { wallets as keplrWallets } from '@cosmos-kit/keplr';
import { wallets as leapWallets } from '@cosmos-kit/leap';
import { isMobile } from '#/utils/platform';
import {
  KEPLR_ICON,
  LEAP_ICON,
  WALLET_ICON,
} from '#/constants/common/images.constants';
import env from './env';

const wallets = [...leapWallets, ...keplrWallets];

export enum WalletName {
  KeplrMobile = 'keplr-mobile',
  LeapMobile = 'leap-cosmos-mobile',
  KeplrExtension = 'keplr-extension',
  LeapExtension = 'leap-extension',
}

export const isLeapExtensionInstalled = window.leap !== undefined;
export const isKeplrExtensionInstalled =
  window.keplr !== undefined &&
  (window.keplr.mode === 'extension' || window.keplr.mode === 'mobile-web');

// Returning undefined from here means we don't know
export const isInstalled = (wallet: ChainWalletBase): boolean | undefined => {
  switch (wallet.walletName) {
    case WalletName.KeplrMobile:
      return undefined;
    case WalletName.LeapMobile:
      return undefined;
    case WalletName.KeplrExtension:
      return isKeplrExtensionInstalled;
    case WalletName.LeapExtension:
      return isLeapExtensionInstalled;
    default:
      return false;
  }
};

// Users typically cannot install extensions on mobile
// Either it's already there which means the user is in the in-app browser
// Or we say it's not supported
// version: 'Mobile-Internal' means we're in Leap's in-app browser, not Keplr's
export const isKeplrInAppBrowser =
  isMobile &&
  isKeplrExtensionInstalled &&
  window.keplr?.version !== 'Mobile-Internal';
export const isLeapInAppBrowser = isMobile && isLeapExtensionInstalled;

export const isKeplrExtensionsSupported = isKeplrInAppBrowser
  ? true
  : isMobile
    ? false
    : true;

export const isLeapExtensionsSupported = isLeapInAppBrowser
  ? true
  : isMobile
    ? false
    : true;

export const isKeplrMobileSupported =
  env.wallets.keplrEnabled &&
  isMobile &&
  !isKeplrInAppBrowser &&
  !isLeapInAppBrowser;

export const isLeapMobileSupported =
  env.wallets.leapEnabled &&
  isMobile &&
  !isKeplrInAppBrowser &&
  !isLeapInAppBrowser;

export const isSupported = (wallet: ChainWalletBase): boolean => {
  switch (wallet.walletName) {
    case WalletName.KeplrMobile:
      return isKeplrMobileSupported;
    case WalletName.LeapMobile:
      return isLeapMobileSupported;
    case WalletName.KeplrExtension:
      return isKeplrExtensionsSupported;
    case WalletName.LeapExtension:
      return isLeapExtensionsSupported;
    default:
      return false;
  }
};

export const walletLogo = (wallet?: ChainWalletBase): string => {
  if (wallet) {
    switch (wallet.walletName) {
      case WalletName.KeplrMobile:
        return KEPLR_ICON;
      case WalletName.LeapMobile:
        return LEAP_ICON;
      case WalletName.KeplrExtension:
        return KEPLR_ICON;
      case WalletName.LeapExtension:
        return LEAP_ICON;

      default:
        return WALLET_ICON;
    }
  } else {
    return WALLET_ICON;
  }
};

export default wallets;
