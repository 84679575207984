export const FlexContainer = `flex`;
export const NotificationIcon = `w-8 3xl:w-9`;
export const IconWrapper = ``;
export const FullContainer = `w-full`;
export const TitleText = `text-white font-normal text-sm xxxs:text-base 3xl:text-xl`;
export const SubTitleText = `text-white font-light text-sm xxxs:text-base 3xl:text-xl`;
export const TimeText = `text-text-gray-1 self-start font-light text-xs xxxs:text-sm 3xl:lg whitespace-nowrap pl-3`;
export const Separator = `h-0 my-4 border border-solid border-t-0 border-border-bg-1`;
export const FlexNone = `flex-none mr-3 pt-1`;

interface TabItemProp {
  isActive: boolean;
}
export const TabsWrapper = `w-full flex overflow-x-auto overflow-y-hidden space-x-2 xxxs:space-x-3 my-4`;
export const TabFilterItems = ({
  isActive,
}: TabItemProp) => `border border-solid border-white rounded-md px-3 text-sm xxxs:text-base 3xl:text-xl text-white font-normal flex justify-center items-center h-10 whitespace-nowrap lg:cursor-pointer
  ${isActive ? '!font-bold !text-text-brand-1 !border-border-bg-2 border-2' : ''}`;
export const FavIcon = `w-5`;

export const NotificationHeader = `w-full flex items-center`;
export const PageTitle = `text-white font-normal text-sm xxxs:text-lg 3xl:text-2xl tracking-wider`;
export const NotificationHeaderIcon = `w-4 mr-4`;
