import {
  AppliedFilterWrapper,
  FilterItems,
  FilterItemsLabel,
  RemoveButton,
  RemoveIcon,
} from './AppliedFiltersPanel.styled';
import Button from '#/components/common/Buttons/Button/Button.component';
import { CLOSE_ICON_BRAND } from '#/constants/common/images.constants';

interface AppliedFiltersPanelProps {
  filters: { id: number; label: string }[]; // Changed to match the structure of the filters
  onRemoveFilter: (id: number) => void;
}

export default function AppliedFilters({
  filters,
  onRemoveFilter,
}: AppliedFiltersPanelProps) {
  if (!filters.length) return null;

  return (
    <div className={AppliedFilterWrapper}>
      {filters.map((filter) => (
        <div className={FilterItems} key={filter.id}>
          <div className={FilterItemsLabel}>{filter.label}</div>
          <Button
            onClick={() => onRemoveFilter(filter.id)}
            className={RemoveButton}
            dataId="remove_filter_click"
            icon={CLOSE_ICON_BRAND}
            iconPosition="right"
            iconClass={RemoveIcon}
          />
        </div>
      ))}
    </div>
  );
}
