import Button from '#/components/common/Buttons/Button/Button.component';
import { FixtureTab } from '#/utils/backend';
import {
  SportListWrapper,
  TagFilterItems,
} from './MatchDetailsFixtureTab.styled';

type FixtureTabsProps = {
  fixtureTabNames: FixtureTab[];
  selectFixtureTab: (item: FixtureTab) => void;
  fixtureTabSelected: FixtureTab;
};

export default function FixtureTabs({
  fixtureTabNames,
  selectFixtureTab,
  fixtureTabSelected,
}: FixtureTabsProps) {
  return (
    <div className={SportListWrapper}>
      {fixtureTabNames.map((fixtureTabName) => (
        <Button
          className={TagFilterItems({
            isActive: fixtureTabSelected.id === fixtureTabName.id,
          })}
          key={fixtureTabName.id}
          onClick={() => selectFixtureTab(fixtureTabName)}
          dataId={`${fixtureTabName.name.toLowerCase().replaceAll(" ", "_")}_fixture_tab`}
        >
          {fixtureTabName.name}
        </Button>
      ))}
    </div>
  );
}
