import { useQuery } from '@tanstack/react-query';
import { loadEngagementsData } from '../backend';
import useAuth from './useAuth';
import useAccessToken from './useAccessToken';

const useEngagementsDataLoader = (
  provisionId: string | null,
  refetchInterval?: number,
) => {
  const { data: user } = useAuth();
  const { data: accessToken } = useAccessToken();

  return useQuery({
    queryKey: ['engagementsDataLoader', provisionId],
    enabled: !!provisionId && !!user && !!accessToken,
    queryFn: () => {
      if (provisionId && accessToken) {
        return loadEngagementsData(provisionId, accessToken);
      }
      return null;
    },
    refetchInterval: refetchInterval ? refetchInterval : 1000 * 10, // requirements are for live data, so we poll every 10 seconds
  });
};

export default useEngagementsDataLoader;
