import React from 'react';
import {
  FlexContainer,
  FullContainer,
  TitleText,
  TimeText,
  NotificationIcon,
  SubTitleText,
  Separator,
  IconWrapper,
  FlexNone,
} from './Notifications.styled';
import { Notification } from '#/utils/backend';
import { timeAgo } from '#/utils/datetime/datetime';
import NotificationsLoader from './Loader.component';
import EmptyList from '../common/EmptyStates/EmptyState.component';
import {
  APPROVED_KYC_ICON,
  BET_SETTLEMENT_LOSS_ICON,
  BET_SETTLEMENT_WIN_ICON,
  COMPLETED_KYC_ICON,
  DENIED_KYC_ICON,
  HOUSE_SETTLEMENT_ICON,
  KYC_VERIFICATION_REMINDER_ICON,
  NOTIFICATION_CIRCLE_ICON,
  REWARDS_RECEIPT_ICON,
  BET_SETTLEMENT_REFUND_ICON,
  BET_PLACEMENT_FAIL_ICON,
  KYC_RESUBMISSION_ICON,
} from '#/constants/common/images.constants';
import { getMarketNameById, getOutcomeName } from '#/utils/marketsMap';

const getNotificationText = (
  notification: Notification,
): { title: string; message: string } => {
  const metadata =
    notification.metadataJson && JSON.parse(notification.metadataJson);

  switch (notification.ty) {
    case 'BetSettlementWin':
      return {
        title: `${metadata.amount} SGE Bet Win`,
        message: `${metadata.fixtureName} | ${getMarketNameById(metadata.marketTypeId)} | ${getOutcomeName(metadata.marketTypeId, metadata.outcome)}`,
      };
    case 'BetSettlementLoss':
      return {
        title: `${metadata.amount} SGE Bet Loss`,
        message: `${metadata.fixtureName} | ${getMarketNameById(metadata.marketTypeId)} | ${getOutcomeName(metadata.marketTypeId, metadata.outcome)}`,
      };
    case 'BetSettlementRefund':
      return {
        title: `${metadata.amount} SGE Refund Issued`,
        message: `A ${metadata.amount} SGE refund has been issued for your bet on '${metadata.fixtureName} | ${getMarketNameById(metadata.marketTypeId)}'`,
      };
    case 'BetPlacementFail':
      return {
        title: 'Bet Placement Failed',
        message: `We encountered an issue placing your bet on '${metadata.fixtureName} | ${getMarketNameById(metadata.marketTypeId)}'. Please try again or contact support if the problem persist.`,
      };
    case 'HouseSettlement':
      return {
        title: 'House Settlement',
        message: `All the bets on your house deposit on the market ${metadata.fixtureName} | ${getMarketNameById(metadata.marketTypeId)} are settled.`,
      };
    case 'KycReminder':
      return {
        title: 'Complete Your KYC Verification!',
        message:
          'Unlock the full potential of your betting experience by completing your KYC verification today.',
      };
    case 'KycApproved':
      return {
        title: 'Approved KYC verification',
        message:
          'Congratulations! You have successfully completed the KYC process. You now have full access to the platform.',
      };
    case 'KycRejected':
      return {
        title: 'Denied KYC verification',
        message:
          'We were not able to verify your identity from the documents you have submitted. If you believe this is an error, please contact info@sixsigmasports.io.  Only KYC approved users have full access to our platform.',
      };
    case 'KycPendingVerification':
      return {
        title: 'Completed KYC verification',
        message:
          'You have completed the KYC process and your account is currently under review.',
      };
    case 'KycResubmission':
      return {
        title: 'KYC Resubmission Required',
        message:
          'We were unable to verify your KYC documents. Please resubmit your ID document, ensuring that the documents are unobstructed, clear, in high-quality resolution, and in color.',
      };
    case 'RewardsReceipt':
      return {
        title: 'Rewards Receipt',
        message:
          'Congratulations - your new rewards tokens have been deposited in your wallet. Please visit your profile to access your rewards tokens.',
      };
  }
};

const notificationTypeToIcon = (notificationType: string) => {
  return (
    {
      BetSettlementWin: BET_SETTLEMENT_WIN_ICON,
      BetSettlementLoss: BET_SETTLEMENT_LOSS_ICON,
      HouseSettlement: HOUSE_SETTLEMENT_ICON,
      KycReminder: KYC_VERIFICATION_REMINDER_ICON,
      KycPendingVerification: COMPLETED_KYC_ICON,
      KycApproved: APPROVED_KYC_ICON,
      KycRejected: DENIED_KYC_ICON,
      RewardsReceipt: REWARDS_RECEIPT_ICON,
      BetSettlementRefund: BET_SETTLEMENT_REFUND_ICON,
      BetPlacementFail: BET_PLACEMENT_FAIL_ICON,
      KycResubmission: KYC_RESUBMISSION_ICON,
    }[notificationType] || NOTIFICATION_CIRCLE_ICON
  );
};

interface NotificationsCardProps {
  notifications: Notification[];
  isLoading: boolean;
}

const NotificationsCard: React.FC<NotificationsCardProps> = ({
  notifications,
  isLoading,
}) => {
  if (!isLoading && !notifications.length) {
    return <EmptyList id="notifications" />;
  }

  return (
    <div className="mt-5">
      {notifications.map((notification, index) => {
        const notificationText = getNotificationText(notification);

        return (
          <React.Fragment key={notification.id}>
            <div className={FlexContainer}>
              <div className={FlexNone}>
                <span className={IconWrapper}>
                  <img
                    className={NotificationIcon}
                    src={notificationTypeToIcon(notification.ty)}
                    alt="icon"
                  />
                </span>
              </div>
              <div className={FullContainer}>
                <h2 className={TitleText}>{notificationText.title}</h2>
                <h3 className={SubTitleText}>{notificationText.message}</h3>
              </div>
              <span className={TimeText}>
                {timeAgo(notification?.date || new Date().toISOString())}
              </span>
            </div>
            <div className={Separator} />
          </React.Fragment>
        );
      })}
      {isLoading && <NotificationsLoader />}
    </div>
  );
};

export default NotificationsCard;
