import React, { useState } from 'react';
import ActivityCard from '#/components/common/ActivityCard/ActivityCard.component';
import { getStatusTypeHouse, HouseProvision } from '#/utils/backend';
import { formatDateToDesiredFormat } from '#/utils/datetime/datetime';
import { getMarketNameById } from '#/utils/marketsMap';
import { CardFlexItem } from '../common/ActivityCard/ActivityCard.types';
import Button from '#/components/common/Buttons/Button/Button.component';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import useBackendUserData from '#/utils/dataLoaders/useBackendUserData';
import useCosmosKitWallet from '#/hooks/useCosmosKitWallet';
import { setIsModalOpen } from '#/utils/slices/walletConnectionSlice';
import ActionTag from '../common/ActionTags.component';
import {
  BeHouseText,
  FlexContainerBeHouse,
  HouseIcon,
} from '../MatchCard/MatchCard/MatchCard.styled';
import { MINUS_CIRCLE_ICON, PLUSH_CIRCLE_ICON } from '#/constants/common/images.constants';
import EngagementDetailsCard from '../EngagementDetailsCard';
import ApprovePopup from '../SlipsDrawers/Components/ApprovePopup/ApprovePopup.component';
import { ExecuteResult } from '@cosmjs/cosmwasm-stargate';
import { AppToast } from '../common/ToastNotification/ToastConfig';

const WithdrawBtn = `w-full sm:w-80 tracking-wider rounded-md bg-brand-bg-1 px-4 py-3 text-black font-bold text-sm xxxs:text-basew-full sm:w-80 tracking-wider rounded-md bg-transparent px-4 py-2.5 text-text-brand-1 font-bold text-sm xxxs:text-base text-center border border-border-bg-2 relative`;

interface ParsedProvisionId {
  marketId: string;
  serial: number;
}

export type WithdrawalError = Error;

export type WithdrawingMarkets = Set<string>;

const formatDecimal = (value: string) => {
  const numberValue = parseFloat(value);
  if (isNaN(numberValue)) {
    return '0.00';
  }
  return numberValue.toFixed(2);
};

interface HouseActivityCardProps {
  provision: HouseProvision;
  withdraw: (marketId: string, serial: number) => Promise<ExecuteResult | undefined>;
}

const HouseActivityCard: React.FC<HouseActivityCardProps> = ({
  provision,
  withdraw,
}) => {
  const dispatch = useDispatch();
  const { data: user } = useBackendUserData();
  const { account } = useCosmosKitWallet();
  const [withdrawingMarkets, setWithdrawingMarkets] = useState<WithdrawingMarkets>(
    new Set(),
  );
  const [showApprovePopup, setShowApprovePopup] = useState(false);
  const [showEngagementDetails, setShowEngagementDetails] = useState<
    Set<string>
  >(new Set());

  const accountDeactivated = user?.responsibleGambling?.accountDeactivated;

  const statusType = getStatusTypeHouse(provision);
  const returnedAmount =
    Number(provision.amount) +
    Number(provision.profitOrLoss) -
    Number(provision.withdrawnAmount);

  const infoItems: CardFlexItem[] = [
    {
      label: 'Provided:',
      value: `${provision.totalBets > 0 ? formatDecimal((Number(provision.amount) - Number(provision.participationFee)).toString()) : formatDecimal(provision.amount)} SGE`,
    },
  ];

  if (provision.state === 'Settled') {
    infoItems.push({
      label: 'Returned:',
      value: `${formatDecimal(returnedAmount.toString())} SGE`,
    });
  }

  if (
    provision.profitOrLoss &&
    provision.profitOrLoss !== '0' &&
    provision.state !== 'Active'
  ) {
    infoItems.push({
      label: 'Win/Loss:',
      value: `${formatDecimal(provision.profitOrLoss)} SGE`,
    });
  }

  if (provision.withdrawnAmount !== '0' || provision.state === 'Active') {
    infoItems.push({
      label: 'Withdrawn:',
      value: `${formatDecimal(provision.withdrawnAmount)} SGE`,
    });
  }

  if (provision.totalBets > 0) {
    infoItems.push({
      label: 'Max Risk Exposure:',
      value: `${formatDecimal(provision.riskExposure || '0')} SGE`,
    });
  }

  infoItems.push({
    label: 'Total Bet Amount:',
    value: `${formatDecimal(provision.betValue)} SGE`,
  });

  const getWithdrawButtonProps = (provision: HouseProvision) => {
    if (withdrawingMarkets.has(provision.id)) {
      return { text: 'Processing...', disabled: true };
    }

    if (provision.withdrawnAmount === provision.amount) {
      return { text: 'Withdrawn', disabled: true };
    }

    const withdrawableAmount =
      Number(provision.amount) -
      Number(provision.withdrawnAmount) -
      Number(provision.riskExposure || '0');

    if (withdrawableAmount > 0) {
      return {
        text: `Withdraw ${formatDecimal(withdrawableAmount.toString())} SGE`,
        disabled: false,
      };
    }

    if (
      withdrawableAmount <= 0 &&
      provision.withdrawnAmount !== provision.amount
    ) {
      return { text: 'Provision filled', disabled: true };
    }

    return { text: 'Withdraw unavailable', disabled: true };
  };

  const { text: buttonText, disabled: buttonDisabled } =
    getWithdrawButtonProps(provision);


  const handleWithdraw = async (provision: HouseProvision) => {
    if (accountDeactivated) {
      toast.error('Your account is deactivated. Withdrawal is disabled.');
      return;
    }

    if (!account) {
      dispatch(setIsModalOpen(true));
      return;
    }
    setWithdrawingMarkets((prev: WithdrawingMarkets) => new Set(prev.add(provision.id)));
    setShowApprovePopup(true);

    try {
      const { marketId, serial } = parseProvisionId(provision.id);

      await withdraw(marketId.toString(), serial);
    } catch (error) {
      if (error instanceof Error) {
        handleWithdrawError(error, provision);
      } else {
        toast.error(
          <AppToast
            id={'ProcessingWithdrawalFailed'}
            message={`An unknown error occurred while trying to withdraw ${provision.amount} SGE. Please try again or contact support.`}
          />,
          { icon: false }
        );
      }
    } finally {
      finalizeWithdraw(provision.id);
    }
  }

  const parseProvisionId = (provisionId: string): ParsedProvisionId => {
    const [marketIdStr, serialStr] = provisionId.split('-');
    if (!marketIdStr || !serialStr) throw new Error(`Invalid provision ID format: ${provisionId}`);

    const serial = Number(serialStr);
    if (isNaN(serial)) throw new Error(`Serial is not a valid number: ${serialStr}`);

    return { marketId: marketIdStr, serial };
  };

  const handleWithdrawError = (error: WithdrawalError, provision: HouseProvision) => {
    console.error('Error during withdrawal:', error);

    if (error instanceof Error && error.message.includes('User cancelled')) {
      toast.info(`Request rejected by user.`);
    } else {
      toast.error(
        <AppToast
          id={'ProcessingWithdrawalFailed'}
          message={`Failed to withdraw ${provision.amount} SGE. Please try again or contact support.`}
        />,
        { icon: false }
      );
    }
  };

  const finalizeWithdraw = (provisionId: string) => {
    setTimeout(() => {
      setWithdrawingMarkets((prev: WithdrawingMarkets) => {
        const newSet = new Set(prev);
        newSet.delete(provisionId);
        return newSet;
      });
    }, 2000);
    setShowApprovePopup(false);
  };

  const toggleEngagementDetails = (provisionId: string) => {
    setShowEngagementDetails((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(provisionId)) {
        newSet.delete(provisionId);
      } else {
        newSet.add(provisionId);
      }
      return newSet;
    });
  };

  return (
    <ActivityCard
      key={provision.id}
      statusText=""
      statusType={statusType}
      dateText={formatDateToDesiredFormat(provision.createdAt)}
      title={getMarketNameById(provision.marketTypeId) || ''}
      subTitle={provision.fixtureName}
      transactionLink={provision.txHash}
      infoItems={infoItems}
    >
      {provision.state === 'Active' && (
        <Button
          className={WithdrawBtn}
          onClick={() => handleWithdraw(provision)}
          dataId="place_withdraw"
          disabled={buttonDisabled}
        >
          {buttonText}
        </Button>
      )}
      {provision.totalBets > 0 ? (
        <ActionTag
          ariaExpanded={showEngagementDetails.has(provision.id)}
          type="div"
          dataId="show_engagement_details"
          onClick={() => toggleEngagementDetails(provision.id)}
          className={`${FlexContainerBeHouse} mt-2`}
        >
          <img
            className={HouseIcon}
            src={
              showEngagementDetails.has(provision.id)
                ? MINUS_CIRCLE_ICON
                : PLUSH_CIRCLE_ICON
            }
            alt={
              showEngagementDetails.has(provision.id)
                ? 'Collapse'
                : 'Expand'
            }
          />
          <span className={`${BeHouseText} my-2`}>
            {showEngagementDetails.has(provision.id)
              ? 'Hide Bets'
              : `View Bets`}{' '}
            ({provision.totalBets})
          </span>
        </ActionTag>
      ) : (
        <div className={`${FlexContainerBeHouse} mt-2 opacity-25`}>
          <img
            className={`${HouseIcon} opacity-25 text-gray-500`}
            src={PLUSH_CIRCLE_ICON}
            alt="Expand"
          />
          <div className={`${BeHouseText} text-gray-500 my-2`}>
            No bets to show
          </div>
        </div>
      )}
      {showEngagementDetails.has(provision.id) && (
        <EngagementDetailsCard provision={provision} />
      )}
      <ApprovePopup
        isOpen={showApprovePopup}
        onClose={() => setShowApprovePopup(false)}
      />
    </ActivityCard>
  );
};

export default HouseActivityCard;
