import { loadFixtureData, outcomeId } from '#/utils/backend';
import { useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '../store';
import { Outcomes, updateOutcomes } from '../slices/outcomesSlice';
import { Markets, updateMarkets } from '../slices/marketsSlice';

const useFixtureData = ({
  id,
  enabled = true,
}: {
  id: string;
  enabled: boolean;
}) => {
  const dispatch = useAppDispatch();

  const hasId = !!id;

  return useQuery({
    queryKey: ['fixture', id],
    queryFn: async () => {
      if (!hasId) {
        return;
      }

      return loadFixtureData(id).then((data) => {
        const newOutcomes: Outcomes = {};
        const newMarkets: Markets = {};

        Object.values(data.markets).forEach((markets) => {
          markets.forEach((market) => {
            newMarkets[market.id] = {
              status: market.status,
              acceptable_provision_size: market.acceptableProvisionSize              
            }

            Object.values(market.odds).forEach((odds) => {
              newOutcomes[outcomeId(odds)] = {
                value: odds.value,
                movement: odds.movement,
                acceptable_bet_size: odds.acceptableBetSize,
              };
            });
          });
        });

        dispatch(updateOutcomes(newOutcomes));
        dispatch(updateMarkets(newMarkets));

        return data;
      });
    },
    enabled: hasId && enabled,
  });
};

export default useFixtureData;
