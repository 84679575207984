import { useEffect, useState } from 'react';
import HouseActivityCard from './HouseActivityCard.component';
import LoadingComponent from '../BetActivityCards/BetActivityLoading.component';
import EmptyList from '../common/EmptyStates/EmptyState.component';
import { useAppSelector } from '#/utils/store';
import useInfiniteLiquidityDataLoader from '#/utils/dataLoaders/useInfiniteLiquidityLoader';
import { useSelector } from 'react-redux';
import { State } from '#/utils/slices/activityFiltersSlice';
import { toast } from 'react-toastify';
import { AppToast } from '../common/ToastNotification/ToastConfig';
import { HOUSE_ACTIVITY, Tab } from '../routes/MyBets';
import useBackendUserData from '#/utils/dataLoaders/useBackendUserData';
import { useKycStatus } from '../SlipsDrawers/Components/useKycStatus';
import { useExecute } from '#/hooks/useExecute';
import { useSearchParams } from 'react-router-dom';
import { HouseActivityOutcomes } from '#/utils/backend';
import { useInView } from 'react-intersection-observer';

const filterHouseActivityOutcomes = (outcomes: string[]): HouseActivityOutcomes[] => {
  const validHouseOutcomes: HouseActivityOutcomes[] = ['Active', 'Settled'];
  return outcomes.filter((outcome): outcome is HouseActivityOutcomes =>
    validHouseOutcomes.includes(outcome as HouseActivityOutcomes)
  );
};

const HouseActivityCards = () => {
  const searchInput = useAppSelector((state) => state.activityFilters.search);
  const { data: user } = useBackendUserData();
  const [searchParams] = useSearchParams();
  const auth0Id = user?.id || "";
  const sgeAddress = user?.walletAddr || "";
  const { outcomeFilters, sports, marketTypeIds: marketTypes } = useSelector((state: { activityFilters: State }) => state.activityFilters);
  const { executeMultiple } = useExecute();
  const { kycToken } = useKycStatus(true);
  const activeTab = (searchParams.get('tab') || HOUSE_ACTIVITY) as Tab;
  const { ref, inView } = useInView();

  const filteredOutcomes = filterHouseActivityOutcomes(outcomeFilters);

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteLiquidityDataLoader({
    search: searchInput,
    filters: { states: filteredOutcomes, sports, marketTypes },
    userId: auth0Id,
    sgeAddress,
  });

  const [localProvisions, setLocalProvisions] = useState(() => {
    return data ? data.pages.flatMap((page) => page.provisions) : [];
  });

  useEffect(() => {
    if (data) {
      const newProvisions = data.pages.flatMap((page) => page.provisions);
      setLocalProvisions(newProvisions);
    }
  }, [data]);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView, hasNextPage, isFetchingNextPage]);

  const withdraw = async (marketId: string, serial: number) => {
    if (!auth0Id) return;

    const msg = {
      msg: {
        house_withdraw_liquidity: {
          token: kycToken,
          market_id: marketId,
          serial: serial.toString(),
        },
      },
      funds: [],
    };
    return executeMultiple([msg], {
      onSuccess: () => {
        toast.success(<AppToast id={'ProcessingWithdrawal'} />, { autoClose: 2000, icon: false })
        const withdrawnAmount = localProvisions.find((provision) => provision.marketId.toString() === marketId)?.amount;
        toast.success(<AppToast id={'WithdrawalPlaced'} message={`You have successfully withdrawn ${withdrawnAmount} SGE.`} />, { autoClose: 2000, icon: false })
        setLocalProvisions((prevProvisions) =>
          prevProvisions.map((provision) =>
            provision.marketId.toString() === marketId
              ? { ...provision, withdrawnAmount: provision.amount }
              : provision
          )
        );
      },
    });
  };

  useEffect(() => {
    if (isError && activeTab === HOUSE_ACTIVITY) {
      toast.error(<AppToast id={'UnableToFetch'} />, { icon: false, autoClose: 2000 });
    }
  }, [isError, activeTab]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (!localProvisions.length && !isLoading) {
    return <EmptyList id="houseDeposits" />;
  }


  return (
    <div>
      {localProvisions?.map((provision) => (
        <HouseActivityCard
          key={provision.id}
          provision={provision}
          withdraw={withdraw}
        />
      ))}
      <div ref={ref}></div>
      {isFetchingNextPage && <LoadingComponent />}
    </div>
  );
};

export default HouseActivityCards;
