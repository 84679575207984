/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { twMerge } from 'tailwind-merge';

import {
  CardContainer,
  CardHeader,
  StatusBadge,
  WonBadge,
  LostBadge,
  DateText,
  Title,
  SubTitle,
  CardFooter,
  FlexCenter,
  CardFooterIcon,
  CardFooterText,
  CardFlex,
  CardFlexItems,
  LabelText,
  ValueText,
  TxAddress,
} from './ActivityCard.styled';
import RedirectLink from '../RedirectLink.component';
import { ActivityCardProps, StatusType } from './ActivityCard.types';
import {
  COPY_ICON,
  SUCCESS_ICON_ACTIVE,
} from '#/constants/common/images.constants';
import { copyToClipboard } from '#/utils/string/string';
import { CustomToast } from '../ToastNotification/ToastConfig';
import { toast } from 'react-toastify';
import env from '#/utils/env';
import ImgActionTag from '../ImageActionTag.component';

const statusTypeToClass = (statusType: StatusType): string => {
  switch (statusType) {
    case 'success':
      return WonBadge;
    case 'failure':
      return LostBadge;
    default:
      return StatusBadge;
  }
};

const TransactionLink = (props: {
  transactionLink: string;
  sliceLength?: number;
}) => {
  const { transactionLink, sliceLength = 25 } = props;
  async function copyAddress(address: string) {
    await copyToClipboard(address);
    toast.error(
      <CustomToast
        title={'Copied successfully'}
        subText="Transaction hash copied to your clipboard."
        iconSrc={SUCCESS_ICON_ACTIVE}
        iconClass="w-6"
      />,
      { icon: false },
    );
  }
  return (
    <div className="flex items-center mb-2">
      <RedirectLink
        className={twMerge(SubTitle, 'flex my-2')}
        href={`${env.blockExplorer}/sge/tx/${transactionLink}`}
        target="_blank"
        dataId="liquidity_card_transaction_explorer_link"
      >
        <span
          className={TxAddress}
        >{`${transactionLink.slice(0, sliceLength)}....${transactionLink.slice(
          transactionLink.length - 5,
          transactionLink.length,
        )}`}</span>
      </RedirectLink>
      <ImgActionTag
        className={twMerge(CardFooterIcon, 'ml-2 cursor-pointer')}
        src={COPY_ICON}
        onClick={() => copyAddress(transactionLink)}
        alt="copy"
        dataId="copy_transaction_link"
      />
    </div>
  );
};

export const Header = (props: {
  statusText?: string;
  dateText: string;
  statusType?: StatusType;
  transactionLink?: string;
}) => {
  const { statusText, dateText, statusType, transactionLink } = props;
  const statusClasses = statusType
    ? statusTypeToClass(statusType)
    : StatusBadge;
  return (
    <div className={CardHeader}>
      <span className={`min-w-24`}>
        {statusText && <span className={statusClasses}>{statusText}</span>}
        {transactionLink && (
          <TransactionLink transactionLink={transactionLink} sliceLength={10} />
        )}
      </span>
      <span className={DateText}>{dateText}</span>
    </div>
  );
};

export const InfoItem = (props: { label: string; value: string }) => {
  const { label, value } = props;
  return (
    <div className={CardFlexItems}>
      <span className={LabelText}>{label}</span>
      <span className={ValueText}>{` ${value}`}</span>
    </div>
  );
};

export const EngagementCard: React.FC<
  Omit<ActivityCardProps, 'title' | 'subTitle'>
> = ({ dateText, footerInfo, infoItems, transactionLink, children }) => {
  return (
    <div
      className={twMerge(
        CardContainer,
        'p-0 rounded-none border-t border-border-bg-1 pt-2 pb-1',
      )}
    >
      <Header transactionLink={transactionLink} dateText={dateText} />
      {infoItems && (
        <div className={twMerge(CardFlex, 'grid grid-cols-2 gap-2')}>
          {infoItems.map((item, index) => (
            <InfoItem key={index} label={item.label} value={item.value} />
          ))}
        </div>
      )}
      {footerInfo && (
        <div className={twMerge(CardFooter, 'grid grid-cols-2 gap-4')}>
          {footerInfo.map((item, index) => (
            <span
              className={twMerge(
                FlexCenter,
                index === footerInfo.length - 1 ? 'justify-self-end' : '',
              )}
              key={index}
            >
              <img className={CardFooterIcon} src={item.iconSrc} alt="icon" />
              <span className={CardFooterText}>{item.text}</span>
            </span>
          ))}
        </div>
      )}
      {children && <div className="mt-2">{children}</div>}
    </div>
  );
};

const ActivityCard: React.FC<ActivityCardProps> = ({
  statusText,
  statusType,
  dateText,
  title,
  titleText,
  subTitle,
  footerInfo,
  infoItems,
  transactionLink,
  children,
}) => {
  return (
    <div className={CardContainer}>
      <Header
        statusText={statusText}
        dateText={dateText}
        statusType={statusType}
      />
      <h2 className={Title}>{title}</h2>
      {titleText ? <h3 className={Title}>{titleText}</h3> : null}
      <h3 className={SubTitle}>{subTitle}</h3>
      {transactionLink && <TransactionLink transactionLink={transactionLink} />}
      {infoItems && (
        <div className={twMerge(CardFlex, 'grid grid-cols-2 gap-2')}>
          {infoItems.map((item, index) => (
            <InfoItem key={index} label={item.label} value={item.value} />
          ))}
        </div>
      )}
      {footerInfo && (
        <div className={twMerge(CardFooter, 'grid grid-cols-2 gap-4')}>
          {footerInfo.map((item, index) => (
            <span
              className={twMerge(
                FlexCenter,
                index === footerInfo.length - 1 ? 'justify-self-end' : '',
              )}
              key={index}
            >
              <img className={CardFooterIcon} src={item.iconSrc} alt="icon" />
              <span className={CardFooterText}>{item.text}</span>
            </span>
          ))}
        </div>
      )}
      {children && <div className="mt-4">{children}</div>}
    </div>
  );
};

export default ActivityCard;
