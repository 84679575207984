export const Container = `w-full mb-3`;
export const FlexContainer = `flex justify-between items-center`;
export const CheckIcon = `w-5 mr-2`;
export const CheckCircleIcon = `w-4.5 3xl:w-5 4xl:w-6 mr-3`;
export const HeadTitle = `flex items-center text-white font-bold text-lg xxxs:text-2xl 3xl:text-3xl 4xl:text-4xl`;
export const StepsText = `text-white font-bold text-xs 3xl:text-base 4xl:text-lg 5xl:text-xl uppercase`;
export const SubTitle = `flex items-center text-white font-normal text-base 3xl:text-xl 4xl:text-2xl 5xl:text-3xl`;
export const ContentWrapper = `w-full pl-8 mt-2`;
export const ContentText = `text-text-gray-5 tracking-wide font-light text-sm 3xl:text-lg 4xl:text-xl 5xl:text-2xl`;
export const VerifyEmailText = `line-through font-light text-text-gray-2`;
export const ConnectButton = `w-full text-center sm:w-64 lg:w-80 tracking-wider rounded-md px-4 py-3 4xl:py-4 text-black font-bold text-sm xxs:text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl bg-brand-bg-1`;
export const SetupButton = `w-full text-center sm:w-64 lg:w-80 tracking-wider rounded-md bg-transparent px-4 py-3 4xl:py-4 text-white font-semibold text-sm xxs:text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl border border-brand-bg-1`;
export const BorderWrapper = `w-full flex space-x-1 mt-4`;
export const PrecessBorderActive = `h-1 bg-text-warning-1 w-full`;
export const PrecessBorder = `h-1 bg-white w-full`;
export const DetailsContainer = `w-full bg-primary-bg-2 rounded-md p-4`;
export const Separator = `h-0 my-4 border border-solid border-t-0 border-border-bg-1`;
export const InactiveText = `text-text-disabled-1 font-light text-base 3xl:text-xl 4xl:text-2xl 5xl:text-3xl`;

interface StepsProp {
  isActive: boolean;
}
export const StepsBorder = ({
  isActive,
}: StepsProp) => `h-1 bg-white w-full first:rounded-l-md last:rounded-r-md
  ${isActive ? '!bg-brand-bg-1' : ''}`;
