export const FixtureInfoContainer = `w-full xl:px-4`;
export const FixtureInfoHeader = `text-base 3xl:text-lg 4xl:text-xl 5xl:text-3xl 6xl:text-4xl text-text-gray-1 font-medium px-4 mb-4`;
export const FullWidthContainer = `w-full`;
export const FullWidthContainerNew = `w-full inline sm:rounded-md lg:rounded-none overflow-hidden`;
export const PaddedContainer = `px-4`;
export const FixtureDetailsWrapper = `flex flex-row justify-between px-4 py-3 lg:py-5 5xl:py-10 relative xl:p-6 overflow-y-hidden relative before:bg-no-repeat before:bg-[url('/v2-icons/matchdetails-bg.png')] before:absolute before:left-0 before:top-0 before:w-full before:h-full before:bg-cover before:z-0 xl:before:bg-[url('/v2-icons/matchdetails-bg-desktop.png')]
`;
export const FixtureDetailsMobile = `block w-full relative z-1`;
export const FixtureName = `text-text-gray-1 text-sm xl:text-base 3xl:text-lg 4xl:text-xl 5xl:text-3xl 6xl:text-4xl font-normal mb-1 3xl:mb-2`;
export const TournamentName = `text-text-gray-1 text-sm xl:text-base 3xl:text-lg 4xl:text-xl 5xl:text-3xl 6xl:text-4xl font-normal mb-1 3xl:mb-2`;
export const MarginedFullContainer = `w-full mb-2`;
export const ScoreContainer = `text-2xl 4xl:text-3xl font-semibold text-text-gray-1`;
export const TournamentDate = `text-text-gray-1 text-sm xl:text-base 3xl:text-lg 4xl:text-xl 5xl:text-3xl 6xl:text-4xl font-normal mb-1 3xl:mb-2`;
export const FixtureInfoWrapper = `block`;
export const MobTournamentName = `mx-0 lg:mx-1 text-xs 3xl:text-base 4xl:text-lg 5xl:text-2xl 6xl:text-3xl text-text-gray-2 font-normal`;
export const MobTournamentDate = `text-xl 3xl:text-2xl 5xl:text-3xl text-white font-normal block`;
export const FixtureLeagueName = `text-xl 3xl:text-2xl 5xl:text-3xl text-white font-normal block tracking-wider`;
export const FixtureTeamName = `text-white text-4xl 3xl:text-5xl 4xl:text-6xl 5xl:text-7xl font-bold tracking-wider`;
export const FixtureFlexWapper = `flex justify-between py-4 space-x-8 sm:space-x-16`;
export const IconsWrapper = `w-full flex justify-end space-x-4`;
export const IconsSize = `size-11 3xl:size-12 hover:animate-pulse`;
export const ShareBtn = `group relative hover:animate-pulse`;
export const TeamNameWrapper = `w-full mt-14 xl:mt-0 lg:mb-2 xl:mb-3`;
export const FixtureTeam = `text-white text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-4xl font-normal tracking-wider`;
