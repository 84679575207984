export type DataState<Data> = {
  isLoading: boolean,
  isError: boolean,
  data: Data | undefined
}

export type WithHandledStatesProps<Data> = {
  dataState: DataState<Data>;
  onLoading: () => JSX.Element
  onError: () => JSX.Element
  onData: (data: Data) => JSX.Element
}

const WithHandledStates = <Data,>({ dataState, onLoading, onError, onData } : WithHandledStatesProps<Data>) => {

  if(dataState.isLoading) {
    return onLoading()
  }

  if(dataState.isError) {
    return onError()
  }

  if(dataState.data) {
    return onData(dataState.data)
  }
  return null
}

export default WithHandledStates
