import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SetURLSearchParams } from 'react-router-dom';
import { ActivityOutcome, SportEnumValues } from '../backend';

export const SPORT_URL_PARAM = 'sport';
export const OUTCOME_URL_PARAM = 'outcome';
export const MARKET_TYPE_ID_URL_PARAM = 'market';

export type State = {
  search: string;
  outcomeFilters: ActivityOutcome[];
  sports: SportEnumValues[];
  marketTypeIds: number[];
};

const initialState: State = {
  search: '',
  outcomeFilters: [],
  sports: [],
  marketTypeIds: [],
};

const syncUrlParams = (
  filterType: string,
  filters: string[] | number[],
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
) => {
  searchParams.delete(filterType);
  filters.forEach((filter) => {
    searchParams.append(filterType, filter.toString());
  });
  setSearchParams(searchParams);
};

// Redux slice for managing filters
const slice = createSlice({
  name: 'activityFilters',
  initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },

    // Set outcome filters
    setOutcomeFilters(state, action: PayloadAction<ActivityOutcome[]>) {
      state.outcomeFilters = action.payload;
    },

    // Toggle outcome filters and sync with URL
    toggleOutcomeFilterAndSynchUrl: (
      state,
      action: PayloadAction<{
        outcomeFilters: ActivityOutcome[];
        searchParams: URLSearchParams;
        setSearchParams: SetURLSearchParams;
      }>,
    ) => {
      state.outcomeFilters = action.payload.outcomeFilters;
      syncUrlParams(
        OUTCOME_URL_PARAM,
        state.outcomeFilters,
        action.payload.searchParams,
        action.payload.setSearchParams,
      );
    },

    // Set sport filters
    setSportFilters(state, action: PayloadAction<SportEnumValues[]>) {
      state.sports = action.payload;
    },

    // Toggle sport filters and sync with URL
    toggleSportFilterAndSynchUrl(
      state,
      action: PayloadAction<{
        sport: SportEnumValues[];
        searchParams: URLSearchParams;
        setSearchParams: SetURLSearchParams;
      }>,
    ) {
      state.sports = action.payload.sport;
      syncUrlParams(
        SPORT_URL_PARAM,
        state.sports,
        action.payload.searchParams,
        action.payload.setSearchParams,
      );
    },

    // Set market type filters
    setMarketTypeIdFilter(state, action: PayloadAction<number[]>) {
      state.marketTypeIds = action.payload;
    },

    // Toggle market type filters and sync with URL
    toggleMarketTypeIdFilterAndSynchUrl(
      state,
      action: PayloadAction<{
        marketTypeIds: number[];
        searchParams: URLSearchParams;
        setSearchParams: SetURLSearchParams;
      }>,
    ) {
      state.marketTypeIds = action.payload.marketTypeIds;
      syncUrlParams(
        MARKET_TYPE_ID_URL_PARAM,
        state.marketTypeIds,
        action.payload.searchParams,
        action.payload.setSearchParams,
      );
    },

    // Clear all filters and sync URL
    clearActivityFilters(
      state,
      action: PayloadAction<{
        searchParams: URLSearchParams;
        setSearchParams: SetURLSearchParams;
      }>,
    ) {
      state.sports = initialState.sports;
      state.marketTypeIds = initialState.marketTypeIds;

      const { searchParams, setSearchParams } = action.payload;
      syncUrlParams(
        SPORT_URL_PARAM,
        state.sports,
        searchParams,
        setSearchParams,
      );
      syncUrlParams(
        OUTCOME_URL_PARAM,
        state.outcomeFilters,
        searchParams,
        setSearchParams,
      );
      syncUrlParams(
        MARKET_TYPE_ID_URL_PARAM,
        state.marketTypeIds,
        searchParams,
        setSearchParams,
      );
    },
  },
});

export const {
  setSearch,
  setOutcomeFilters,
  toggleOutcomeFilterAndSynchUrl,
  setSportFilters,
  toggleSportFilterAndSynchUrl,
  setMarketTypeIdFilter,
  toggleMarketTypeIdFilterAndSynchUrl,
  clearActivityFilters,
} = slice.actions;

export default slice.reducer;
