interface MarketSelected {
  $isMarketSelected: boolean;
  $isDisabled: boolean;
}

export const HouseParamsContainer = `w-full mt-4 mb-4 xl:flex xl:items-end`;
export const HouseParamsWrapper = `w-full`;
export const HouseParamsStats = `w-full flex justify-between items-start mb-2`;
export const FlexItemsCenter = `flex items-center mb-2`;
export const FlexCenterBMargin = `mb-2`;
export const FlexItemsWrapper = `flex`;
export const FlexItemsCenterMargined = `flex items-center relative mr-3`;
export const SpanMargined = `mr-1`;
export const HouseImage = `w-4 5xl:w-6`;
export const HouseParamText = `text-xs 3xl:text-sm 4xl:text-base 5xl:text-lg text-white font-normal whitespace-nowrap uppercase`;
export const ToolTipText = `rounded-md 5xl:rounded-xl w-auto shadow-md shadow-black/50 whitespace-nowrap absolute invisible py-1.5 px-3 font-normal text-xs 3xl:text-base 4xl:text-lg 5xl:text-2xl 6xl:text-3xl bg-tooltip-bg-1 font-normal text-white -translate-x-2/4 left-1/2 top-auto bottom-[calc(100%_+_7px)] after:content-[''] after:absolute after:top-full after:left-1/2 after:border-[6px] after:border-border-primary-3 after:border-t-border-primary-3 after:border-border-primary-3 after:border-b-border-primary-3 after:-translate-x-2/4`;
export const BeTheHouseCTA = `lg:cursor-pointer w-full sm:w-80`;

export const HouseText = ({
  $isMarketSelected,
  $isDisabled,
}: MarketSelected) => `w-full sm:w-80 tracking-wider rounded-md bg-transparent px-4 py-2.5 text-text-brand-1 uppercase font-bold text-sm xxxs:text-base text-center border border-border-bg-2 relative overflow-hidden
      ${$isMarketSelected ? '!text-text-brand-1' : ''}
      ${$isMarketSelected ? ' font-bold' : ''}
      ${$isDisabled ? ' pointer-events-none opacity-25' : ''}`;
export const BackButtonImage = `rotate-90 ml-1 inline-block w-4 -mt-1`;
export const MobEventWraper = `block lg:hidden`;
export const FlexDetailWraper = `flex items-center mb-2`;
export const HouseParamTextMob = `text-xs 3xl:text-sm 4xl:text-base 5xl:text-lg text-text-gray-2 font-normal mx-1`;
export const HouseValueTextMob = `text-xs 3xl:text-sm 4xl:text-base 5xl:text-lg text-text-gray-1 font-normal`;
export const Separator = `hidden lg:block h-0 border border-solid border-t-0 border-white`;
export const EventSeparator = `h-0 my-4 border border-solid border-t-0 border-white`;
export const ShowInmob = `block`;
export const ShowInWeb = `hidden lg:block`;
export const ModeImages = `w-4 h-4 mr-1`;
export const LimitMsg = `text-center text-sm text-text-danger-1 p-2.5`;
export const ContainerForTwoEvent = `5xl:ml-6`;
export const HouseParamValueText = `text-xs xxs:text-sm text-text-gray-2 font-medium whitespace-wrap uppercase truncate`;
export const ToolTipContainer = `group flex items-center ml-1 lg:cursor-pointer relative`;
export const ToolTipContents = `group-hover:!visible rounded-md 5xl:rounded-xl 5xl:rounded-xl w-auto shadow-md shadow-black/50 whitespace-nowrap absolute invisible py-1.5 px-3 font-normal text-xs 3xl:text-base 4xl:text-lg 5xl:text-2xl 6xl:text-3xl 6xl:text-3xl bg-tooltip-bg-1 font-normal text-white -translate-x-2/4 -left-8 top-auto bottom-[calc(100%_+_7px)] after:content-[''] after:absolute after:-bottom-1 after:left-[80%] after:-translate-x-2/4 after:h-2.5 after:w-2.5 after:bg-tooltip-bg-1 after:rotate-45`;

export const HouseParamContainer = `w-full mb-2 xl:flex xl:mb-1 xl:space-x-6`;
export const HouseParamItems = `w-full mb-2 xl:w-auto xl:mb-3`;
export const ParamItemsText = `text-white font-light text-sm 3xl:text-base 4xl:text-lg 5xl:text-xl`;
export const ParamValueItemsText = `text-white truncate max-w-20 inline-block font-normal text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl`;
export const SgeText = `text-white font-light text-xxxs 3xl:text-xxs 4xl:text-xs 4xl:text-sm`;
export const ParamIcon = `w-3.5 3xl:w-4 mt-0.5`;
export const ParamIconBet = `w-3 3xl:w-4 mt-0.5`;
export const HouseIcon = `size-4 3xl:size-6 mr-2 3xl:mr-3`;
export const HouseButton = `w-full sm:w-80 tracking-wider rounded-md bg-brand-bg-1 px-4 py-3 text-black font-bold text-sm xxxs:text-base`;
export const MarketCardContainer = `border-b border-border-bg-1 bg-primary-bg-2 pb-4 mb-2`;
export const MarketHouseButton = `w-full sm:w-80 tracking-wider rounded-md bg-transparent px-4 py-3 text-white font-medium text-sm xxxs:text-base border border-border-bg-2`;
export const BeHouseText = `text-white font-semibold text-xxs xxxs:text-xs 3xl:text-base 4xl:text-lg uppercase`;
export const ParamValueWrapper = `flex space-x-1 items-baseline`;
