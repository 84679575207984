import { useQuery } from '@tanstack/react-query';
import { reloadOutcomes } from '../backend';
import * as outcomeSliceEvents from '#/utils/slices/outcomesSlice';
import * as marketSliceEvents from '#/utils/slices/marketsSlice';
import { useAppDispatch } from '../store';

type UseOutcomesReloaderProps = {
  outcomeIds: string[];
  marketIds: number[];
};

const useOutcomesReloader = ({
  outcomeIds,
  marketIds,
}: UseOutcomesReloaderProps) => {
  const dispatch = useAppDispatch();

  return useQuery({
    queryKey: [`odds`],
    enabled: outcomeIds.length > 0 || marketIds.length > 0,
    refetchInterval: 3000,
    queryFn: () =>
      reloadOutcomes(outcomeIds, marketIds).then((data) => {
        dispatch(outcomeSliceEvents.updateOutcomes(data.outcomes));
        dispatch(marketSliceEvents.updateMarkets(data.markets));

        return data;
      }),
  });
};

export default useOutcomesReloader;
