import { useState, useEffect } from 'react';
import Transactions from '#/components/Transactions/Transactions.component';
import useTransactionsDataLoader from '#/utils/dataLoaders/useTransactionsLoader';
import { SEARCH_ICON_LIGHT } from '#/constants/common/images.constants';
import { DropDownInnerWrapper, DropDownStyle, FilterBtn, FiltersWrapper, Header, PageTitle } from './Transactions/Transactions.styled';
import FilterButton from '#/components/common/FilterButton.component';
import { SearchInputField, SearchInputFieldWrapper, SearchLeftIcon } from './common/Search/Search.styled';
import TransactionsFilter from './Transactions/TransactionsFilter.component';
import { AppToast } from './common/ToastNotification/ToastConfig';
import { toast } from 'react-toastify';
import useCosmosKitWallet from '#/hooks/useCosmosKitWallet';
import StatusFilter from './Transactions/StatusFilter.component';
import TypeFilter from './Transactions/TypeFilter.component';
import useClickOutside from '#/hooks/useClickOutside';
import { useSearchParams } from 'react-router-dom';
import { gtmEvent } from '#/utils/appUtils';

export default function TransactionsList() {
  const pageSize = 100;
  const { account } = useCosmosKitWallet();
  const [showFilters, setShowFilters] = useState(false);
  const [showTypeDropdown, setShowTypeDropdown] = useState(false);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedStatus, setSelectedStatus] = useState<{ [key: string]: boolean }>({
    success: false,
    failed: false,
  });

  const [selectedType, setSelectedType] = useState<{ [key: string]: boolean }>({
    bet_placement: false,
    reward: false,
  });

  const transactionsQuery = useTransactionsDataLoader({
    pageSize,
    address: account?.address,
  });

  const { data, isLoading, error } = transactionsQuery;

  useEffect(() => {
    const statusFilters = [];
    if (selectedStatus.success) statusFilters.push('success');
    if (selectedStatus.failed) statusFilters.push('failed');

    if (statusFilters.length > 0) {
      searchParams.set('statusFilter', statusFilters.join(','));
    } else {
      searchParams.delete('statusFilter');
    }

    const typeFilters = [];
    if (selectedType.bet_placement) typeFilters.push('bet_placement');
    if (selectedType.reward) typeFilters.push('reward');

    if (typeFilters.length > 0) {
      searchParams.set('typeFilter', typeFilters.join(','));
    } else {
      searchParams.delete('typeFilter');
    }

    setSearchParams(searchParams);
  }, [selectedStatus, selectedType, searchParams, setSearchParams]);

  const dropdownRef = useClickOutside<HTMLDivElement>(() => setShowStatusDropdown(false));
  const dropdownRefType = useClickOutside<HTMLDivElement>(() => setShowTypeDropdown(false));

  if (error) {
    toast.error(<AppToast id={'UnableToFetch'} />, { icon: false });
  }

  const toggleTypeDropdown = () => {
    setShowTypeDropdown((prev) => !prev);
    setShowStatusDropdown(false);
    gtmEvent({ action: "toggle_type_filter_dropdown"})
  };

  const toggleStatusDropdown = () => {
    setShowStatusDropdown((prev) => !prev);
    setShowTypeDropdown(false);
    gtmEvent({ action: "toggle_status_filter_dropdown"})
  };

  return (
    <div className="px-4">
      <div className={Header}>
        <h2 className={PageTitle}>Transactions</h2>
      </div>
      <div className="flex items-center">
        <div className={SearchInputFieldWrapper}>
          <img
            className={SearchLeftIcon}
            src={SEARCH_ICON_LIGHT}
            alt="icon"
            loading="lazy"
          />
          <input
            className={SearchInputField}
            aria-label={'Search'}
            type="text"
            placeholder="Search"
            autoComplete="off"
          />
        </div>
        <div className="lg:hidden">
          <FilterButton onClick={() => setShowFilters(!showFilters)} />
        </div>
        <div className={FiltersWrapper}>
          <span className="relative">
            <span
              className={FilterBtn}
              onClick={toggleStatusDropdown}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  toggleStatusDropdown();
                }
              }}
            >
              Status
            </span>
            {showStatusDropdown && (
              <div className={DropDownStyle} ref={dropdownRef}>
                <div className={DropDownInnerWrapper}>
                  <StatusFilter
                    selectedStatus={selectedStatus}
                    setSelectedStatus={setSelectedStatus}
                  />
                </div>
              </div>
            )}
          </span>
          <span className="relative">
            <span
              className={FilterBtn}
              onClick={toggleTypeDropdown}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  toggleTypeDropdown();
                }
              }}
            >
              Bet Type
            </span>
            {showTypeDropdown && (
              <div className={DropDownStyle} ref={dropdownRefType}>
                <div className={DropDownInnerWrapper}>
                  <TypeFilter
                    selectedType={selectedType}
                    setSelectedType={setSelectedType}
                  />
                </div>
              </div>
            )}
          </span>
        </div>
      </div>

      <Transactions data={transactionsQuery} dataSelector={(data) => data.data } />

      {showFilters ? (
        <TransactionsFilter
          isOpen={showFilters}
          onClose={() => setShowFilters(false)}
        />
      ) : null}
    </div>
  );
}
