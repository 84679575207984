export const NavContainer = `flex justify-between items-center xl:justify-end`;
export const SgeLogo = `w-8`;
export const MenuIcon = `w-4 xxxs:w-7`;
export const WalletIcon = `w-5 mr-2`;
export const WalletButton = `flex justify-center items-center rounded-llg bg-text-warning-1 px-4 py-2 text-black font-medium text-sm xxxs:text-base h-8 xxxs:h-9`;
export const NavButtonWrapper = `flex items-center space-x-3 xxs:space-x-6`;
export const MenuIconBtn = `text-white font-medium xl:hidden`;
export const WalletBtnIcon = `w-4.5 xl:w-6 3xl:w-8  mr-2 3xl:mr-3`;
export const DecimalText = `text-xs 3xl:text-base`;
export const LoginButton = `w-auto rounded-[5px] px-2 py-1 border border-brand-bg-1 text-white text-sm font-light text-center`;
export const SignupButton = `w-auto rounded-[5px] px-2 py-1 border border-brand-bg-1 bg-brand-bg-1 text-black text-sm font-normal text-center`;
export const LogoutButton = `w-auto rounded-[5px] px-2 py-1.5 border border-brand-bg-1 bg-brand-bg-1 text-black text-sm font-normal text-center`;
export const DropDownStyle = `bg-primary-bg-2 rounded-llg py-5 px-2 absolute -right-2 mt-4 h-auto z-[2] w-[420px] 3xl:w-[480px] [box-shadow:0px_0px_15px_5px_#FFFFFF1A;]`;
export const DropDownInnerWrapper = `overflow-y-auto max-h-[570px]`;
