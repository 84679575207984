import Button from '#/components/common/Buttons/Button/Button.component';
import { ARROW_BACK } from '#/constants/common/images.constants';
import { useState } from 'react';
import {
  BackBtn,
  BackBtnIcon,
  BgContainer,
  BgInnerContainer,
  ButtonNext,
  ButtonWhite,
  ButtonWrapper,
  ModalContentWrapper,
  NavDotsActive,
  NavDotsInactive,
  NavDotsWrapper,
  StepContents,
  WalletContentText,
  HeadTitle,
} from './KycInfo.styled';
import CenterModal from '#/components/common/CenterModal.component';

const KycInfo = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [setCurrentStep, setShowCurrentStep] = useState(0);

  const handleButtonNext = () => {
    setShowCurrentStep(setCurrentStep + 1);
  };

  const handleButtonBack = () => {
    setShowCurrentStep(setCurrentStep - 1);
  };
  const navigationDots = () => {
    const numbers = [0, 1, 2, 3];
    return numbers.map((number) => (
      <span
        key={number}
        className={setCurrentStep === number ? NavDotsActive : NavDotsInactive}
      />
    ));
  };
  return (
    <CenterModal isOpen={isOpen} onClose={onClose}>
      <div className={BgContainer}>
        <div className={BgInnerContainer}></div>
      </div>
      <div className={ModalContentWrapper}>
        <div
          className={StepContents}
          style={{ display: setCurrentStep === 0 ? 'block' : 'none' }}
        >
          <h2 className={HeadTitle}>What is KYC?</h2>
          <p className={WalletContentText}>
            KYC (Know Your Customer) is a verification process to confirm your
            identity, ensuring a secure and compliant environment.
          </p>
          <div className={ButtonWrapper}>
            <span />
            <Button
              className={`${ButtonWhite} ${ButtonNext}`}
              onClick={handleButtonNext}
              dataId="kyc_info_next"
            >
              Next
            </Button>
          </div>
        </div>
        <div
          className={StepContents}
          style={{ display: setCurrentStep === 1 ? 'block' : 'none' }}
        >
          <h2 className={HeadTitle}>Why Synaps KYC?</h2>
          <p className={WalletContentText}>
            Synaps KYC is quick, secure, and designed to protect your privacy
            while meeting regulatory requirements.
          </p>
          <div className={ButtonWrapper}>
            <Button className={BackBtn} onClick={handleButtonBack} dataId="kyc_info_back">
              <span className="flex items-center">
                <img
                  className={BackBtnIcon}
                  src={ARROW_BACK}
                  alt="Arrow icon"
                />{' '}
                Back
              </span>
            </Button>
            <Button
              className={`${ButtonWhite} ${ButtonNext}`}
              onClick={handleButtonNext}
              dataId="kyc_info_next"
            >
              Next
            </Button>
          </div>
        </div>
        <div
          className={StepContents}
          style={{ display: setCurrentStep === 2 ? 'block' : 'none' }}
        >
          <h2 className={HeadTitle}>How It Works</h2>
          <p className={WalletContentText}>
            Provide your ID, proof of address, and a selfie. Our system will
            verify your identity in a few easy steps.
          </p>
          <div className={ButtonWrapper}>
            <Button className={BackBtn} onClick={handleButtonBack} dataId="kyc_info_back">
              <span className="flex items-center">
                <img
                  className={BackBtnIcon}
                  src={ARROW_BACK}
                  alt="Arrow icon"
                />{' '}
                Back
              </span>
            </Button>
            <Button
              className={`${ButtonWhite} ${ButtonNext}`}
              onClick={handleButtonNext}
              dataId="kyc_info_next"
            >
              Next
            </Button>
          </div>
        </div>
        <div
          className={StepContents}
          style={{ display: setCurrentStep === 3 ? 'block' : 'none' }}
        >
          <h2 className={HeadTitle}>Your Data is Safe</h2>
          <p className={WalletContentText}>
            All your information is encrypted and used solely for verification
            purposes.
          </p>
          <div className={ButtonWrapper}>
            <Button className={BackBtn} onClick={handleButtonBack} dataId="kyc_info_back">
              <span className="flex items-center">
                <img
                  className={BackBtnIcon}
                  src={ARROW_BACK}
                  alt="Arrow icon"
                />{' '}
                Back
              </span>
            </Button>
            <Button
              className={`${ButtonWhite} ${ButtonNext}`}
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
              dataId="kyc_info_end"
            >
              Continue
            </Button>
          </div>
        </div>
        <div className={NavDotsWrapper}>{navigationDots()}</div>
      </div>
    </CenterModal>
  );
};

export default KycInfo;
