import { SigningCosmWasmClient } from '@cosmjs/cosmwasm-stargate';
import { ChainWalletBase } from '@cosmos-kit/core';
import { useQuery } from '@tanstack/react-query';

// Needed so that cosmos kit works properly. DO NOT REMOVE
SigningCosmWasmClient;

const useSigningCosmWasmClient = ({
  wallet,
}: {
  wallet: ChainWalletBase | undefined;
}) => {
  return useQuery({
    queryKey: ['signingCosmWasmClient', wallet?.walletName],
    queryFn: async () => {
      if (wallet) {
        return wallet.getSigningCosmWasmClient();
      }
    },
    enabled: !!wallet,
    structuralSharing: false,
  });
};

export default useSigningCosmWasmClient;
