import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import FiltersButton from '../Filters/FiltersButton.component';
import Modal from '../common/Modal.component';
import StatusFilter from './StatusFilter.component';
import { HeadDivider } from './Transactions.styled';
import TypeFilter from './TypeFilter.component';

const TransactionsFilter = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedStatus, setSelectedStatus] = useState<{ [key: string]: boolean }>({
    success: false,
    failed: false,
  });

  const [selectedType, setSelectedType] = useState<{ [key: string]: boolean }>({
    bet_placement: false,
    reward: false,
  });

  useEffect(() => {
    const statusFilters = searchParams.get('statusFilter')?.split(',') || [];
    const typeFilters = searchParams.get('typeFilter')?.split(',') || [];

    setSelectedStatus({
      success: statusFilters.includes('success'),
      failed: statusFilters.includes('failed'),
    });

    setSelectedType({
      bet_placement: typeFilters.includes('bet_placement'),
      reward: typeFilters.includes('reward'),
    });
  }, [searchParams]);

  function applyFilters() {
    const statusFilters = [];
    if (selectedStatus.success) statusFilters.push('success');
    if (selectedStatus.failed) statusFilters.push('failed');

    const typeFilters = [];
    if (selectedType.bet_placement) typeFilters.push('bet_placement');
    if (selectedType.reward) typeFilters.push('reward');

    if (statusFilters.length > 0) {
      searchParams.set('statusFilter', statusFilters.join(','));
    } else {
      searchParams.delete('statusFilter');
    }

    if (typeFilters.length > 0) {
      searchParams.set('typeFilter', typeFilters.join(','));
    } else {
      searchParams.delete('typeFilter');
    }

    setSearchParams(searchParams);
    onClose();
  }

  function clearFilters() {
    searchParams.delete('statusFilter');
    searchParams.delete('typeFilter');
    setSelectedStatus({ success: false, failed: false });
    setSelectedType({ bet_placement: false, reward: false });
    setSearchParams(searchParams);
    onClose();
  }

  return (
    <Modal title="Filters" isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col p-5">
        <StatusFilter
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
        <TypeFilter
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        />
        <hr className={`${HeadDivider} !mt-0`} />
        <FiltersButton
          clearFilters={clearFilters}
          applyFilters={applyFilters}
        />
      </div>
    </Modal>
  );
};

export default TransactionsFilter;
