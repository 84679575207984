// src/store/responsibleGamblingSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BetLimitState {
  dailyLimit: number;
  weeklyLimit: number;
  monthlyLimit: number;
}

interface TimeLimitState {
  reminderFrequency: number | null;
}

export interface TimeoutPeriodState {
  appliedAt: string | null;
  duration: number | null;
}

interface ResponsibleGamblingState {
  betLimit: BetLimitState;
  timeLimit: TimeLimitState;
  timeoutPeriod: TimeoutPeriodState;
}

const initialState: ResponsibleGamblingState = {
  betLimit: {
    dailyLimit: 0,
    weeklyLimit: 0,
    monthlyLimit: 0,
  },
  timeLimit: {
    reminderFrequency: null,
  },
  timeoutPeriod: {
    appliedAt: null,
    duration: null,
  },
};

const responsibleGamblingSlice = createSlice({
  name: 'responsibleGambling',
  initialState,
  reducers: {
    setBetLimit: (state, action: PayloadAction<BetLimitState>) => {
      state.betLimit = action.payload;
    },
    setTimeLimit: (state, action: PayloadAction<TimeLimitState>) => {
      state.timeLimit = action.payload;
    },
    setTimeoutPeriod: (
      state,
      action: PayloadAction<{
        appliedAt: string | null;
        duration: number | null;
      }>,
    ) => {
      state.timeoutPeriod = action.payload;
    },
    resetLimits: (state) => {
      state.betLimit = initialState.betLimit;
      state.timeLimit = initialState.timeLimit;
      state.timeoutPeriod = initialState.timeoutPeriod; // Reset both appliedAt and duration
    },
  },
});

export const { setBetLimit, setTimeLimit, setTimeoutPeriod, resetLimits } =
  responsibleGamblingSlice.actions;

export default responsibleGamblingSlice.reducer;
