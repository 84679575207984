export const PageContainer = `w-full mt-4 px-4 xl:mt-0`;
export const Header = `w-full flex items-center`;
export const PageTitle = `text-white font-normal text-sm xxxs:text-xl 2xl:text-2xl 3xl:text-2xl 4xl:text-3xl tracking-wider`;
export const PageSubTitle = `text-white font-normal text-xs xxxs:text-sm 2xl:text-base 3xl:text-lg 4xl:text-xl tracking-wider mb-3`;
export const ConversionList = `list-none text-sm 2xl:text-base 3xl:text-lg 4xl:text-xl text-white font-light mb-3`;
export const FieldContainer = `w-full mb-4`;
export const InputLabel = `block text-white font-normal text-sm 2xl:text-base 4xl:text-lg 5xl:text-xl`;
export const InputField = `w-full bg-primary-bg-3 text-base 2xl:text-lg 3xl:text-xl 4xl:text-2xl font-normal text-white placeholder:text-white focus:outline-none pl-4 pr-12 py-2 2xl:py-4 rounded-md mt-2 !bg-[url('/v2-icons/downarrow-icon-brand.svg')] bg-[length:13px_19px]`;
export const OddsDisplayMsg = `bg-toast-bg-1 rounded-md p-4 flex items-center text-sm xl:text-base 2xl:text-lg 3xl:text-xl 4xl:text-2xl font-normal text-white`;
export const InfoIcon = `w-7 mr-3 4xl:w-8 4xl:mr-4`;
export const DefaultSelectedOdd =
  'w-full bg-primary-bg-3 text-base font-normal text-white pl-4 pr-12 py-2 rounded-md mt-2 bg-[length:13px_19px]';
export const OddsConversionTitle = `text-white font-normal text-base xxxs:text-xl 2xl:text-2xl 3xl:text-2xl 4xl:text-3xl tracking-wider mb-3`;
export const OddsTitle = `text-base 2xl:text-lg 3xl:text-xl 4xl:text-2xl font-light text-white`;
export const OddsInputField = `w-1/7 bg-transparent text-base 2xl:text-lg 3xl:text-xl 4xl:text-2xl font-bold text-white placeholder:text-white !bg-[url('/v2-icons/downarrow-icon-brand.svg')] bg-[length:16px_19px] pl-4 pr-8 py-2 focus:outline-none`;
export const OddsConversionContainer = `flex flex-col mt-8`;
export const SelectField = `lg:bg-[url('/v2-icons/brand-down.svg')] 2xl:bg-[96%_calc(100%_-_18px)]`;
