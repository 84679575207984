/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from '@tanstack/react-query';
import { TransactionsData, loadTransactions } from '../backend';
import useCosmosKitWallet from '#/hooks/useCosmosKitWallet';

export type UseTransactionsDataLoaderProps = {
  page?: number;
  pageSize?: number;
  address?: string;
};

const useTransactionsDataLoader = ({
  page = 0,
  pageSize = 10,
  address,
}: UseTransactionsDataLoaderProps) => {
  const { tendermintClient } = useCosmosKitWallet();

  return useQuery<TransactionsData>({
    queryKey: ['transactions', address, page, pageSize],
    queryFn: async () => {
      return loadTransactions(page, pageSize, address, tendermintClient);
    },
    enabled: address !== undefined && tendermintClient !== undefined,
  });
};

export default useTransactionsDataLoader;
