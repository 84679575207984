import { ChainName, Logger, WalletManager } from '@cosmos-kit/core';

import wallets from '#/utils/wallets';
import env from '#/utils/env';
import assets from '#/utils/assets';
import { Chain } from '@chain-registry/types';
import { GasPrice } from '@cosmjs/stargate';
import { Decimal } from '@cosmjs/math';
import { SSS_FULL_LOGO } from '#/constants/common/images.constants';

let walletManager_ = undefined;
let walletRepo_ = undefined;

if (env.chain) {
  const newWalletManager = new WalletManager(
    [env.chain],
    wallets,
    // I've never seen cosmos-kit log anything that would be useful
    // and it throws errors when probing clients
    new Logger('NONE'),
    false, // We don't want to throw errors - pollutes the console
    true,
    undefined,
    assets,
    'icns',
    {
      signClient: {
        projectId: env.walletConnectProjectId,
        metadata: {
          name: "Six Sigma Sports",
          description: "Six Sigma Sports",
          url: window.location.origin,
          icons: [
            `${window.location.origin}${SSS_FULL_LOGO}`
          ],
          
        }
      },
    },
    {
      signingCosmwasm: (chain: Chain | ChainName) => {
        const chainName = typeof chain === 'string' ? chain : chain.chain_name;
        switch (chainName) {
          case env.chain?.chain_name:
            return {
              gasPrice: new GasPrice(
                Decimal.fromUserInput('1000000', 1),
                'usge',
              ),
            };
          default:
            return void 0;
        }
      },
    },
    undefined,
    undefined,
  );

  const cosmosRepo = newWalletManager.walletRepos.find(
    (walletRepo) => walletRepo.namespace === 'cosmos',
  );

  if (cosmosRepo) {
    walletManager_ = newWalletManager;
    walletRepo_ = cosmosRepo;
  }
}

export const walletManager = walletManager_;
export const walletRepo = walletRepo_;
