import { useInfiniteQuery } from '@tanstack/react-query';
import { BetActivityOutcomes, loadInfiniteBets } from '../backend';
import useBackendUserData from './useBackendUserData';

export interface BetFilters {
  ids?: string[];
  marketTypes?: number[];
  outcomes?: BetActivityOutcomes[];
  sports?: string[];
}

interface UseBetsDataLoaderParams {
  userId: string;
  sgeAddress: string;
  filters?: BetFilters;
  search?: string;
  refetchInterval?: number;
}

const useInfiniteBetsDataLoader = ({
  filters = {},
  search = '',
  refetchInterval,
}: UseBetsDataLoaderParams) => {
  const { data: user } = useBackendUserData()

  const sgeAddress = user?.walletAddr
  const userId = user?.id

  return useInfiniteQuery({
    queryKey: ['betsDataLoader', userId, filters, search],
    enabled: !!userId && !!sgeAddress,
    queryFn: ({ pageParam = 0 }) => {
      if (userId && sgeAddress) {
        return loadInfiniteBets({ userId, offset: pageParam, filters, search });
      }
      return { bets: [], total: 0, offset: pageParam };
    },
    refetchInterval: refetchInterval ?? false,
    getNextPageParam: (lastPage, allPages) => {
      const totalLoaded = allPages.reduce((acc, page) => acc + page.bets.length, 0);
      if (totalLoaded < lastPage.total) {
        return totalLoaded;
      } else {
        return undefined;
      }
    },
    initialPageParam: 0
  });
};

export default useInfiniteBetsDataLoader;

