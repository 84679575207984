export const ContentContainer = `w-full lg:w-96 mt-4`;
export const Header = `w-full flex items-center`;
export const PageTitle = `text-white font-normal text-base xxxs:text-xl 3xl:text-2xl tracking-wider`;
export const PageSubTitle = `text-white font-normal text-sm 3xl:text-lg tracking-wider`;
export const HeaderIcon = `w-5 mr-4`;
export const InputLabel = `block text-sm text-text-gray-2 font-normal`;
export const InputField = `w-full font-normal bg-primary-bg-3 text-base text-text-gray-1 placeholder:text-gray-1 focus:outline-none pl-4 pr-12 py-2 border-0 rounded-md mt-2`;
export const FieldContainer = `w-full my-4`;
export const EditIcon = `w-5 mr-2 cursor-pointer`;
export const ProfileImageContainer = `size-16 rounded-full p-0 overflow-hidden relative bg-white`;
export const FileInputLabel = `text-xs font-thin text-text-gray-1 uppercase text-center `;
export const ProfileImg = `w-full h-full object-center origin-center object-cover`;
export const ProfileImgWrapper = `mr-4`;
export const FileInput = `text-sm text-text-gray-1 placeholder:text-text-gray-2 placeholder:opacity-50 focus:outline-none py-2 pl-3 border border-white rounded w-full mt-2 pr-8`;
export const UploadBtn = `w-full h-full flex items-center px-2 py-1 text-text-gray-1 cursor-pointer relative z-1`;
export const UploadInput = `w-full h-full absolute left-0 top-0 text-5xl font-normal opacity-0`;

export const WraperIsolate = `absolute bottom-0 h-full w-full left-0`;
export const ButtonSave = `w-full text-center sm:w-80 lg:w-96 tracking-wider rounded-md bg-brand-bg-1 px-4 py-2.5 text-black font-bold text-sm xxxs:text-base`;
export const ButtonSaveWrapper = `mt-6`;
export const SettingText = `text-white font-normal text-base xxxs:text-xl 3xl:text-2xl tracking-wider mb-5`;
export const SettingItems = `flex text-white font-light text-base 2xl:text-xl tracking-wider mb-3`;
export const SelectField = `w-full bg-transparent text-base font-normal text-white placeholder:text-white focus:outline-none pl-1 pr-8 rounded-md bg-[90%_6px] bg-[length:16px_14px]`;
