export const OddEventsContainer = ({
  $isDisabled,
}: {
  $isDisabled: boolean;
}) => `flex min-w-[40%] mt-0 lg:mt-2 xl:mt-0 flex-row justify-end items-center lg:cursor-pointer
      ${$isDisabled ? ' pointer-events-none opacity-25' : ''}`;
export const OddName = `hidden lg:block text-xs 2xl:text-sm 3xl:text-base 4xl:text-lg 5xl:text-2xl 6xl:text-3xl text-text-gray-2 mb-2`;
export const HouseMode = `border-0 lg:border border-white rounded-full text-xs md:text-sm 3xl:text-base 4xl:text-lg 5xl:text-2xl 6xl:text-3xl inline-flex items-center
   justify-center px-1 lg:px-2 lg:w-24 md:h-8 text-text-brand-1 lg:text-white 3xl:w-28 3xl:h-10 4xl:w-40 4xl:h-11 6xl:w-52 6xl:h-16 6xl:mr-5`;
export const OddsContainer = `flex flex-col justify-center items-center text-xs lg:text-base font-normal lg:font-bold flex-1`;
export const FlexColWrapper = `flex flex-col`;
export const LimitMsg = `text-center text-sm text-text-danger-1`;
export const MainMarketOddsContainer =
  'flex flex-col flex-1 justify-center items-center text-xs lg:text-base font-normal lg:font-bold';
//  Style for V2
export const OddNameMobile = `text-xs 3xl:text-sm 4xl:text-base 5xl:text-lg 6xl:text-xl leading-3 font-normal text-white truncate text-center max-w-16 mb-1`;
export const OddEventsContainerMarket = ({
  $isDisabled,
}: {
  $isDisabled: boolean;
}) => `flex w-full flex-row justify-between sm:justify-start items-center lg:cursor-pointer space-x-2
      ${$isDisabled ? ' pointer-events-none opacity-60' : ''}`;
export const FlexColContainer = `flex flex-col justify-between w-full`;
