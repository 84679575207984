import { Big, BigDecimal } from 'bigdecimal.js';

export const stringToBigdecimal = (input: string): BigDecimal | undefined => {
  try {
    return Big(input);
  } catch {
    return undefined;
  }
};

export const stringToBigdecimalWithDefault = (input: string, fallback: BigDecimal): BigDecimal => {
  return stringToBigdecimal(input) || fallback
};

export const stringToBigdecimalOrZero = (input: string): BigDecimal => {
  return stringToBigdecimalWithDefault(input, Big('0'))
};
