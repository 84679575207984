export const MatchCardContainer = `flex flex-wrap lg:items-center justify-between flex-row px-4 pt-5 5xl:px-6 5xl:pt-8 6xl:pt-12 relative lg:cursor-pointer items-start xl:flex-row xl:items-center 2xl:items-baseline relative`;
export const MatchContainer = ({
  isDisabled,
  isMainMarketFixture,
}: {
  isDisabled: boolean;
  isMainMarketFixture: boolean;
}) =>
  `overflow-y-hidden mb-4${isMainMarketFixture ? ' border border-solid border-border-bg-2' : ''
  }${isDisabled ? ' opacity-25 pointer-events-none' : ''}`;

export const MatchSeparator = `h-0 border border-solid border-t-0 border-card-bg-2`;
export const FlexContainer = `flex`;
export const FlexContainerBeHouse = `flex items-center my-0`;
export const StarIcon = `w-4 3xl:w-6 6xl:w-9 h-4 3xl:h-6 6xl:h-9 ml-3 md:mx-2 self-center cursor-pointer`;
// Style for V2
export const MatchCardWrapper = `flex flex-wrap lg:items-center justify-between flex-row relative lg:cursor-pointer items-start xl:flex-nowrap xl:items-center 2xl:items-baseline relative`;
export const FavIcon = `w-4 3xl:w-6 6xl:w-9 h-4 3xl:h-6 6xl:h-9 ml-1 md:mx-2 self-center cursor-pointer`;
export const MatchDivider = `h-0 border border-solid border-t-0 border-border-bg-1 mb-4`;
export const ForWebFlex = `lg:flex-1 lg:w-full`;
export const BetCapacityWrapper = `text-white text-sm 3xl:text-base border border-[#50525a] p-2 rounded-md`;
export const HouseParamContainer = `w-full mb-2 xl:flex xl:mb-1 xl:space-x-6`;
export const HouseParamItems = `w-full mb-2 xl:w-auto xl:mb-0`;
export const ParamItemsText = `text-white font-light text-sm 3xl:text-base 4xl:text-lg 5xl:text-xl`;
export const ParamValueItemsText = `text-white truncate max-w-20 inline-block font-normal text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl`;
export const SgeText = `text-white font-light text-xxxs 3xl:text-xxs 4xl:text-xs 4xl:text-sm`;
export const ParamIcon = `w-3.5 3xl:w-4 4xl:w-5 mt-0.5`;
export const ParamIconBet = `w-3 3xl:w-4 4xl:w-5 mt-0.5`;
export const HouseIcon = `size-4 3xl:size-6 mr-2 3xl:mr-3`;
export const HouseButton = `w-full sm:w-80 tracking-wider rounded-md bg-brand-bg-1 px-4 py-3 text-black font-bold text-sm xxxs:text-base`;
export const MarketCardContainer = `border-b border-border-bg-1 bg-primary-bg-2 pb-4 mb-2 xl:flex xl:items-center`;
export const MarketHouseButton = `w-full relative overflow-hidden sm:w-80 tracking-wider rounded-md bg-transparent px-4 py-3 text-text-brand-1 font-medium text-sm xxxs:text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl border border-border-bg-2`;
export const BeHouseText = `text-white font-semibold text-xxs xxxs:text-xs 3xl:text-base 4xl:text-lg uppercase`;
export const ParamValueWrapper = `flex space-x-1 items-baseline`;
export const FavIconWrapper = `hidden lg:flex ml-2 self-center`;
export const FavouriteIcon = `size-5 3xl:size-6 6xl:size-9 ml-1`;
