import {
  InnerContainer,
  NavIcon,
  NavIconActive,
  NavItems,
  NavigationContainer,
} from './MobileNavigation.styled';
import { useMatches, RouteObject } from 'react-router-dom';
import { NAV_ITEMS } from './MobileNavigation.constants';
import NavLink from '../NavLink.component';

export type ExtendedRouteObject = RouteObject & {
  handle?: {
    navItem: string;
  };
};

export default function MobileNavigation() {
  const matches = useMatches() as ExtendedRouteObject[];

  // Extract the last matched route's handle.navItem
  const activeNavItem =
    matches
      .slice()
      .reverse()
      .find((match) => match.handle && match.handle.navItem)?.handle?.navItem ||
    '';

  return (
    <div className={NavigationContainer}>
      <div className={InnerContainer}>
        {NAV_ITEMS.map((item) => {
          const isActive = item.id === activeNavItem;

          return (
            <NavLink
              key={item.id}
              to={item.to}
              className={() => NavItems({ isActive })}
              dataId="mobile_navigation_links"
            >
              <span>
                <img
                  className={isActive ? NavIconActive : NavIcon}
                  src={isActive ? item.iconActive : item.icon}
                  alt={item.text}
                />
              </span>
              <span>{item.text}</span>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
}
