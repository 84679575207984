import { BUY_SELL_CRYPTO_ICON } from '#/constants/common/images.constants';
import env from '#/utils/env';
import * as kadoEvents from '#/utils/slices/kadoSlice';
import { useAppDispatch } from '#/utils/store';
import Button from './common/Buttons/Button/Button.component';

export const KadoContainer = `mb-4`;
export const KadoButton = `flex items-center space-x-4 px-6 lg:px-8 4xl:px-12 py-4 border border-border-bg-2 rounded-md justify-center`;
export const KadoText = `text-text-brand-2 tracking-wide text-base 3xl:text-xl 4xl:text-2xl 5xl:text-3xl font-normal`;

export type KadoCTAProps = {
  additionalContainerStyles? : string;
  additionalButtonStyles? : string;
  ctaType: 'sidemenu' | 'wallet_modal'
}

const KadoCTA = ({ 
  ctaType, 
  additionalButtonStyles, 
  additionalContainerStyles 
} : KadoCTAProps) : JSX.Element => {
  const dispatch = useAppDispatch()

  if(!env.kado.enabled) {
    return <></>
  }

  const containerStyles = additionalContainerStyles 
    ? `${KadoContainer} ${additionalContainerStyles}`
    : KadoContainer

  const buttonStyles = additionalButtonStyles 
    ? `${KadoButton} ${additionalButtonStyles}`
    : KadoButton

  return (
    <div className={containerStyles}>
      <Button
        className={buttonStyles}
        dataId={`open_kado_${ctaType}`}
        onClick={() => { dispatch(kadoEvents.setOpen(true)) }}
      >
        <span>
          <img
            src={BUY_SELL_CRYPTO_ICON}
            alt={'Buy/Sell Crypto Icon'}
          />
        </span>

        <span className={KadoText}>
          Buy/Sell Crypto
        </span>
      </Button>
    </div>
  )
}

export default KadoCTA
