import { DEFAULT_ODD_TYPE, MIN_BET_SLIP_AMOUNT, MIN_HOUSE_SLIP_AMOUNT } from '#/constants/common/global-constants';
import {
  FlexColContainer,
  OddEventsContainerMarket,
  OddNameMobile,
  OddsContainer,
} from './DashboardMarket.styled';
import OddButton from '../Buttons/OddButton/OddButton.component';
import { getDisplayOddValue } from '#/utils/appUtils';
import { getOutcomeName } from '#/utils/marketsMap';
import {
  ODDS_DOWN_ARROW,
  ODDS_UP_ARROW,
} from '#/constants/common/images.constants';
import { OddLabelButtonStyleMobile } from '../Buttons/OddButton/OddButton.styled';
import { FixtureSummary, MarketLiveData, Odds, OutcomeLiveData, outcomeId } from '#/utils/backend';
import { useAppSelector } from '#/utils/store';
import { isOddSelected } from '#/components/SlipsDrawers/SlipsDrawer.utils';
import { stringToBigdecimalOrZero } from '#/utils/bigdecimal';
import useBackendUserData from '#/utils/dataLoaders/useBackendUserData';

export type MatchCardOddsProp = {
  market: FixtureSummary;
  addBetSlip: (market: FixtureSummary, odd: Odds) => void;
};

export default function DashboardMarket({
  market,
  addBetSlip,
}: MatchCardOddsProp) {
  const outcomes = useAppSelector((state) => state.outcomes.outcomes);
  const markets = useAppSelector((state) => state.markets.markets);
  const betSlips = useAppSelector((state) => state.betSlips);
  const { data: user } = useBackendUserData();
  const marketData: MarketLiveData | undefined = markets[market.id];
  const marketStatus = marketData ? marketData.status : 'Aborted';
  const acceptableProvisionSize = stringToBigdecimalOrZero(marketData ? marketData.acceptable_provision_size : '0')
  const isMarketDisabled = marketStatus !== 'Operational' || acceptableProvisionSize.lowerThan(MIN_HOUSE_SLIP_AMOUNT);
  const oddType = user?.oddsFormat ?? DEFAULT_ODD_TYPE.key;

  return (
    <div className={FlexColContainer}>
      <div
        className={OddEventsContainerMarket({
          $isDisabled: isMarketDisabled,
        })}
      >
        {Object.keys(market.odds)
          .filter((_, index) => index < 3)
          .map((oddKey) => {
            const odd = market.odds[oddKey];
            const outcomeData: OutcomeLiveData | undefined = outcomes[outcomeId(odd)];

            const value = outcomeData?.value || '-';
            const movement = outcomeData?.movement || 'Down';
            const acceptableBetSize = stringToBigdecimalOrZero(outcomeData ? outcomeData.acceptable_bet_size : '0');

            const isSelected = isOddSelected(betSlips, odd);
            const isOddDisabled = acceptableBetSize.lowerThan(MIN_BET_SLIP_AMOUNT) || false;

            return (
              <OddButton
                key={odd.outcomePosition}
                value={getDisplayOddValue({
                  oddsType: String(oddType),
                  oddValues: { decimal: value, american: '', fractional: '' },
                })}
                extendedValue={getDisplayOddValue({
                  oddsType: String(oddType),
                  oddValues: {
                    decimal: value,
                    american: '',
                    fractional: '',
                  },
                  extended: true,
                })}
                onClick={() => addBetSlip(market, { ...odd, value })}
                oddsType={String(oddType)}
                name={
                  getOutcomeName(
                    odd.marketTypeId,
                    odd.outcomePosition,
                    market.teams.Home.Name,
                    market.teams.Away.Name,
                  ) || odd.outcomeName
                }
                oddContainerStyle={OddsContainer}
                oddLabelStyle={OddNameMobile}
                withLabel={true}
                OddValueStyledMobile={OddLabelButtonStyleMobile({ $isSelected: isSelected, $isDisabled: isOddDisabled })}
                icon={
                  movement
                    ? movement === 'Up'
                      ? ODDS_UP_ARROW
                      : ODDS_DOWN_ARROW
                    : ''
                }
              />
            );
          })}
      </div>
    </div>
  );
}
