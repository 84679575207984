import { FixtureSummary } from '#/utils/backend'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import * as localStorageUtils from '../localStorage';

export type State = {
  // Tutorial modal should be shown only if fixtureSummary is set
  fixtureSummary: FixtureSummary | undefined;
  // Ignore tutorials altogether if this flag is on false
  showHouseTutorialsOnAddHouseSlip: boolean;
}

const initialState: State = { 
  fixtureSummary: undefined,
  showHouseTutorialsOnAddHouseSlip: !localStorageUtils.getDoNotShowHouseTutorials()
};

const slice = createSlice({
  name: 'houseTutorial',
  initialState,
  reducers: {
    open: (state, fixtureSummary: PayloadAction<FixtureSummary>) => {
      state.fixtureSummary = fixtureSummary.payload
    },
    close: (state) => {
      state.fixtureSummary = undefined
    },
    complete: (state, doNotShowAgain : PayloadAction<boolean>) => {
      state.fixtureSummary = undefined
      state.showHouseTutorialsOnAddHouseSlip = false

      localStorageUtils.setDoNotShowHouseTutorials(doNotShowAgain.payload);
    },
  },
});

export const { open, close, complete } =
  slice.actions;

export default slice.reducer;
