interface FilterListItemProp {
  isActive: boolean;
}
export const ContentContainer = `w-full`;
export const Header = `w-full flex items-center mb-3 3xl:mb-5`;
export const PageTitle = `text-white font-normal text-sm xxxs:text-xl 2xl:text-2xl 3xl:text-2xl 4xl:text-3xl tracking-wider`;
export const HeaderIcon = `w-5 mr-4`;
export const TxAddress = `text-base lg:text-sm 3xl:text-lg 5xl:text-2xl 6xl:text-3xl text-white font-normal uppercase`;
export const LabelText = `text-sm 3xl:text-lg 5xl:text-2xl 6xl:text-3xl text-text-gray-5 font-light`;
export const ValueText = `text-sm 3xl:text-lg 5xl:text-2xl 6xl:text-3xl text-white font-normal`;
export const CardHeader = `flex justify-between items-center mb-2 4xl:mb-4`;
export const StatusBadge = `flex-none text-center uppercase text-sm text-text-gray-1 font-bold rounded-md px-4 py-1 border border-solid border-card-bg-2 min-w-28`;
export const DateText = `text-sm 3xl:text-lg 5xl:text-2xl 6xl:text-3xl text-white font-light text-right`;
export const CardContainer = `relative w-full mb-4 lg:mb-0 bg-primary-bg-2 rounded-md 4xl:rounded-lg  p-4 4xl:p-6`;
export const CardFooter = `flex items-center space-x-8 mt-3`;
export const FlexCenter = `flex items-center mt-3`;
export const CopyIcon = `w-4.5 4xl:w-6 ml-2`;
export const CheckInput = `size-4 4xl:size-5 mr-2 appearance-none rounded-sm  border border-text-gray-2 focus:outline-none align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer checked:bg-brand-bg-1 checked:bg-[url('/v2-icons/check-icon-dark.svg')] checked:border-brand-bg-1 checked:bg-[length:10px_10px]`;

export const HeaderWrapper = `flex justify-between items-center lg:hidden`;
export const Title = `text-base md:text-lg 3xl:text-xl 4xl:text-xl 5xl:text-2xl 6xl:text-3xl text-text-gray-1 font-light uppercase`;
export const HeadDivider = `h-0 border border-solid border-t-0 border-border-bg-1 mt-3 mb-4 lg:hidden`;
export const FilterListItemText = ({
  isActive,
}: FilterListItemProp) => `w-full text-sm lg:text-base 3xl:text-lg 4xl:text-xl 5xl:text-3xl 6xl:text-4xl text-text-gray-2 flex justify-between font-light
  ${isActive ? 'text-white' : ''}`;
export const MarketItemContainer = `pb-3 lg:py-2 4xl:py-4 lg:cursor-pointer flex items-center`;
export const MarketLabel = `text-sm 3xl:text-lg 4xl:text-xl 5xl:text-2xl 6xl:text-3xl text-white font-normal`;
export const FilterCardContainer = `block bg-transparent overflow-y-hidden`;
export const SuccessBadge = `flex-none block w-24 3xl:w-32 5xl:w-40 6xl:w-52 text-center uppercase text-sm 3xl:text-lg 4xl:text-xl 5xl:text-2xl 6xl:text-3xl text-text-success-1 font-bold rounded-md px-3 py-1 bg-success-bg-2 lg:bg-[#1d2f26]`;
export const FailedBadge = `flex-none block w-24 3xl:w-32 5xl:w-40 6xl:w-52 text-center uppercase text-sm 3xl:text-lg 4xl:text-xl 5xl:text-2xl 6xl:text-3xl text-text-danger-1 font-bold rounded-md px-3 py-1 bg-danger-bg-2 lg:bg-[#322423]`;

export const TableHead = `border-b border-border-bg-1 text-white text-sm 3xl:text-lg 4xl:text-xl 5xl:text-3xl 6xl:text-4xl font-light`;
export const FilterBtn = `flex-none text-center text-sm 3xl:text-lg 4xl:text-xl 5xl:text-2xl 6xl:text-3xl text-white font-normal rounded-md px-3 py-1 border border-white cursor-pointer relative`;

export const DropDownStyle = `bg-primary-bg-2 rounded-md py-1.5 px-4 absolute right-0 mt-4 h-auto z-[2] w-48 3xl:w-56 [box-shadow:0px_0px_15px_5px_#FFFFFF1A;]`;
export const DropDownInnerWrapper = `overflow-y-auto`;
export const TxAddressWrapper = `flex items-center justify-between max-w-72 3xl:max-w-[23rem] 5xl:max-w-[30rem] 6xl:max-w-[37rem]`;
export const FiltersWrapper = `hidden lg:flex w-[400px] justify-end space-x-4 3xl:space-x-5`;
export const TableColGap = `py-3.5 px-4 last:rounded-r-md first:rounded-l-md 3xl:last:rounded-r-lg 3xl:first:rounded-l-lg`;
export const PaginationContainer = `hidden lg:flex w-full mt-10 mb-14 relative`;
export const PaginationItemWrapper = `flex items-center`;


export const PageResultText = `text-sm 3xl:text-lg 4xl:text-xl 5xl:text-2xl text-white font-light absolute left-0 top-0 mt-2.5`;

export const PaginationItems = ({ isActive }: { isActive: boolean }) =>
  `size-9 4xl:size-12 text-base 3xl:text-xl 4xl:text-2xl 5xl:text-3xl 6xl:text-3xl text-white font-normal flex items-center justify-center mx-2 cursor-pointer  ${isActive ? '!text-black !font-bold rounded-md bg-brand-bg-1' : ''}`;

export const OrderIconWrapper = `bg-toast-bg-1 rounded-md p-4 flex items-center size-11`;
export const OrderIcon = `w-4`;
export const GridWrapper = `lg:grid lg:grid-cols-2 4xl:grid-cols-3 lg:gap-5 4xl:gap-6 5xl:gap-7 lg:items-baseline`;