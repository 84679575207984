/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  BeTheHouseButton,
  BtnWrapper,
  CardContainer,
  CardHeaderWrapper,
  CardInnerContainer,
  CardMainContainer,
  CardScrollContainer,
  CardSubTitle,
  CardTitle,
  HeadTitle,
  IconGraph,
  IconSize,
  IconWrapper,
  LostBadge,
  MarketPopupTitle,
  MarketText,
  OddValueContainer,
  WonBadge,
  HouseParamContainer,
  HouseParamItems,
  ParamIcon,
  ParamIconBet,
  ParamItemsText,
  ParamValueItemsText,
  ParamValueWrapper,
  SgeText,
} from './TrendingCard.styled';
import { formatDateToDesiredFormat } from '#/utils/datetime/datetime';
import { DATE_TIME_FORMATS } from '#/utils/datetime/datetime.constants';
import DashboardMarket from '#/components/common/MarketComponent/DashboardMarket.component';
import { getMarketNameById } from '#/utils/marketsMap';
import { useAppDispatch, useAppSelector } from '#/utils/store';
import { selectOrDeselect } from '#/utils/slices/betSlipsSlice';
import { fixtureSummaryToHouseSlip, selectOrDeselect as selectOrDeselectHouse } from '#/utils/slices/houseSlipsSlice';
import {
  FixtureSummary,
  MarketProvision,
  Odds,
  outcomeId,
} from '#/utils/backend';
import Link from '#/components/common/Link.component';
import {
  FlexContainer,
  MatchLeagueTime,
} from '../MatchDetails/MatchDetails.styled';
import Button from '#/components/common/Buttons/Button/Button.component';
import { useState } from 'react';
import CenterModal from '#/components/common/CenterModal.component';
import {
  BET_AMOUNT_ICON_BRAND,
  CLOSE_ICON,
  GRAPH_ICON_BRAND,
  LIQUIDITY_ICON_BRAND,
  NO_OF_BETS_ICON,
} from '#/constants/common/images.constants';
import { stringToBigdecimalOrZero } from '#/utils/bigdecimal';
import { MIN_BET_SLIP_AMOUNT } from '#/constants/common/global-constants';
import { toast } from 'react-toastify';
import ActionTag from '#/components/common/ActionTags.component';
import * as houseTutorialEvents from '#/utils/slices/houseTutorialSlice';

type TrendingCardProps = {
  icon: string;
  title: string;
  markets: FixtureSummary[] | undefined;
};

const MarketStatsModal = ({
  isOpen,
  onClose,
  marketProvision,
}: {
  isOpen: boolean;
  onClose: () => void;
  marketProvision: MarketProvision;
}) => {
  return (
    <CenterModal isOpen={isOpen} onClose={onClose}>
      <div className="relative p-5">
        <div className="flex justify-between items-center mb-4">
          <span className={MarketPopupTitle}>Market Stats</span>
          <Button onClick={onClose} dataId="close_market_stats">
            <img className="w-6" src={CLOSE_ICON} alt="Close Icon" />
          </Button>
        </div>
        <div>
          <div className={HouseParamContainer}>
            <div className={HouseParamItems}>
              <div className="flex items-center">
                <span className="mr-2">
                  <img className={ParamIconBet} src={NO_OF_BETS_ICON} alt="" />
                </span>
                <span className={ParamItemsText}>Number of Bets</span>
                <span className={`${ParamValueItemsText} ml-2`}>
                  {marketProvision.TotalBets}
                </span>
              </div>
            </div>

            <div className={HouseParamItems}>
              <div className="flex items-center">
                <span className="mr-2">
                  <img
                    className={ParamIcon}
                    src={BET_AMOUNT_ICON_BRAND}
                    alt=""
                  />
                </span>
                <span className={ParamItemsText}>Bet Amount</span>
                <div className={`${ParamValueWrapper} ml-2`}>
                  <span className={ParamValueItemsText}>
                    {marketProvision.TotalBetAmount}
                  </span>
                  <span className={SgeText}>SGE</span>
                </div>
              </div>
            </div>
            <div className={HouseParamItems}>
              <div className="flex items-center">
                <span className="mr-2">
                  <img
                    className={ParamIcon}
                    src={LIQUIDITY_ICON_BRAND}
                    alt=""
                  />
                </span>
                <span className={ParamItemsText}>Committed Liquidity</span>
                <div className={`${ParamValueWrapper} ml-2`}>
                  <span className={ParamValueItemsText}>
                    {marketProvision.TotalLiquidity}
                  </span>
                  <span className={SgeText}>SGE</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CenterModal>
  );
};

export default function TrendingList({
  icon,
  title,
  markets,
}: TrendingCardProps) {
  const dispatch = useAppDispatch();
  const rewardCalculation = useAppSelector((state) => state.rewardsCalculation);
  const showHouseTutorials = useAppSelector((state) => state.houseTutorial.showHouseTutorialsOnAddHouseSlip)


  function addBetSlip(market: FixtureSummary, odd: Odds) {
    if (stringToBigdecimalOrZero(odd.acceptableBetSize).lowerThan(MIN_BET_SLIP_AMOUNT)) {
      toast.info(` Not enough bet capacity available`, { autoClose: 1000 });
      return;
    }
    const slip = {
      fixture: {
        id: market.fixtureId,
      },
      market: {
        id: market.id,
        name: market.marketTypeName,
        marketTypeId: market.marketTypeId,
      },
      outcome: {
        id: outcomeId(odd),
        position: odd.outcomePosition,
        name: odd.outcomeName,
        acceptedRate: odd.value,
      },
      homeTeam: {
        name: market.teams.Home.Name,
      },
      awayTeam: {
        name: market.teams.Away.Name,
      },
      slippageParams: {
        slippage: '0',
        odds: odd.value,
      },
      league: {
        id: market.league.Id,
      },
      sport: {
        id: market.sport,
      },
    };
    dispatch(
      selectOrDeselect({
        slip,
        campaigns: rewardCalculation.campaignsApplicable,
        totalRewardsAvailable: rewardCalculation.totalRewardsAvailable,
      }),
    );
  }

  function addHouseSlip(fixtureSummary: FixtureSummary) {
    if(showHouseTutorials) {
      dispatch(
        houseTutorialEvents.open(fixtureSummary)
      )
    } else {
      dispatch(
        selectOrDeselectHouse(fixtureSummaryToHouseSlip(fixtureSummary))
      );   
    }
  }

  return (
    <div className={CardMainContainer}>
      <div className={CardHeaderWrapper}>
        <span className={IconWrapper}>
          <img className={IconSize} src={icon} alt="" />
        </span>
        <h2 className={HeadTitle}>{title}</h2>
      </div>
      <div id="CardScroll" className={CardScrollContainer}>
        {markets?.map((market) => (
          <TrendingCard
            market={market}
            key={market.id}
            addBetSlip={addBetSlip}
            addHouseSlip={addHouseSlip}
          />
        ))}
      </div>
    </div>
  );
}

export function TrendingCard({
  market,
  addBetSlip,
  addHouseSlip,
}: {
  market: FixtureSummary;
  addBetSlip: (market: FixtureSummary, odd: Odds) => void;
  addHouseSlip: (market: FixtureSummary) => void;
}) {
  const [showMarketDetails, setShowMarketDetails] = useState(false);
  return (
    <div className={CardContainer}>
      <div
        className={CardInnerContainer({
          $isDisabled: market.fixtureStatus !== 'NotStarted',
        })}
      >
        <div className="flex justify-between w-full">
          <Link
            to={`/match-detail/${market.fixtureId}`}
            state={{ navigated: true }}
            prefetch="intent"
            dataId="redirect_to_match_details"
          >
            <div
              className={CardTitle}
            >{`${market.teams.Home.Name} vs ${market.teams.Away.Name}`}</div>
          </Link>
          {market.fixtureStatus !== 'NotStarted' ? (
            <div
              className={
                market.fixtureStatus === 'Cancelled' ? LostBadge : WonBadge
              }
            >
              {market.fixtureStatus.toUpperCase()}
            </div>
          ) : null}
        </div>
        <div className={CardSubTitle}>
          {market.fixtureName ? (
            <div className={MatchLeagueTime({ isMainMarketCard: false })}>
              {formatDateToDesiredFormat(
                market.startDate,
                DATE_TIME_FORMATS.DATE_TIME_2,
              )}
              &nbsp; | &nbsp;
            </div>
          ) : null}

          <div className={FlexContainer}>
            <div className={MatchLeagueTime({ isMainMarketCard: false })}>
              {`${market.league.Name}`}
            </div>
          </div>
        </div>
        <div className={CardTitle}>
          {getMarketNameById(market.marketTypeId) || market.marketTypeName}
        </div>
        <div className={OddValueContainer}>
          <DashboardMarket
            market={market}
            addBetSlip={addBetSlip}
          />
        </div>
        <div className={BtnWrapper}>
          <Button
            className={`${BeTheHouseButton} ripple`}
            onClick={() => addHouseSlip(market)}
            dataId="add_house"
          >
            BE THE HOUSE
          </Button>
          <ActionTag
            type="span"
            dataId="view_market_stats"
            className={MarketText}
            onClick={() => setShowMarketDetails(true)}
          >
            <img className={IconGraph} src={GRAPH_ICON_BRAND} alt="" /> Market
            Stats
          </ActionTag>
        </div>
        {showMarketDetails && (
          <MarketStatsModal
            isOpen={showMarketDetails}
            onClose={() => setShowMarketDetails(false)}
            marketProvision={market.houseProvision}
          />
        )}
      </div>
    </div>
  );
}
