import {
  BETS_ICON,
  BETS_ICON_ACTIVE,
  EXPLORE_ICON,
  EXPLORE_ICON_BRAND,
  HOME_ICON,
  HOME_ICON_BRAND,
  NOTIFICATION_ICON,
  NOTIFICATION_ICON_BRAND,
} from '#/constants/common/images.constants';

export const HOME_TEXT = 'Home';
export const EXPLORE_TEXT = 'Explore';
export const MYBETS_TEXT = 'My Bets';
export const NOTIFICATIONS_TEXT = 'Notifications';
export const IMG_ALT_TEXT = 'Icon';

type NavItem = {
  id: string;
  to: string;
  iconActive: string;
  icon: string;
  text: string;
};

export const NAV_ITEMS: NavItem[] = [
  {
    id: 'home',
    to: '/soccer',
    icon: HOME_ICON,
    iconActive: HOME_ICON_BRAND,
    text: HOME_TEXT,
  },
  {
    id: 'explore',
    to: '/explore',
    icon: EXPLORE_ICON,
    iconActive: EXPLORE_ICON_BRAND,
    text: EXPLORE_TEXT,
  },
  {
    id: 'mybets',
    to: '/my-bets',
    icon: BETS_ICON,
    iconActive: BETS_ICON_ACTIVE,
    text: MYBETS_TEXT,
  },
  {
    id: 'notifications',
    to: '/notifications',
    icon: NOTIFICATION_ICON,
    iconActive: NOTIFICATION_ICON_BRAND,
    text: NOTIFICATIONS_TEXT,
  },
];
