import type { ReactNode } from 'react';

interface LinkPropTypes {
  children: ReactNode;
  dataId?: string;
  className?: string;
  href?: string;
  target?: string;
  rel?: string;
}

export default function RedirectLink({
  children,
  dataId,
  className = '',
  href,
  target = '',
  rel = '',
}: LinkPropTypes) {
  return (
    <a
      className={className}
      href={href}
      target={target}
      rel={rel}
      id="redirect-link"
      data-testid={dataId}
    >
      {children}
    </a>
  );
}
