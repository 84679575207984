import { useEffect, useState } from 'react';

import { useAppDispatch } from '#/utils/store';
import {
  setWalletData,
  setWalletMessage,
  setWalletState,
} from '#/utils/slices/cosmosKitManagerSlice';
import useCosmosKitConnect from './useCosmosKitConnect';
import { walletManager, walletRepo } from '#/utils/cosmosKitManager';

const CurrentWalletKey = 'cosmos-kit@2:core//current-wallet';
const AccountsKey = 'cosmos-kit@2:core//accounts';

const useCosmosKitManager = () => {
  const dispatch = useAppDispatch();
  const { connect } = useCosmosKitConnect();
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    if (walletManager && walletRepo) {
      walletRepo.wallets.forEach((wallet) => {
        wallet.setActions({
          render: setRender,
          // TODO: Unexpected data types might creep in through here. It would be ideal to parse it
          data: (data) =>
            dispatch(setWalletData({ walletName: wallet.walletName, data })),
          message: (message) =>
            dispatch(
              setWalletMessage({ walletName: wallet.walletName, message }),
            ),
          state: (state) =>
            dispatch(setWalletState({ walletName: wallet.walletName, state })),
        });
      });
    }
  }, [walletManager, setRender]);

  useEffect(() => {
    if (walletManager) {
      walletManager.onMounted();

      return () => {
        walletManager && walletManager.onUnmounted();
      };
    }
  }, [walletManager, render]);

  useEffect(() => {
    const currentWalletName = localStorage.getItem(CurrentWalletKey);
    const accountsFromStorage = localStorage.getItem(AccountsKey);

    const accounts = accountsFromStorage
      ? (JSON.parse(accountsFromStorage) ?? [])
      : [];

    if (walletRepo && accounts.length > 0) {
      const foundWallet = walletRepo.wallets.find(
        (wallet_) => wallet_.walletName === currentWalletName,
      );

      if (foundWallet) {
        connect(foundWallet);
      }
    }
  }, [walletRepo]);
};

export default useCosmosKitManager;
