import Button from '#/components/common/Buttons/Button/Button.component';
import { ARROW_BACK } from '#/constants/common/images.constants';
import { useState } from 'react';
import {
  BackBtn,
  BackBtnIcon,
  BgContainer,
  BgInnerContainer,
  ButtonNext,
  ButtonWhite,
  ButtonWrapper,
  ModalContentWrapper,
  NavDotsActive,
  NavDotsInactive,
  NavDotsWrapper,
  StepContents,
  HouseContentText,
  HeadTitle,
  CheckInput,
  CheckLabel,
} from './HouseTutorialsPopup.styled';
import CenterModal from '#/components/common/CenterModal.component';
import { gtmEvent } from '#/utils/appUtils';
import RedirectLink from '#/components/common/RedirectLink.component';

const HouseTutorials = ({
  onClose,
  onProceed,
}: {
  onClose: () => void;
  onProceed: (doNotShowAgain: boolean) => void;
}) => {
  const [setCurrentStep, setShowCurrentStep] = useState(0);

  const handleButtonNext = () => {
    setShowCurrentStep(setCurrentStep + 1);
  };

  const handleButtonBack = () => {
    setShowCurrentStep(setCurrentStep - 1);
  };
  const navigationDots = () => {
    const numbers = [0, 1, 2, 3];
    return numbers.map((number) => (
      <span
        key={number}
        className={setCurrentStep === number ? NavDotsActive : NavDotsInactive}
      />
    ));
  };

  const [doNotShowAgain, setDoNotShowAgain] = useState(false)

  return (
    <CenterModal isOpen={true} onClose={onClose}>
      <div className={BgContainer}>
        <div className={BgInnerContainer}>
        </div>
      </div>
      <div className={ModalContentWrapper}>
        <div
          className={StepContents}
          style={{ display: setCurrentStep === 0 ? 'block' : 'none' }}
        >
          <h2 className={HeadTitle}>House Participation</h2>
          <p className={HouseContentText}>
            Participating as the House is NEITHER risk-free NOR passive. You are providing liquidity on individual bets based on your event selection. Your betting P&L will be the cumulative sum of your individual bet outcomes.
          </p>
          <div className={ButtonWrapper}>
            <span />
            <Button
              className={`${ButtonWhite} ${ButtonNext}`}
              onClick={handleButtonNext}
              dataId="house_info_next"
            >
              Next
            </Button>
          </div>
        </div>
        <div
          className={StepContents}
          style={{ display: setCurrentStep === 1 ? 'block' : 'none' }}
        >
          <h2 className={HeadTitle}>Managing Risk</h2>
          <p className={HouseContentText}>
            Although it is possible to arrive at a &quot;risk-free&quot; positive result, it is unlikely to be realised by the sports book in any single event given the realities of uneven bet flow and changing odds. Chances are pre-match betting will close with the sports book and some of all House participants having directional risk.
          </p>
          <div className={ButtonWrapper}>
            <Button className={BackBtn} onClick={handleButtonBack} dataId="house_info_back">
              <span className="flex items-center">
                <img
                  className={BackBtnIcon}
                  src={ARROW_BACK}
                  alt="Arrow icon"
                />{' '}
                Back
              </span>
            </Button>
            <Button
              className={`${ButtonWhite} ${ButtonNext}`}
              onClick={handleButtonNext}
              dataId="house_info_next"
            >
              Next
            </Button>
          </div>
        </div>
        <div
          className={StepContents}
          style={{ display: setCurrentStep === 2 ? 'block' : 'none' }}
        >
          <h2 className={HeadTitle}>Responsible Betting</h2>
          <p className={HouseContentText}>
            It is your responsibility to monitor odds changes and any other factors that may affect the result of a sporting event. You can withdraw UNUSED liquidity at any time.<br />
            Always bet responsibly. Please take note of tools available to manage your activity here.
          </p>
          <div className={ButtonWrapper}>
            <Button className={BackBtn} onClick={handleButtonBack} dataId="house_info_back">
              <span className="flex items-center">
                <img
                  className={BackBtnIcon}
                  src={ARROW_BACK}
                  alt="Arrow icon"
                />{' '}
                Back
              </span>
            </Button>
            <Button
              className={`${ButtonWhite} ${ButtonNext}`}
              onClick={handleButtonNext}
              dataId="house_info_next"
            >
              Next
            </Button>
          </div>
        </div>
        <div
          className={StepContents}
          style={{ display: setCurrentStep === 3 ? 'block' : 'none' }}
        >
          <h2 className={HeadTitle}>Review the House Rules</h2>
          <p className={HouseContentText}>
            Before proceeding, make sure you have read the explanation of the House functionality available <RedirectLink href="https://support.sixsigmasports.app/hc/en-us/articles/4521820232991--Be-the-House-Explained" className='text-text-brand-1 font-bold cursor-pointer' target="_blank" dataId="house_tutorials_link_to_explanation">here</RedirectLink>.
          </p>
          <div className='flex items-center'>
            <input onClick={() => { setDoNotShowAgain((value) => !value); gtmEvent({ action: !doNotShowAgain ? "house_rules_review_checked": "house_rules_review_unchecked"}) }} className={CheckInput} type="checkbox" id="dont-show" />
            <label className={CheckLabel} htmlFor="dont-show">
              Don’t show me this again
            </label>
          </div>
          <div className={ButtonWrapper}>
            <Button className={BackBtn} onClick={handleButtonBack} dataId="house_info_back">
              <span className="flex items-center">
                <img
                  className={BackBtnIcon}
                  src={ARROW_BACK}
                  alt="Arrow icon"
                />{' '}
                Back
              </span>
            </Button>
            <Button
              className={`${ButtonWhite} ${ButtonNext}`}
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => onProceed(doNotShowAgain)}
              dataId="house_info_end"
            >
              Proceed
            </Button>
          </div>
        </div>
        <div className={NavDotsWrapper}>{navigationDots()}</div>
      </div>
    </CenterModal>
  );
};

export default HouseTutorials;
