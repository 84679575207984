import {
  LoaderBg,
  LoaderBtn,
  LoaderCardContainer,
  LoaderSeparator,
} from '#/components/common/LoaderStyle/LoaderStyle.styled';

export default function MatchShimmer() {
  return (
    <>
      <div className="px-0 lg:hidden">
        <div className='w-full'>
          <div className={`${LoaderBg} h-6 w-3/4 mb-3`}></div>
          <div className={`${LoaderBg} h-5 w-2/3 mb-4`}></div>
          <div className="flex mb-4 space-x-2">
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-1/3 mb-3`}></div>
        </div>
        <div className={LoaderSeparator} />
      </div>
      <div className="px-0 hidden lg:block">
        <div className='w-full flex justify-between'>
          <div className='flex-1'>
            <div className={`${LoaderBg} h-6 w-3/4 mb-3`}></div>
            <div className={`${LoaderBg} h-5 w-2/3 mb-5`}></div>
            <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
          </div>
          <div className="flex flex-1 items-center mb-3 space-x-4">
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} ${LoaderBtn}`}></div>
            <div className={`${LoaderBg} size-5 flex-none`}></div>
          </div>
        </div>
        <div className={LoaderSeparator} />
      </div></>
  );
}
