/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  AvatarImg,
  CloseIcon,
  FlexWrapper,
  MenuContainer,
  MenuDivider,
  MenuFooter,
  MenuHeader,
  MenuIcon,
  MenuIconActive,
  MenuItems,
  MenuItemsDesktopOnly,
  MenuItemsLogout,
  MenuItemsName,
  MenuItemsUserName,
  MenuList,
  SgeLogo,
  UserName,
} from './SideMenu.styled';
import { SideMenuProps } from './SideMenu.types';
import NavLink from '#/components/common/NavLink.component';
import ActionTag from '#/components/common/ActionTags.component';
import { CLOSE_ICON, LOG_OUT_ICON, BUY_SELL_CRYPTO_ICON } from '#/constants/common/images.constants';
import Button from '#/components/common/Buttons/Button/Button.component';
import Link from '#/components/common/Link.component';
import useCosmosKitWallet from '#/hooks/useCosmosKitWallet';
import useAuth0UserData from '#/utils/dataLoaders/useAuth0UserData';
import * as kadoEvents from '#/utils/slices/kadoSlice';
import { useMatches } from 'react-router-dom';
import { ExtendedRouteObject } from '#/components/common/MobileNavigation/MobileNavigation.component';
import env from '#/utils/env';
import { useAppDispatch } from '#/utils/store';
import KadoCTA from '#/components/KadoCTA.component';

const SideMenu: React.FC<SideMenuProps> = ({
  close,
  menuItems,
  logo,
  isAuthenticated,
  logout,
}) => {
  const { disconnect } = useCosmosKitWallet();
  const { data: auth0User } = useAuth0UserData();
  const handleNavLinkClick = () => {
    close ? close() : null;
  };

  const matches = useMatches() as ExtendedRouteObject[];
  const dispatch = useAppDispatch()

  // Extract the last matched route's handle.navItem
  const activeNavItem =
    matches
      .slice()
      .reverse()
      .find((match) => match.handle && match.handle.navItem)?.handle?.navItem ||
    '';

  return (
    <div className={MenuContainer}>
      <div>
        <div className={MenuHeader}>
          <span>
            <Link to="/" dataId="side_menu_logo">
              <img className={SgeLogo} src={logo.src} alt={logo.alt} />
            </Link>
          </span>
          {close ? (
            <ActionTag type="span" onClick={close} dataId="close_side_menu">
              <img className={CloseIcon} src={CLOSE_ICON} alt="Close Icon" />
            </ActionTag>
          ) : null}
        </div>
        <div className="px-6 lg:px-8">
          <div className={MenuDivider} />
        </div>
        <div>
          <ul className={MenuList}>
            {menuItems.map((item, index) => {
              const isActive = item.id === activeNavItem;

              return (
                <li key={index}>
                  <NavLink
                    className={
                      item.desktopOnly
                        ? MenuItemsDesktopOnly({
                            isActive,
                          })
                        : MenuItems({
                            isActive,
                          })
                    }
                    to={item.to}
                    prefetch="intent"
                    onClick={
                      item.onClick
                        ? () => {
                            item.onClick && item.onClick();
                            handleNavLinkClick();
                          }
                        : handleNavLinkClick
                    }
                    dataId="side_menu_links"
                  >
                    <span>
                      {isActive ? (
                        <img
                          className={MenuIconActive}
                          src={item.iconActive}
                          alt={item.alt}
                        />
                      ) : (
                        <img
                          className={MenuIcon}
                          src={item.icon}
                          alt={item.alt}
                        />
                      )}
                    </span>
                    <span
                      className={MenuItemsName({
                        isActive,
                      })}
                    >
                      {item.text}
                    </span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className="mt-12">
        <KadoCTA ctaType='sidemenu' additionalContainerStyles='mx-6' />

        {isAuthenticated ? (
          <Button
            className={MenuItemsLogout}
            onClick={() => {
              disconnect();
              logout();
            }}
            dataId="sidemenu_logout"
          >
            <span>
              <img
                className={MenuIcon}
                src={LOG_OUT_ICON}
                alt={'Log out icon'}
              />
            </span>
            <span className={MenuItemsUserName}>Log Out</span>
          </Button>
        ) : null}

        {auth0User && (
          <>
            <div className="px-6 lg:px-8">
              <div className={`${MenuDivider} !mt-4`} />
            </div>

            <div className={MenuFooter}>
              <div className={FlexWrapper}>
                <span>
                  <img
                    className={AvatarImg}
                    src={auth0User.picture || ''}
                    alt={`${auth0User.name}'s avatar`}
                  />
                </span>
                <div>
                  <div className={MenuItemsUserName}>{auth0User.nickname}</div>
                  <div className={UserName}>{auth0User.name}</div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SideMenu;
