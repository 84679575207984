import {
  setIsConnecting,
  setIsDisconnecting,
  setWallet,
} from '#/utils/slices/cosmosKitManagerSlice';
import { useAppDispatch, useAppSelector } from '#/utils/store';
import { ChainWalletBase, WalletRepo } from '@cosmos-kit/core';
import { useCallback, useMemo } from 'react';
import { walletRepo } from '#/utils/cosmosKitManager';
import { setIsModalOpen } from '#/utils/slices/walletConnectionSlice';

const connect = async (walletRepo: WalletRepo, wallet: ChainWalletBase) => {
  return walletRepo.connect(wallet.walletName, false);
};

const disconnect = async (walletRepo: WalletRepo, wallet: ChainWalletBase) => {
  return walletRepo.disconnect(wallet.walletName, false);
};

const useCosmosKitConnect = () => {
  const dispatch = useAppDispatch();
  const walletName = useAppSelector(
    (state) => state.cosmosKitManager.walletName,
  );
  const isConnectingData = useAppSelector(
    (state) => state.cosmosKitManager.isConnecting,
  );
  const isDisconnectingData = useAppSelector(
    (state) => state.cosmosKitManager.isDisconnecting,
  );

  const isConnecting = walletName
    ? isConnectingData[walletName] || false
    : false;

  const isDisconnecting = walletName
    ? isDisconnectingData[walletName] || false
    : false;

  const connectHandler = useCallback(
    async (wallet: ChainWalletBase) => {
      if (walletRepo && !walletName && !isConnecting) {
        dispatch(
          setIsConnecting({
            walletName: wallet.walletName,
            isConnecting: true,
          }),
        );

        await connect(walletRepo, wallet)
          .then(() => {
            dispatch(setIsModalOpen(false));
            dispatch(setWallet(wallet.walletName));
          })
          .finally(() => {
            dispatch(
              setIsConnecting({
                walletName: wallet.walletName,
                isConnecting: false,
              }),
            );
          });
      }
    },
    [walletRepo, walletName, isConnecting],
  );

  const wallet = useMemo(() => {
    if (walletRepo && walletName) {
      return walletRepo.wallets.find((w) => w.walletName === walletName);
    }
  }, [walletName]);

  const disconnectHandler = useCallback(async () => {
    if (walletRepo && wallet && walletName && !isDisconnecting) {
      dispatch(
        setIsDisconnecting({ walletName: walletName, isDisconnecting: true }),
      );

      return disconnect(walletRepo, wallet)
        .then(() => {
          dispatch(setWallet(undefined));
        })
        .finally(() => {
          dispatch(
            setIsDisconnecting({
              walletName: wallet.walletName,
              isDisconnecting: false,
            }),
          );
        });
    }
  }, [walletRepo, wallet, walletName, isDisconnecting]);

  return {
    isConnecting,
    isDisconnecting,
    connect: connectHandler,
    disconnect: disconnectHandler,
    wallet,
  };
};

export default useCosmosKitConnect;
