import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ActivityTab from '#/components/common/ActivityTab/ActivityTab.component';
import FilterButton from '#/components/common/FilterButton.component';
import Search from '#/components/common/Search/Search.component';
import BetsFilters from '#/components/Filters/BetsFilters.component'; import { useAppDispatch } from '#/utils/store';
import {
  clearActivityFilters,
  setOutcomeFilters,
  setSearch,
  toggleOutcomeFilterAndSynchUrl,
} from '#/utils/slices/activityFiltersSlice';
import {
  ActivityOutcome,
} from '#/utils/backend';
import { SubTabs } from '../common/ActivityTab/SubTab.component';
import { Helmet } from 'react-helmet-async';
import EmptyList from '../common/EmptyStates/EmptyState.component';
import useBackendUserData from '#/utils/dataLoaders/useBackendUserData';
import BetActivityCards from '../BetActivityCards/BetActivityCards.component';
import { useDebounce } from '#/hooks/useDebounce';
import HouseActivityCards from '../HouseActivityCards/HouseActivityCards.component';
import { isProductionEnv } from '#/utils/env';

export type Tab = 'betActivity' | 'houseActivity';
export type SubItem = 'active' | 'settled';
export const BET_ACTIVITY = 'betActivity';
export const HOUSE_ACTIVITY = 'houseActivity';

export const OUTCOME_OPTIONS: Record<Tab, Record<SubItem, ActivityOutcome[]>> = {
  betActivity: {
    active: ['active', 'submitted'],
    settled: ['won', 'lost', 'failed', 'refunded'],
  },
  houseActivity: {
    active: ['Active'],
    settled: ['Settled'],
  },
};

export default function MyBets(): JSX.Element {
  const [showFilters, setShowFilters] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: user } = useBackendUserData();
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState('');
  const debouncedSearchInput = useDebounce(searchText, 300);
  const prevSearchText = useRef(searchText); // Track previous search text
  const isAuthenticated = !!user;

  const setSearchInput = useCallback(
    (input: string) => {
      dispatch(setSearch(input));
    },
    [dispatch]
  );

  const activeTab = (searchParams.get('tab') || BET_ACTIVITY) as Tab;
  const activeSubItem = (searchParams.get('subItem') || 'active') as SubItem;

  useEffect(() => {
    setSearchInput('');
  }, [activeTab, setSearchInput]);

  useEffect(() => {
    // Only call setSearchInput if searchText has changed since the last render
    if (debouncedSearchInput !== prevSearchText.current) {
      setSearchInput(debouncedSearchInput);
      prevSearchText.current = debouncedSearchInput; // Update the previous value
    }
  }, [debouncedSearchInput, setSearchInput]);

  useEffect(() => {
    resetAndApplyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubItem, activeTab]);

  const resetAndApplyFilters = () => {
    dispatch(clearActivityFilters({ searchParams, setSearchParams }));

    const outcomeFilters = OUTCOME_OPTIONS[activeTab][activeSubItem];
    if (outcomeFilters) {
      applyOutcomeFilters(outcomeFilters);
    }
  };

  const applyOutcomeFilters = (outcomeFilters: ActivityOutcome[]) => {
    dispatch(setOutcomeFilters(outcomeFilters));
    dispatch(
      toggleOutcomeFilterAndSynchUrl({
        outcomeFilters,
        searchParams,
        setSearchParams,
      })
    );
  };

  return (
    <div className="px-4">
      <Helmet>
        <title>{`My Bets | SixSigmaSports`}</title>
        {!isProductionEnv() ? <meta name="robots" content="noindex" /> : null}
      </Helmet>
      <div className="mt-4 xl:mt-0">
        <ActivityTab />
      </div>
      <div className="flex items-center">
        <Search
          search={searchText}
          setSearch={setSearchText}
          showSearchResults={true}
        />
        <FilterButton onClick={() => setShowFilters(!showFilters)} />
      </div>
      <div className="mt-4">
        <SubTabs activeTab={activeTab} />
      </div>
      <div className="space-y-4 mt-4">
        {isAuthenticated ? (
          activeTab === BET_ACTIVITY ? (
            <BetActivityCards />
          ) : (
            <HouseActivityCards />
          )
        ) : (
          <EmptyList id={activeTab === BET_ACTIVITY ? 'betsLoggedOut' : 'houseDepositsLoggedOut'} mini />
        )}
      </div>
      {showFilters && (
        <BetsFilters
          isOpen={showFilters}
          onClose={() => setShowFilters(false)}
          activeSubItem={activeSubItem}
        />
      )}
    </div>
  );
}
