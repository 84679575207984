import { COPY_ICON } from '#/constants/common/images.constants';
import {
  AddressText,
  ContentContainer,
  ContentText,
  ContentWrapper,
  CopyIcon,
  FlexWrapper,
  Header,
  PageTitle,
  QRImgWrapper,
} from './Deposit.styled';
import Button from '#/components/common/Buttons/Button/Button.component';
import RedirectLink from '../common/RedirectLink.component';
import { QRCodeSVG } from 'qrcode.react';

export default function Deposit({
  address,
  copyAddress,
}: {
  address: string;
  copyAddress: (item: string) => void;
}) {
  return (
    <>
      <div className={Header}>
        <h2 className={PageTitle}>Deposit</h2>
      </div>
      <div className={ContentContainer}>
        <h3 className={ContentText}>
          Scan the QR or your public address to receive funds.
        </h3>
        <div className={ContentWrapper}>
          <div className={QRImgWrapper}>
            <div className="p-3 bg-white QrSize">
              <QRCodeSVG value={address} />
            </div>
          </div>
          <div className={FlexWrapper}>
            <RedirectLink
              className={AddressText}
              href=""
              target="_blank"
              rel="noreferrer"
            >
              <span className='lg:hidden'>
                {`${address.slice(0, 10)}....${address.slice(
                  address.length - 5,
                  address.length,
                )}`}
              </span>
              <span className='hidden lg:block'>{address}</span>
            </RedirectLink>
            <Button onClick={() => copyAddress(address)} dataId="copy_wallet_address">
              <img className={CopyIcon} src={COPY_ICON} alt="Copy icon" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
