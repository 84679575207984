import { useMemo } from 'react';
import env from '#/utils/env';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import useCosmosKitWallet from '#/hooks/useCosmosKitWallet';

/**
 * Hook for querying a smart contract using react-query.
 * @param queryMsg The query message to send to the contract
 * @param enabled Whether the query should be automatically enabled
 * @returns An object containing the query result, loading, and error status
 */
export function useQuerySmartContract<TData>(
  queryMsg: Record<string, unknown>,
  enabled = true, // Add a default enabled flag
): UseQueryResult<TData> {
  const contractAddress = env.contract;
  const { cosmWasmClient } = useCosmosKitWallet();

  const memoizedQueryMsg = useMemo(() => queryMsg, [JSON.stringify(queryMsg)]);

  const fetchSmartContractData = async (): Promise<TData> => {
    if (!cosmWasmClient) {
      throw new Error('CosmWasm client not available');
    }

    const response = await cosmWasmClient.queryContractSmart(
      contractAddress,
      memoizedQueryMsg,
    );

    return response as TData;
  };

  return useQuery<TData>({
    queryKey: ['smartContractQuery', memoizedQueryMsg],
    queryFn: fetchSmartContractData,
    enabled: cosmWasmClient != null && enabled,
  });
}
