import { Tendermint37Client } from '@cosmjs/tendermint-rpc';
import { useQuery } from '@tanstack/react-query';
import env from '../env';
import { ChainWalletBase } from '@cosmos-kit/core';

const useTendermintClient = ({
  wallet,
}: {
  wallet: ChainWalletBase | undefined;
}) => {
  return useQuery({
    queryKey: ['tendermintClient', wallet?.walletName],
    queryFn: async () => {
      if (wallet) {
        return Tendermint37Client.connect(env.rpc);
      }
    },
    enabled: !!wallet,
  });
};

export default useTendermintClient;
