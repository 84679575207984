import { useInfiniteQuery } from '@tanstack/react-query';
import { FixtureSummary, loadFixtureSearchData } from '../backend';
import { QueryParams } from '../slices/searchSlice';

export interface SearchResult {
  rows: FixtureSummary[];
  total: number;
  nextOffset: number | null;
}

export function useInfiniteSearchLoader({
  query,
  pageSize = 50,
  refetchInterval = null,
  enabled = true,
}: {
  query: QueryParams;
  pageSize?: number;
  refetchInterval?: number | null;
  enabled?: boolean;
}) {
  return useInfiniteQuery({
    queryKey: [
      'searchInfinite',
      query.search,
      query.filters,
      query.sport,
      query.startsAfter,
      query.startsBefore,
      query.favouritesOf,
      pageSize,
    ],
    queryFn: async ({ pageParam = 0 }) => {
      return loadFixtureSearchData(query, pageParam, pageSize)
    },
    getNextPageParam: (lastPage, allPages) => {
      const totalLoaded = allPages.reduce((acc, page) => acc + page.fixtures.length, 0);
      if (totalLoaded < lastPage.total) {
        return totalLoaded;
      } else {
        return undefined;
      }
    },
    refetchInterval: refetchInterval ?? false,
    enabled: enabled && !!query, // Enabled only when the query is available
    initialPageParam: 0,
    staleTime: 0,
  });
}
