import { useMutation } from '@tanstack/react-query';
import { postUserSettings, UserSettings } from '../backend'; // This function should make the POST request
import useAccessToken from './useAccessToken';

const usePostSettingsDataLoader = () => {
  const { data: accessToken } = useAccessToken();

  const mutation = useMutation({
    mutationFn: async (settingsData: UserSettings) => {
      if (!accessToken) {
        throw new Error('Access token is required');
      }

      return postUserSettings(settingsData, accessToken);
    },
  });

  return {
    ...mutation,
    isLoading: mutation.isPending,
  };
};

export default usePostSettingsDataLoader;
