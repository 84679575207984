import {
  PaginationContainer,
  PaginationItemWrapper,
  PageResultText,
  PaginationItems,
} from './Transactions.styled';

export default function Pagination() {
  return (
    <div className={PaginationContainer}>
      <div>
        <span className={PageResultText}>Showing 10 out of 15 results</span>
      </div>
      <div className='flex justify-center w-full'>
        <ul className={PaginationItemWrapper}>
          <li>
            <span className={PaginationItems({
              isActive: true
            })}>1</span>
          </li>
          <li>
            <span className={PaginationItems({
              isActive: false
            })}>2</span>
          </li>
          <li>
            <span className={PaginationItems({
              isActive: false
            })}>3</span>
          </li>
          <li>
            <span className={PaginationItems({
              isActive: false
            })}>4</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
