import { useQuery } from '@tanstack/react-query';
import useAccessToken from './useAccessToken';
import useAuth from './useAuth';
import { loadAuth0UserData } from '../backend';
import { MINUTE } from '../time';

const useAuth0UserData = () => {
  // We cannot use the data returned from useAuth directly 
  // because it returns email_verified = false
  // Looks like a bug on the Auth0 side
  const { data: user } = useAuth();
  const { data: accessToken } = useAccessToken();

  return useQuery({
    queryKey: ['auth0UserData', user?.sub],
    queryFn: async () => {
      if (accessToken && user?.sub) {
        return loadAuth0UserData(accessToken);
      }
    },
    retry: 3,
    refetchInterval: MINUTE,
    refetchOnWindowFocus: true,
    refetchOnMount: false,
    enabled: !!user?.sub && !!accessToken,
  });
};

export default useAuth0UserData;
