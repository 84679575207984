import { ContentContainer, GridWrapper, OrderIcon, OrderIconWrapper, TableColGap, TableHead } from './Transactions.styled';
import { Transaction, TransactionsData } from '#/utils/backend';
import TransactionCard from './TransactionCard.component';
import {
  LoaderBg,
  LoaderCardContainer,
  LoaderSeparator,
} from '../common/LoaderStyle/LoaderStyle.styled';
import EmptyList from '../common/EmptyStates/EmptyState.component';
import { useSearchParams } from 'react-router-dom';
import WithHandledStates, { DataState } from '../WithHandledStates.component';
import Pagination from './Pagination';
import { ORDER_ICON_BRAND } from '#/constants/common/images.constants';

interface TransactionsWithDataProps {
  data: Transaction[];
}

function TransactionsWithData({ data }: TransactionsWithDataProps) {
  const [searchParams] = useSearchParams();

  const statusFilters: string[] = searchParams.get('statusFilter')?.split(',') || [];
  const typeFilters: string[] = searchParams.get('typeFilter')?.split(',') || [];

  const filteredDataByStatus = data?.filter((transaction: Transaction) => {
    if (!statusFilters.length) return true;
    return statusFilters.includes(transaction.status.toLowerCase());
  });

  const filteredDataByType = filteredDataByStatus?.filter((transaction: Transaction) => {
    if (!typeFilters.length) return true;
    return typeFilters.includes(transaction.filterKey.toLowerCase());
  });

  if (!filteredDataByType || filteredDataByType.length === 0) {
    return <EmptyList id="transactions" />;
  }

  return (
    <>
      <div className='hidden lg:flex mt-4 justify-end'>
        <span className={OrderIconWrapper}>
          <img className={OrderIcon} src={ORDER_ICON_BRAND} alt="Order Icon" />
        </span>
      </div>
      <div className={`${ContentContainer} mt-4`}>
        <div className={GridWrapper}>
          {filteredDataByType.map((transaction) => (
            <TransactionCard transaction={transaction} key={transaction.hash} />
          ))}
        </div>
      </div>
      <div>
        <Pagination />
      </div>
    </>
  );
}

export const LoadingComponent = () => {
  return (
    <>
      <div className="mt-4 lg:hidden">
        <div className={LoaderCardContainer}>
          {[...Array(3)].map((_, i) => (
            <div key={i}>
              <div className="flex justify-between mb-4">
                <div className={`${LoaderBg} h-6 w-24`} />
                <div className={`${LoaderBg} h-6 w-20`} />
              </div>
              <div className="flex mb-4">
                <div className={`${LoaderBg} h-5 w-2/5`} />
                <div className={`${LoaderBg} h-5 w-10 ml-4`} />
              </div>
              <div className="flex mb-4">
                <div className="flex-1">
                  <div className={`${LoaderBg} h-5 w-20`} />
                </div>
                <div className="flex-1">
                  <div className={`${LoaderBg} h-5 w-24`} />
                </div>
              </div>
              {i < 2 && <div className={LoaderSeparator} />} {/* Separator */}
            </div>
          ))}
        </div>
      </div>
      <div className="mt-4 hidden lg:grid lg:grid-cols-2 3xl:grid-cols-3 lg:gap-6 lg:items-baseline">
        {[...Array(4)].map((_, i) => (
          <div className={LoaderCardContainer} key={i}>
            <div>
              <div className="flex justify-between mb-4">
                <div className={`${LoaderBg} h-6 w-24`} />
                <div className={`${LoaderBg} h-6 w-28`} />
              </div>
              <div className="flex mb-4">
                <div className={`${LoaderBg} h-5 w-2/5`} />
                <div className={`${LoaderBg} h-5 w-10 ml-4`} />
              </div>
              <div className="flex">
                <div className="flex-1">
                  <div className={`${LoaderBg} h-5 w-20`} />
                </div>
                <div className="flex-1">
                  <div className={`${LoaderBg} h-5 w-24`} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export type TransactionsProps = {
  data: DataState<TransactionsData>,
  dataSelector: (data: TransactionsData) => Transaction[]
}

const Transactions = (props: TransactionsProps): JSX.Element => {
  const { data, dataSelector } = props

  return (
    <WithHandledStates
      dataState={data}
      onData={(data) => <TransactionsWithData data={dataSelector(data)} />}
      onLoading={() => <LoadingComponent />}
      onError={() => <LoadingComponent />}
    />
  )
};

export default Transactions;
