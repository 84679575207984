import { useQuery } from '@tanstack/react-query';
import useAccessToken from './useAccessToken';
import { MINUTE } from '../time';
import { loadBackendUserData } from '../backend';

export const queryKey = (id : string | undefined) => {
  return ['backendUserData', id]
}

const useBackendUserData = () => {
  const { data: accessToken } = useAccessToken();

  return useQuery({
    queryKey: queryKey(accessToken),
    queryFn: async () => {
      if (accessToken) {
        return loadBackendUserData(accessToken);
      }
    },
    retry: 3,
    refetchInterval: MINUTE,
    refetchOnWindowFocus: true,
    refetchOnMount: false,
    enabled: !!accessToken,
  });
};

export default useBackendUserData;
