import { useEffect } from 'react';
import { useAppDispatch } from '#/utils/store';
import { FixtureSummary, outcomeId } from '#/utils/backend';
import { Markets, updateMarkets } from '#/utils/slices/marketsSlice';
import { Outcomes, updateOutcomes } from '#/utils/slices/outcomesSlice';
import { InfiniteData } from '@tanstack/react-query';

type UseUpdateOutcomesAndMarketsProps = {
  data: InfiniteData<{ fixtures: FixtureSummary[] }> | undefined;
};

export function useUpdateOutcomesAndMarkets({ data }: UseUpdateOutcomesAndMarketsProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) {
      const newOutcomes: Outcomes = {};
      const newMarkets: Markets = {};

      data.pages.forEach((page) => {
        page.fixtures.forEach((fixture) => {
          newMarkets[fixture.id] = {
            status: fixture.marketStatus,
            acceptable_provision_size: fixture.acceptableProvisionSize,
          };

          Object.values(fixture.odds).forEach((odds) => {
            newOutcomes[outcomeId(odds)] = {
              value: odds.value,
              movement: odds.movement,
              acceptable_bet_size: odds.acceptableBetSize,
            };
          });
        });
      });

      dispatch(updateOutcomes(newOutcomes));
      dispatch(updateMarkets(newMarkets));
    }
  }, [data, dispatch]);
}
