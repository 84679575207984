export const PageContainer = `w-full p-4 xl:pt-0`;
export const ContentContainer = `w-full mt-0`;
export const Header = `w-full flex items-center mb-5`;
export const PageTitle = `text-white font-normal text-sm xxxs:text-xl 2xl:text-2xl 3xl:text-2xl 4xl:text-3xl tracking-wider`;
export const HeaderIcon = `w-5 mr-4`;
export const ContentText = `text-text-gray-4 font-light text-sm 2xl:text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl mb-1`;
export const TabsContainer = `w-full space-y-3 mt-4 xl:mt-6 xl:space-y-6`;
export const TabItems = `w-full text-left rounded-llg bg-primary-bg-2 py-4 pl-4 xl:pl-6 pr-28 relative whitespace-normal`;
export const TabTitleText = `flex text-white font-bold text-sm 2xl:text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl uppercase mb-1`;
export const TabContentText = `text-text-gray-4 font-normal text-sm 2xl:text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl`;
export const ArrowIcon = `w-3 absolute right-4 xl:right-8 top-2/4 -mt-3 xl:-mt-2.5`;
export const FieldContainer = `w-full mb-4 lg:w-2/5 lg:last:!ml-0`;
export const BtnWrapper = `space-y-4`;
export const InputLabel = `block text-white font-normal text-sm 2xl:text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl`;
export const InputField = `w-full font-light bg-primary-bg-3 text-base 2xl:text-lg 3xl:text-xl 4xl:text-2xl 5xl:text-3xl text-text-gray-1 placeholder:text-gray-1 focus:outline-none pl-4 pr-12 py-2.5 2xl:py-4 border-0 rounded-md mt-2`;
export const InputFieldWrapper = `relative w-full`;
export const SgeText = `text-text-gray-1 font-light text-sm xxxs:text-base 2xl:text-lg 3xl:text-xl 4xl:text-2xl 5xl:text-3xl absolute right-3 2xl:right-4 top-4 xl:top-4.5 2xl:top-6`;
export const ButtonSave = `w-full flex justify-center items-center text-center sm:w-80 tracking-wider rounded-md bg-brand-bg-1 px-4 py-2.5 2xl:py-4 text-black font-bold text-sm xxxs:text-base xl:text-lg 3xl:text-xl 4xl:text-2xl 5xl:text-3xl uppercase lg:w-2/5`;
export const ButtonSaveWrapper = `mt-6`;

export const LogoWrapper = `w-full mt-14`;
export const LogoImg = `w-16`;
export const HeaderTitle = `text-2xl text-text-brand-1 font-bold tracking-wider mt-10`;
export const InfoText = `text-white font-normal text-sm 2xl:text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl mb-1`;
export const BackIcon = `w-2.5 mr-2 cursor-pointer rotate-180`;
export const PageTabTitle = `flex text-white font-bold xl:font-normal text-sm uppercase xl:capitalize xl:text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-4xl mb-1`;
export const FieldWrapper = `mt-5 relative lg:flex lg:flex-wrap lg:space-x-14`;
export const SelectField = `lg:bg-[url('/v2-icons/brand-down.svg')] 2xl:bg-[96%_calc(100%_-_18px)]`;