import { useQuery } from "@tanstack/react-query";
import { encodeSha256 } from '#/utils/sha256'
import useAuth0UserData from "#/utils/dataLoaders/useAuth0UserData";

const useHashedUserId = () => {
  const { data: user } = useAuth0UserData()
  
  return useQuery({
    queryKey: ['hashedAuth0UserId', user?.sub],
    queryFn: async () => {
      if(user && user.sub) {
        return encodeSha256(user.sub)
      }
    },
    refetchOnMount: false,
    enabled: !!user?.sub,
  });
  
}

export default useHashedUserId
