import { Odds } from '#/utils/backend';
import { BetSlips } from '#/utils/Slip';
import { ExecuteResult } from '@cosmjs/cosmwasm-stargate';

const extractIdsFromResult = (
  result: ExecuteResult,
): { betIds: string[]; marketIds: string[] } => {
  const betIds: string[] = [];
  const marketIds: string[] = [];
  // Extracting events from the transaction result
  const events = result?.logs?.flatMap((log) => log.events) || [];

  // Look for the wasm-bet-submission or wasm-liquidity-provision event and extract the "id" attribute
  events.forEach((event) => {
    if (
      event.type === 'wasm-bet-submission' ||
      event.type === 'wasm-liquidity-provision'
    ) {
      event.attributes.forEach((attribute) => {
        if (attribute.key === 'id') {
          betIds.push(attribute.value);
        } else if (attribute.key === 'market_id') {
          marketIds.push(attribute.value);
        }
      });
    }
  });

  return { betIds, marketIds };
};

export const isOddSelected = (betSlips: BetSlips, odd: Odds): boolean => {
  return Object.values(betSlips).some((betSlipWithAmount) => {
    const { slip } = betSlipWithAmount;
    return (
      slip.market.id === odd.marketId &&
      slip.outcome.position === odd.outcomePosition &&
      slip.outcome.acceptedRate === odd.value
    );
  });
};

/**
 * Formats a number to ensure it has up to 4 decimal places.
 * If the number is an integer, it will return it as-is.
 * @param {string | number} value - The value to format (string or number).
 * @param {number} [decimalPlaces=4] - Number of decimal places to keep (default is 4).
 * @returns {string} - Formatted string with up to 4 decimal places.
 */
export const formatToDecimalPlaces = (
  value: string | number,
  decimalPlaces: number = 4,
): string => {
  // Convert the value to a string if it's a number
  const stringValue = typeof value === 'number' ? value.toString() : value;

  // Split the string into the integer and decimal parts
  const [integerPart, decimalPart] = stringValue.split('.');

  // If there's no decimal part, return the integer part as-is
  if (!decimalPart) {
    return integerPart;
  }

  // Return the integer part concatenated with the trimmed decimal part
  return `${integerPart}.${decimalPart.slice(0, decimalPlaces)}`;
};

export default extractIdsFromResult;
