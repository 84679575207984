interface BetButtonProp {
  isBetSlipButtonDisabled: boolean;
}
interface HouseButtonProp {
  isHouseSlipButtonDisabled: boolean;
}
export const PlaceBetBtn = ({
  isBetSlipButtonDisabled,
}: BetButtonProp) => `w-full text-center sm:w-80 md:w-full tracking-wider rounded-md p-2 text-black font-semibold text-sm xxs:text-base bg-brand-bg-1
    ${isBetSlipButtonDisabled ? ' bg-btn-disabled-1 text-text-disabled-1' : ''}`;
export const PlaceHouseBtn = ({
  isHouseSlipButtonDisabled,
}: HouseButtonProp) => `w-full text-center sm:w-80  md:w-full tracking-wider rounded-md p-2 text-black font-semibold text-sm xxs:text-base bg-brand-bg-1
     ${isHouseSlipButtonDisabled ? ' bg-btn-disabled-1 text-text-disabled-1' : ''}`;
export const CardInnerContainer = `fixed z-[2] bottom-17 md:!-bottom-0.5 left-0 rounded-t-2xl bg-primary-bg-3 w-full md:w-[400px] md:right-0 md:!left-[inherit] lg:right-8 lg:flex lg:justify-end lg:space-x-4 lg:w-[800px] lg:bg-transparent`;
export const InnerContainer = `rounded-t-2xl bg-black lg:bg-primary-bg-2 left-0 py-4 w-full p-6`;
export const CardContainer = `w-full`;
export const CardHeader = `flex justify-between items-center px-6 py-4 bg-primary-bg-3 w-full rounded-t-2xl`;
export const FlexCenter = `flex items-center`;
export const BetCount = `text-xl text-black font-normal size-8 rounded-full mr-2 bg-brand-bg-1 flex justify-center items-center`;
export const BetCountText = `text-xl text-white font-normal`;
export const ClearButton = `text-sm 3xl:text-base 4xl:text-lg 5xl:text-xl 6xl:text-2xl text-white font-normal`;
export const OddInfoText = `text-base text-white font-bold`;
export const OddInfoSubText = `text-sm 3xl:text-base 4xl:text-lg 5xl:text-xl 6xl:text-2xl text-text-gray-1 font-bold`;
export const TeamNameText = `text-sm 3xl:text-base 4xl:text-lg 5xl:text-xl 6xl:text-2xl tracking-wide text-white font-light`;
export const FlexWrapper = `flex justify-between`;
export const CloseIcon = `w-4.5`;
export const InputField = `bg-card-bg-1 text-base text-text-gray-1 placeholder:font-light focus:outline-none pl-3 pr-12 py-2 border-0 rounded-md max-w-32`;
export const CheckboxWrapper = `flex`;
export const CheckInput = `size-4 appearance-none rounded-sm border border-text-gray-2 focus:outline-none align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer checked:bg-brand-bg-1 checked:bg-[url('/v2-icons/check-icon-dark.svg')] checked:border-brand-bg-1 checked:bg-[length:10px_10px]`;
export const CheckLabel = `inline-block text-white text-sm 3xl:text-base 4xl:text-lg 5xl:text-xl 6xl:text-2xl font-normal`;
export const AmountText = `text-white text-sm 3xl:text-base 4xl:text-lg 5xl:text-xl 6xl:text-2xl font-normal`;
export const TotalAmountText = `text-white tracking-wide text-sm 3xl:text-base 4xl:text-lg 5xl:text-xl 6xl:text-2xl font-light`;
export const IconWrapper = `size-48 rounded-full flex items-center justify-center bg-primary-bg-1 mx-auto p-4`;
export const HomeIcon = `w-14`;
export const ParticipationText = `text-3xl text-center text-text-gray-1 font-semibold tracking-wider`;
export const HouseText = `text-xl text-center text-white font-semibold tracking-wider`;
export const HouseCardContainer = `w-full rounded-2xl bg-primary-bg-2 px-4 py-6`;
export const IconContainer = `text-center my-8`;
export const Separator = `block h-0 border border-solid border-t-0 border-border-bg-1 my-4`;
export const SlipsSeparator = `block w-11/12 mx-auto h-0 border border-solid border-t-0 border-border-bg-1`;
export const ContainerScroll = `overflow-y-auto max-h-60`;
export const SgeText = `text-base absolute right-3 top-2 text-white font-normal`;
export const TextError = `text-sm 3xl:text-base 4xl:text-lg 5xl:text-xl 6xl:text-2xl text-text-danger-1 font-light`;
export const ErrorWrapper = `grow w-full text-right`;
export const OddsWrapper = `w-full rounded-md bg-primary-bg-2 p-4 text-center mt-3 border border-text-danger-1`;
export const TextOdds = `mb-3 text-center text-white text-xs xl:text-sm 3xl:text-base 4xl:text-lg 5xl:text-xl 6xl:text-2xl`;
export const ButtonAccept = `rounded-md px-4 py-2 border border-brand-bg-1 bg-brand-bg-1 text-black text-sm 3xl:text-base 4xl:text-lg 5xl:text-xl 6xl:text-2xl font-medium text-center`;
export const ButtonReject = `rounded-md px-4 py-2 border border-brand-bg-1 text-white text-sm 3xl:text-base 4xl:text-lg 5xl:text-xl 6xl:text-2xl font-medium text-center`;

export const StyleWeb = `lg:w-[400px] lg:[box-shadow:0px_0px_15px_5px_#FFFFFF1A;] lg:rounded-t-2xl`;
