import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Modal from '../common/Modal.component';
import MarketFilters from '../MarketFilter/MarketFilter.component';
import OutcomeFilter from '../common/OutcomeFilter/OutcomeFilter.component';
import SportsFilterModal from '../SportsFilter/SportsFilterModal.component';
import FiltersButton from './FiltersButton.component';
import {
  clearActivityFilters,
  toggleOutcomeFilterAndSynchUrl,
  toggleMarketTypeIdFilterAndSynchUrl,
  toggleSportFilterAndSynchUrl,
  OUTCOME_URL_PARAM,
  SPORT_URL_PARAM,
  MARKET_TYPE_ID_URL_PARAM,
} from '#/utils/slices/activityFiltersSlice';
import { useAppDispatch } from '#/utils/store';
import {
  ActivityOutcome,
  SportEnumValues,
} from '#/utils/backend';
import { BET_ACTIVITY, OUTCOME_OPTIONS, Tab } from '../routes/MyBets';

const BetsFilters = ({
  isOpen,
  onClose,
  activeSubItem
}: {
  isOpen: boolean;
  onClose: () => void;
  activeSubItem: string;
}) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // Local state to hold filter values before applying
  const [localOutcomeFilters, setLocalOutcomeFilters] = useState<ActivityOutcome[]>([]);
  const [localSportFilters, setLocalSportFilters] = useState<SportEnumValues[]>([]);
  const [localMarketTypeIds, setLocalMarketTypeIds] = useState<number[]>([]);
  const activeTab = (searchParams.get('tab') || BET_ACTIVITY) as Tab;
  const isOnSettledTab = activeTab === BET_ACTIVITY && searchParams.get('subItem') === 'settled';

  // Sync initial state from Redux and URL params into local state when modal opens
  useEffect(() => {
    const initOutcomes = searchParams.getAll(OUTCOME_URL_PARAM) as ActivityOutcome[];
    const initSports = searchParams.getAll(SPORT_URL_PARAM) as SportEnumValues[];
    const initMarketTypeIds = searchParams.getAll(MARKET_TYPE_ID_URL_PARAM).map(Number);
    setLocalOutcomeFilters(initOutcomes);
    setLocalSportFilters(initSports);
    setLocalMarketTypeIds(initMarketTypeIds);
  }, [searchParams]);

  // Apply filters to Redux and URL when Apply is clicked
  const applyFilters = () => {
    // Sync to URL params and Redux store
    const selectedOutComes = localOutcomeFilters.filter((outcome) => {
      return OUTCOME_OPTIONS[activeTab].settled.includes(outcome);
    });

    const outcomes =
      activeSubItem === 'active'
        ? OUTCOME_OPTIONS[activeTab].active
        : selectedOutComes;

    const allOutComes = Array.from(new Set([...outcomes, ...localOutcomeFilters]));
    const activeSubItemOutComes =
      activeSubItem === 'active'
        ? OUTCOME_OPTIONS[activeTab].active
        : OUTCOME_OPTIONS[activeTab].settled;

    dispatch(
      toggleOutcomeFilterAndSynchUrl({
        outcomeFilters: allOutComes.length === 0 ? activeSubItemOutComes : allOutComes,
        searchParams,
        setSearchParams,
      })
    );

    dispatch(
      toggleMarketTypeIdFilterAndSynchUrl({
        marketTypeIds: localMarketTypeIds,
        searchParams,
        setSearchParams,
      })
    );

    dispatch(
      toggleSportFilterAndSynchUrl({
        sport: localSportFilters,
        searchParams,
        setSearchParams,
      })
    );

    onClose(); // Close the modal after applying filters
  };

  // Clear filters from both local state and Redux store
  const clearFilters = () => {
    setLocalOutcomeFilters([]);
    setLocalSportFilters([]);
    setLocalMarketTypeIds([]);

    dispatch(clearActivityFilters({ searchParams, setSearchParams }));
    onClose();
  };


  return (
    <Modal title="Filters" isOpen={isOpen} onClose={onClose}>
      <div className="w-full p-5">
        <div className="mb-5 overflow-y-auto space-y-3 max-h-[400px]">
          {isOnSettledTab ? (
            <OutcomeFilter
              localOutcomeFilters={localOutcomeFilters}
              setLocalOutcomeFilters={setLocalOutcomeFilters}
            />
          ) : null}
          <SportsFilterModal
            localSportFilters={localSportFilters}
            setLocalSportFilters={setLocalSportFilters}
          />
          <MarketFilters
            localMarketTypeIds={localMarketTypeIds}
            setLocalMarketTypeIds={setLocalMarketTypeIds}
          />
        </div>
        <FiltersButton clearFilters={clearFilters} applyFilters={applyFilters} />
      </div>
    </Modal>
  );
};

export default BetsFilters;
