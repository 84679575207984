import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from '#/utils/store';
import { mapMessagesToFrequency } from '#/components/ResponsibleGambling/ResponsibleGambling.utils';

export const LAST_NOTIFICATION_KEY = 'lastTimeLimitNotification';

/**
 * Hook to trigger toast notifications based on the selected time limit.
 */
export const useTimeLimitNotification = () => {
  const timeLimit = useSelector(
    (state: RootState) => state.responsibleGambling.timeLimit.reminderFrequency,
  );

  useEffect(() => {
    if (!timeLimit) return;

    const lastNotificationTime = localStorage.getItem(LAST_NOTIFICATION_KEY);
    const currentTime = Date.now();

    // If there's no previous notification time, we avoid showing the notification immediately
    if (!lastNotificationTime) {
      // Set the initial last notification time but don't show the toast immediately
      localStorage.setItem(LAST_NOTIFICATION_KEY, currentTime.toString());
    } else {
      // Calculate the difference since the last notification
      const timeSinceLastNotification =
        currentTime - parseInt(lastNotificationTime, 10);

      // Show the notification only if the time since the last notification is greater than or equal to the time limit
      if (timeSinceLastNotification >= timeLimit) {
        toast.info(
          `You've been playing for ${mapMessagesToFrequency(timeLimit)}. Please take a break if needed.`,
        );
        localStorage.setItem(LAST_NOTIFICATION_KEY, currentTime.toString());
      }
    }

    // Set up an interval to show the toast periodically
    const intervalId = setInterval(() => {
      toast.info(
        `You've been playing for ${mapMessagesToFrequency(timeLimit)}. Please take a break if needed.`,
      );
      localStorage.setItem(LAST_NOTIFICATION_KEY, Date.now().toString());
    }, timeLimit); // Notify at intervals of time limit

    // Clear the interval when the component is unmounted or the time limit changes
    return () => clearInterval(intervalId);
  }, [timeLimit]); // Re-run the effect when the time limit changes
};
