import { BetSlips, HouseSlips } from './Slip';

const BET_SLIPS_KEY = 'bet_slips';
const HOUSE_SLIPS_KEY = 'house_slips';
const DO_NOT_SHOW_HOUSE_TUTORIALS_KEY = 'do_not_show_house_tutorials';

export const getDoNotShowHouseTutorials = (): boolean => {
  return window.localStorage.getItem(DO_NOT_SHOW_HOUSE_TUTORIALS_KEY) === 'true';
};

export const setDoNotShowHouseTutorials = (doNotShow: boolean) => {
  if(doNotShow) {
    return window.localStorage.setItem(DO_NOT_SHOW_HOUSE_TUTORIALS_KEY, 'true');
  } else {
    return window.localStorage.removeItem(DO_NOT_SHOW_HOUSE_TUTORIALS_KEY);
  }
};

export const getBetSlips = (): string | null => {
  return window.localStorage.getItem(BET_SLIPS_KEY);
};

export const setBetSlips = (slips: BetSlips) => {
  window.localStorage.setItem(BET_SLIPS_KEY, JSON.stringify(slips));
};

export const getHouseSlips = (): string | null => {
  return window.localStorage.getItem(HOUSE_SLIPS_KEY);
};

export const setHouseSlips = (slips: HouseSlips) => {
  window.localStorage.setItem(HOUSE_SLIPS_KEY, JSON.stringify(slips));
};
