import {
  Flex1Container,
  FlexContainer,
  LostBadge,
  MatchLabel,
  MatchNameContainer,
  WonBadge,
} from './MatchLabelCard.styled';
import Link from '#/components/common/Link.component';
import { FixtureStatus } from '#/utils/backend';

export type MatchProps = {
  homeTeam: string;
  awayTeam: string;
  fixtureUID: string;
  status: FixtureStatus;
};

export default function MatchLabelCard({
  homeTeam,
  awayTeam,
  fixtureUID,
  status,
}: MatchProps) {
  return (
    <div className="flex justify-between w-full">
      <Link
        to={`/match-detail/${fixtureUID}`}
        state={{ navigated: true }}
        prefetch="intent"
        className={Flex1Container}
        dataId="redirect_to_match_details"
      >
        <div className={FlexContainer}>
          <div className={MatchNameContainer}>
            <span className={MatchLabel}>{homeTeam}</span>
            <span className={MatchLabel}>&nbsp;vs&nbsp;</span>
            <span className={MatchLabel}>{awayTeam}</span>
          </div>
        </div>
      </Link>
      {status !== 'NotStarted' ? (
        // <div style={status === "Cancelled" ?
        //   {padding: "2px 10px", backgroundColor: "rgba(249, 112, 102, 0.1)", color: "#F97066", fontSize: "14px", fontWeight: "bold",borderRadius: "5px"}
        //   : {padding: "2px 10px", backgroundColor: "rgba(104, 252, 201, 0.1)", color: "#68FCC9", fontSize: "14px", fontWeight: "bold",borderRadius: "5px"}
        // }>
        <div className={status === 'Cancelled' ? LostBadge : WonBadge}>
          {status.toUpperCase()}
        </div>
      ) : null}
    </div>
  );
}
