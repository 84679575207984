import Link from '../common/Link.component';
import RedirectLink from '../common/RedirectLink.component';
import {
  EIGHTEEN_PLUS_LOGO,
  SSS_LOGO,
} from '#/constants/common/images.constants';
import {
  FooterHeader,
  PageTitle,
  FooterLogo,
  FooterContainer,
  SubTitle,
  RequirmentBtnWrapper,
  RequirmentBtn,
  RequirmentLogo,
  FooterLinks,
  Separator,
  SocialIconWrapper,
  SocialIcon,
  DiscordIcon,
} from './Footer.styled';
import {
  BETTING_RULES_URL,
  BETTING_TEXT,
  CONTACT_TEXT,
  COOKIES_TEXT,
  DOCS_TEXT,
  DOCS_URL,
  FAQS_URL,
  FAQ_TEXT,
  JOIN_COMMUNITY,
  PRIVACY_POLICY,
  PRIVACY_POLICY_URL,
  RESPONSIBLE_GAMBLING,
  SocialLinksData,
  TERMS_TEXT,
  TERMS_URL,
  USEFUL_LINK,
  footerText,
} from './Footer.constants';
import Button from '#/components/common/Buttons/Button/Button.component';
import AnjSeal from './AnjSeal';
import env from '#/utils/env';

export default function Footer({ country }: { country: string }) {
  const socialLinks = SocialLinksData.filter(
    (socialLink) => !socialLink.isButton,
  );
  const isIreland = country === "IE"; //ISO alpha-2 country code for Ireland is IE
  return (
    <div className={FooterContainer}>
      <div className={FooterHeader}>
        <span>
          <img
            className={FooterLogo}
            src={SSS_LOGO}
            alt="Six Sigma Sports Logo"
          />
        </span>
        <h2 className={PageTitle}>SixSigmaSports</h2>
      </div>
      <h3 className={SubTitle}>The future of betting.</h3>
      <div className={RequirmentBtnWrapper}>
        <span className={RequirmentBtn}>
          <span>
            <img
              className={RequirmentLogo}
              src={EIGHTEEN_PLUS_LOGO}
              alt="18+ Requirement Logo"
            />
          </span>
          <span>18+ Requirement</span>
        </span>
        {isIreland ?         
        <span className={RequirmentBtn}>SixSigma Sports offers remote betting in Ireland as CRGC GAMES LTD and under betting reference number 1021414</span>
        : <span className={RequirmentBtn}>
          <AnjSeal />
        </span>
        }
        {env.cookieBotId ? <script
          id="CookieDeclaration"
          src={`https://consent.cookiebot.com/${env.cookieBotId}/cd.js`}
          type="text/javascript"
        ></script> : null}
      </div>
      <div className="flex">
        <div className="flex-1">
          <h2 className={PageTitle}>{USEFUL_LINK}</h2>
          <div className="mt-4 4xl:mt-6">
            <Button
              className={FooterLinks}
              onClick={() => {
                window.zE && window.zE.activate({ hideOnClose: true });
              }}
              dataId="contact_us"
              rel="noreferrer"
            >
              {CONTACT_TEXT}
            </Button>
            <RedirectLink
              className={FooterLinks}
              href={DOCS_URL}
              target="_blank"
              rel="noreferrer"
              dataId="footer_docs_link"
            >
              {DOCS_TEXT}
            </RedirectLink>
            <RedirectLink
              className={FooterLinks}
              href={PRIVACY_POLICY_URL}
              target="_blank"
              rel="noreferrer"
              dataId="footer_privacy_policy_link"
            >
              {PRIVACY_POLICY}
            </RedirectLink>
            <Link className={FooterLinks} to={'/responsible-gambling'} dataId='footer_responsible_gambling_link'>
              {RESPONSIBLE_GAMBLING}
            </Link>
            <RedirectLink
              className={FooterLinks}
              href={PRIVACY_POLICY_URL}
              target="_blank"
              rel="noreferrer"
              dataId="footer_cookie_settings_link"
            >
              {COOKIES_TEXT}
            </RedirectLink>
            <RedirectLink
              className={FooterLinks}
              href={TERMS_URL}
              target="_blank"
              rel="noreferrer"
              dataId="footer_terms_n_conditions_link"
            >
              {TERMS_TEXT}
            </RedirectLink>
            <RedirectLink
              className={FooterLinks}
              href={FAQS_URL}
              target="_blank"
              rel="noreferrer"
              dataId="footer_faqs_link"
            >
              {FAQ_TEXT}
            </RedirectLink>
            <RedirectLink
              className={FooterLinks}
              href={BETTING_RULES_URL}
              target="_blank"
              rel="noreferrer"
              dataId="footer_betting_rules"
            >
              {BETTING_TEXT}
            </RedirectLink>
          </div>
        </div>
        <div className="flex-1">
          <h2 className={PageTitle}>{JOIN_COMMUNITY}</h2>
          <div className={SocialIconWrapper}>
            {socialLinks.map((item) => (
              <RedirectLink
                href={item.href}
                target="_blank"
                rel="noreferrer"
                key={item.dark_image}
                dataId={`footer_${item.label}_link`}
              >
                <img
                  className={`${SocialIcon} ${item.label === 'discord' ? DiscordIcon : ''}`}
                  loading="lazy"
                  src={item.dark_image}
                  id={`social-links-${item.label}`}
                  alt={`${item.label} Social Icon`}
                />
              </RedirectLink>
            ))}
          </div>
        </div>
      </div>
      <div className={Separator} />
      <h3 className={SubTitle}>{footerText(new Date().getFullYear())}</h3>
    </div>
  );
}
