import { useQuery } from '@tanstack/react-query';
import useCosmosKitWallet from './useCosmosKitWallet';

const DENOM = 'usge';

const useBalance = () => {
  const { stargateClient, account } = useCosmosKitWallet();

  return useQuery({
    queryKey: ['balance', account?.address],
    queryFn: async () => {
      if (account && stargateClient) {
        return stargateClient.getBalance(account.address, DENOM);
      }
    },
    enabled: account !== undefined && stargateClient !== undefined,
  });
};

export default useBalance;
