import { useEffect, useMemo } from 'react';
import { loadKycToken } from '#/utils/backend';
import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from '#/utils/store';
import useAccessToken from '#/utils/dataLoaders/useAccessToken';
import useBackendUserData from '#/utils/dataLoaders/useBackendUserData';

export const useKycStatus = (forceFetch?: boolean) => {
  const { data : user } = useBackendUserData()

  const auth0Id = user?.id;
  const kycStatus = user?.kycStatus;
  const sgeAddress = user?.walletAddr

  const betSlipsFromStore = useAppSelector((state) => state.betSlips);
  const betSlips = useMemo(
    () =>
      Object.values(betSlipsFromStore || {}).filter(
        (slip) => parseFloat(slip.amount) > 0,
      ),
    [betSlipsFromStore],
  );
  const houseSlipsFromStore = useAppSelector((state) => state.houseSlips);
  const houseSlips = useMemo(
    () =>
      Object.values(houseSlipsFromStore || {}).filter(
        (slip) => parseFloat(slip.amount) > 0,
      ),
    [houseSlipsFromStore],
  );

  const activeSlip = !!betSlips.length || !!houseSlips.length;

  const {
    data : token,
    isLoading: tokenLoading,
    error: tokenError,
  } = useAccessToken();

  const {
    data: kycData,
    error: kycError,
    isLoading: kycLoading,
  } = useQuery({
    queryKey: ['kyc_token', auth0Id],
    queryFn: async () => {
      if (token) {
        return loadKycToken(token);
      }
    },
    enabled:
      !!auth0Id &&
      !!token &&
      !!sgeAddress &&
      kycStatus === 'APPROVED' &&
      (activeSlip || forceFetch),
    staleTime: 1000 * 60 * 3, // refresh every 3 minutes
  });

  useEffect(() => {
    if (kycError) {
      console.error(kycError);
    }
  }, [kycError]);

  const kycToken = kycData?.token || null;

  return {
    kycToken,
    isLoading: tokenLoading || kycLoading,
    error: tokenError || kycError,
  };
};
