import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SportEnums, stringToSport } from '../backend';

export type State = {
  sport: SportEnums;
};

const initialState: State = {
  sport: 'Soccer',
};

const slice = createSlice({
  name: 'sport',
  initialState,
  reducers: {
    setSport: (state, data: PayloadAction<string>) => {
      state.sport = stringToSport(data.payload) ?? 'Soccer';
    },
  },
});

export const { setSport } = slice.actions;
export default slice.reducer;
