interface FilterListItemProp {
  isActive: boolean;
}
export const SportList = `list-none max-h-64 4xl:max-h-80 5xl:max-h-100 overflow-y-auto`;
export const SportListItemContainer = ({
  isDisabled,
}: {
  isDisabled: boolean;
}) => `lg:py-2 xl:py-2 cursor-pointer flex items-center justify-between list-none
    ${isDisabled ? ' pointer-events-none opacity-50' : ''}`;
export const SportIconContainer = `block w-5 6xl:w-8 mr-2`;
export const SportIcon = `mr-2 w-4 h-4 4xl:w-6 4xl:h-6 5xl:w-8 5xl:h-8`;
export const SportLabel = `leading-5`;

export const SportListItem = ({
  isActive,
}: FilterListItemProp) => `text-base 3xl:text-lg 4xl:text-xl 5xl:text-3xl 6xl:text-4xl py-2 lg:py-0 5xl:py-4 text-text-gray-2 flex justify-between items-center font-light lg:w-full
  ${isActive
    ? ' text-base 3xl:text-lg 4xl:text-xl 5xl:text-3xl 6xl:text-4xl py-2 lg:py-0 4xl:py-4 font-normal'
    : ''
  }`;

export const SportItem = `inline-flex lg:items-center`;
export const ComingSoon = ``;
// Style for V2
export const SportListWrapper = `w-full flex overflow-x-auto overflow-y-hidden space-x-2`;
export const SportListItemWrapper = ({
  isDisabled,
  isActive,
}: {
  isDisabled: boolean;
  isActive: boolean;
}) => `lg:cursor-pointer flex items-center justify-between rounded-md 5xl:rounded-lg py-2.5 px-3.5
    ${isDisabled ? ' pointer-events-none opacity-50' : ''}
     ${isActive ? ' bg-brand-bg-1' : ''}`;

export const InnerWrapperListItem = ({
  isActive,
}: FilterListItemProp) => `text-base 3xl:text-lg 4xl:text-xl 5xl:text-3xl 6xl:text-4xl text-white flex justify-between items-center font-bold 5xl:px-6 5xl:py-3
  ${isActive ? '!text-black' : ''}`;
export const SportsIcon = `size-5 5xl:size-7`;
export const SportIconWrapper = `block w-6 h-auto mr-1 5xl:mr-3`;
export const AppliedFilterWrapper = `w-full flex overflow-x-auto overflow-y-hidden space-x-2 xxs:space-x-4`;
