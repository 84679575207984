/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  NavContainer,
  MenuIcon,
  SgeLogo,
  NavButtonWrapper,
  MenuIconBtn,
  LoginButton,
  SignupButton,
  DropDownStyle,
  DropDownInnerWrapper,
} from './NavBar.styled';
import Button from '../common/Buttons/Button/Button.component';
import { useState, useEffect } from 'react';
import SideMenu from './SideMenuPanel/SideMenu.component';
import Link from '../common/Link.component';
import { configItems, logo, getMenuItems } from './NavBar.constants';
import WalletConnector from '../WalletConnector/WalletConnector';
import { useAuth0 } from '@auth0/auth0-react';
import {
  BACK_ICON,
  MENU_BARS_ICON,
  NOTIFICATION_ICON,
  NOTIFICATION_ICON_BRAND,
  SSS_LOGO,
} from '#/constants/common/images.constants';
import { useLocation } from 'react-router-dom';
import Notifications from '../routes/Notifications';
import useBackendUserData from '#/utils/dataLoaders/useBackendUserData';
import ImgActionTag from '../common/ImageActionTag.component';
import { useAppSelector } from '#/utils/store';
import { DEFAULT_SPORT } from '#/App';
import useClickOutside from '#/hooks/useClickOutside';

export default function NavBar() {
  const location = useLocation();
  const backButtonLink = showBackIcon(location.pathname);
  const { loginWithRedirect, logout } = useAuth0();
  const { data: user } = useBackendUserData();
  const isAuthenticated = !!user;
  const currentSport =
    useAppSelector((state) => state.sport.sport) || DEFAULT_SPORT;

  const isKycApproved = user?.kycStatus == 'APPROVED';

  const [showMenu, setShowMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  useEffect(() => {
    if (showMenu) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [showMenu]);

  const dropdownRef = useClickOutside<HTMLDivElement>(() => setShowDropdown(false));

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };
  return (
    <div className="p-4">
      <nav className={NavContainer}>
        <div className="flex items-center xl:hidden">
          {backButtonLink ? (
            <span className="mr-4">
              <Link to={backButtonLink} dataId="navbar_back">
                <img className="w-2.5" src={BACK_ICON} alt="Back Icon" />
              </Link>
            </span>
          ) : null}
          <Link to="/" dataId="navbar_logo">
            <img
              className={SgeLogo}
              src={SSS_LOGO}
              alt="Six Sigma Sports Logo"
            />
          </Link>
        </div>
        <div className={NavButtonWrapper}>
          <span className="hidden lg:block relative">
            <ImgActionTag
              className="w-4 3xl:w-6 cursor-pointer"
              src={showDropdown ? NOTIFICATION_ICON_BRAND : NOTIFICATION_ICON}
              alt="Notification Icon"
              onClick={toggleDropdown}
              dataId="notification_icon"
            />
            {showDropdown && (
              <div ref={dropdownRef} className={DropDownStyle} id="dropdown" data-testid={"notification_dropdown_opened"}>
                <div className={DropDownInnerWrapper}>
                  <Notifications />
                </div>
              </div>
            )}
          </span>
          {isAuthenticated ? (
            isKycApproved && <WalletConnector />
          ) : (
            <>
              <Button
                className={LoginButton}
                type="button"
                onClick={() => loginWithRedirect()}
                dataId="navbar_login"
              >
                Log in
              </Button>
              <Button
                className={SignupButton}
                type="button"
                onClick={() =>
                  loginWithRedirect({
                    authorizationParams: {
                      screen_hint: 'signup',
                    },
                  })
                }
                dataId="navbar_signup"
              >
                Sign up
              </Button>
            </>
          )}
          <Button
            className={MenuIconBtn}
            onClick={() => setShowMenu(true)}
            dataId="navbar_menu"
          >
            <img className={MenuIcon} src={MENU_BARS_ICON} alt="Menu Icon" />
          </Button>
        </div>
      </nav>
      <div className={`SideMenuWrapper ${showMenu ? 'open' : ''}`}>
        <SideMenu
          menuItems={getMenuItems(currentSport)}
          configItems={configItems}
          isAuthenticated={isAuthenticated}
          logo={logo}
          close={() => setShowMenu(false)}
          logout={logout}
        />
      </div>
    </div>
  );
}

function showBackIcon(pathname: string) {
  const referrer =
    document.referrer && document.referrer.startsWith(location.pathname)
      ? new URL(document.referrer)
      : null;

  if (pathname.includes('/deposit') || pathname.includes('/transactions')) {
    const hash = '#openWalletView';
    if (referrer) {
      return referrer.pathname + hash + referrer.search;
    }
    return '/soccer' + hash;
  }

  if (pathname.includes('/match-detail/')) {
    if (referrer) {
      return referrer.pathname + referrer.search;
    }
    return '/soccer';
  }

  // Add a condition for `/edit-profile`
  if (pathname.includes('/edit-profile')) {
    if (referrer) {
      return referrer.pathname + referrer.search;
    }
    return '/soccer'; // Default back to the main soccer page
  }

  return '';
}
