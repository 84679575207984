import useInfiniteBetsDataLoader from '#/utils/dataLoaders/useInfiniteBetsDataLoader';
import LoadingComponent from './BetActivityLoading.component';
import EmptyList from '../common/EmptyStates/EmptyState.component';
import BetActivityCard from './BetActivityCard.component';
import useBackendUserData from '#/utils/dataLoaders/useBackendUserData';
import { useAppSelector } from '#/utils/store';
import { useSearchParams } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import { State } from '#/utils/slices/activityFiltersSlice';
import { BetActivityOutcomes } from '#/utils/backend';

const filterBetActivityOutcomes = (outcomes: string[]): BetActivityOutcomes[] => {
  const validBetOutcomes: BetActivityOutcomes[] = ['active', 'submitted', 'won', 'lost', 'failed', 'refunded'];
  return outcomes.filter((outcome): outcome is BetActivityOutcomes =>
    validBetOutcomes.includes(outcome as BetActivityOutcomes)
  );
};

const BetActivityCards = () => {
  const { data: user } = useBackendUserData();
  const [searchParams] = useSearchParams();
  const auth0Id = user?.id ?? '';
  const sgeAddress = user?.walletAddr ?? '';
  const { search, outcomeFilters, sports, marketTypeIds: marketTypes } = useAppSelector(
    (state: { activityFilters: State }) => state.activityFilters
  );
  const activeSubItem = searchParams.get('subItem') ?? 'active';

  const { ref, inView } = useInView();

  const filteredOutcomes = filterBetActivityOutcomes(outcomeFilters);

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteBetsDataLoader({
    search,
    filters: { outcomes: filteredOutcomes, sports, marketTypes },
    userId: auth0Id,
    sgeAddress
  });

  const bets = data ? data.pages.flatMap((page) => page.bets) : [];

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView, hasNextPage, isFetchingNextPage]);

  if (isLoading && !bets.length) {
    return <LoadingComponent />;
  }

  if (isError) {
    return <div>Error loading bets.</div>;
  }

  if (!bets.length && !isLoading) {
    return <EmptyList id="bets" />;
  }

  return (
    <div>
      {bets.map((bet) => (
        <BetActivityCard key={bet.id} bet={bet} activeSubItem={activeSubItem} />
      ))}
      <div ref={ref}></div>
      {isFetchingNextPage && <LoadingComponent />}
    </div>
  );
};

export default BetActivityCards;
