import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getFavouriteFixtures,
  addFavouriteFixture,
  removeFavouriteFixture,
} from '../backend';
import { useAuth0 } from '@auth0/auth0-react';

export const useFavouritesLoader = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const queryClient = useQueryClient();

  const fetchFavourites = async () => {
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently();
      return getFavouriteFixtures(accessToken);
    }
    return [];
  };

  // Fetch the list of favourite fixture IDs
  const {
    data: favouritesIds = [],
    isLoading: isFavouritesLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ['favourites'],
    queryFn: fetchFavourites,
    enabled: isAuthenticated,
  });

  // Mutation to add a favourite
  const addFavourite = useMutation({
    mutationFn: async (fixtureId: number) => {
      const accessToken = await getAccessTokenSilently();
      await addFavouriteFixture(fixtureId, accessToken);
      return fixtureId;
    },
    onSuccess: (newFixtureId) => {
      queryClient.setQueryData(
        ['favourites'],
        (oldData: number[] | undefined) => {
          if (oldData) {
            return [...oldData, newFixtureId];
          }
          return [newFixtureId];
        },
      );
    },
  });

  // Mutation to remove a favourite
  const removeFavourite = useMutation({
    mutationFn: async (fixtureId: number) => {
      const accessToken = await getAccessTokenSilently();
      await removeFavouriteFixture(fixtureId, accessToken);
      return fixtureId;
    },
    onSuccess: (removedFixtureId) => {
      queryClient.setQueryData(
        ['favourites'],
        (oldData: number[] | undefined) => {
          if (oldData) {
            return oldData.filter((id) => id !== removedFixtureId);
          }
          return [];
        },
      );
    },
  });

  return {
    favouritesIds,
    isLoading:
      isFavouritesLoading ||
      addFavourite.isPending ||
      removeFavourite.isPending,
    isError,
    error,
    addFavourite,
    removeFavourite,
  };
};
