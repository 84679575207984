import React from 'react';
import ActivityCard from '#/components/common/ActivityCard/ActivityCard.component';
import { formatDateToDesiredFormat } from '#/utils/datetime/datetime';
import {
  Bet,
  getStatusType,
} from '#/utils/backend';
import { getMarketNameById, getOutcomeName } from '#/utils/marketsMap';
import { typedOddValue } from '#/utils/sgeUtils/sgeUtils';
import { UPLOAD_ICON, DOWNLOAD_ICON } from '#/constants/common/images.constants';
import useBackendUserData from '#/utils/dataLoaders/useBackendUserData';

interface BetActivityCardProps {
  bet: Bet;
  activeSubItem: string;
}

const BetActivityCard: React.FC<BetActivityCardProps> = ({ bet, activeSubItem }) => {
  const { data: user } = useBackendUserData();

  if (!bet) return null;

  const getBetState = (state: string, bet: Bet) => {
    if (bet.result === 'NoResult' && bet.state === 'Refunded') return 'Refunded';
    if (bet.result === 'NoResult' && activeSubItem === 'settled') return 'Failed';
    return activeSubItem === 'settled' ? bet.result : state;
  };

  const outcomePosition = parseInt(bet?.outcomeId.split('-')[1] || '0', 10);
  const statusType = getStatusType(bet);

  const getReturnAmount = () => {
    if (bet.state === 'Refunded') {
      return bet.stake; // Return stake if the bet was refunded
    } else if (bet.result === 'Won') {
      return bet.totalReturn; // Return totalReturn if the bet was won
    } else if (bet.result === 'Lost') {
      return '0'; // Return 0 if the bet was lost
    }
    return bet.totalReturn || '0'; // Default to totalReturn or 0 if none of the conditions match
  };

  return (
    <ActivityCard
      key={bet.id}
      statusText={getBetState(bet.state, bet)}
      statusType={statusType}
      dateText={formatDateToDesiredFormat(bet.submittedAt || '')}
      title={`${getOutcomeName(bet.marketTypeId, outcomePosition)} @ ${typedOddValue(
        user?.oddsFormat || 'decimal',
        Number(bet.odds),
        !!user?.id
      )}`}
      titleText={`${getMarketNameById(bet.marketTypeId)}`}
      subTitle={bet.fixtureName}
      footerInfo={[
        { iconSrc: UPLOAD_ICON, text: `${bet.stake} SGE` },
        { iconSrc: DOWNLOAD_ICON, text: `${getReturnAmount()} SGE` },
      ]}
    />
  );
};

export default BetActivityCard;
