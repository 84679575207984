import { useQuery } from '@tanstack/react-query';
import env from '../env';
import useCosmosKitWallet from '#/hooks/useCosmosKitWallet';
import { CampaignDetails, CapUsed, RewardBucket } from '../backend';
import { useAppDispatch } from '../store';
import {
  setBetCapUsed,
  setCampaignsApplicable,
  setRewardBuckets,
  setTotalRewardsAvailable,
} from '../slices/rewardsSlice';
import useHashedUserId from '#/hooks/useHashedUserId';
import useBackendUserData from './useBackendUserData';
import { MINUTE } from '../time';

function useRewardsDataLoader() {
  const { cosmWasmClient: client } = useCosmosKitWallet();
  const { data: hashedUserId } = useHashedUserId()
  const { data: user } = useBackendUserData()

  const dispatch = useAppDispatch();

  return useQuery({
    queryKey: ['rewardsLoader', user?.id],
    enabled: !!hashedUserId && !!client && !!user,
    queryFn: async () => {
      if (hashedUserId && client) {
        const result: RewardBucket[] = await client.queryContractSmart(
          env.contract,
          {
            locked_tokens: { user_id: hashedUserId, start_after: null, limit: 30 },
          },
        );
        const campaigns: CampaignDetails[] = await client.queryContractSmart(
          env.contract,
          {
            campaigns: { start_after: null, limit: 30, descending: true },
          },
        );
        
        const campaignIdList = campaigns.map((item) => item.id);

        const capUsedList: number[] = await client.queryContractSmart(
          env.contract,
          {
            campaign_participation: { user_id: hashedUserId, ids: campaignIdList },
          },
        );

        const betCapUsed : CapUsed[] = [];

        campaignIdList.forEach((id, index) => {
          if(index < capUsedList.length) {
            betCapUsed.push({ id, cap: capUsedList[index] })
          }
        })

        const totalRewards = result
          .map((item) => +item.amount)
          .reduce((partialSum, a) => partialSum + a, 0);

        dispatch(setTotalRewardsAvailable(totalRewards));
        dispatch(setRewardBuckets(result));
        dispatch(setCampaignsApplicable(campaigns));
        dispatch(
          setBetCapUsed(betCapUsed),
        );

        return {
          balance: totalRewards,
        };
      }
    },
    refetchInterval: MINUTE
  });
}

export default useRewardsDataLoader;
