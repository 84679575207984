import { isMobile } from '#/utils/platform';
import { useSearchParams } from 'react-router-dom';
import {
  ContentContainer,
  CheckInput,
  HeaderWrapper,
  Title,
  HeadDivider,
  FilterCardContainer,
  MarketLabel,
  MarketItemContainer,
} from './Transactions.styled';

interface Filter {
  id: string;
  label: string;
}

interface StatusFilterProps {
  selectedStatus: { [key: string]: boolean };
  setSelectedStatus: (status: { [key: string]: boolean }) => void;
}

export default function StatusFilter({
  selectedStatus,
  setSelectedStatus,
}: StatusFilterProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters: Filter[] = [
    { id: 'success', label: 'Success' },
    { id: 'failed', label: 'Failed' },
  ];

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setSelectedStatus({
      ...selectedStatus,
      [id]: checked,
    });
    if (!isMobile) {
      let statusFilters = searchParams.get('statusFilter')?.split(',') || [];

      if (checked) {
        if (!statusFilters.includes(id)) {
          statusFilters.push(id);
        }
      } else {
        statusFilters = statusFilters.filter((filter) => filter !== id);
      }

      if (statusFilters.length > 0) {
        searchParams.set('statusFilter', statusFilters.join(','));
      } else {
        searchParams.delete('statusFilter');
      }

      setSearchParams(searchParams);
    }
  };

  return (
    <div className={ContentContainer}>
      <div className={HeaderWrapper}>
        <span className={Title}>Status</span>
      </div>
      <hr className={HeadDivider} />
      <div className={FilterCardContainer}>
        <ul>
          {filters.map((filter) => (
            <li className={MarketItemContainer} key={filter.id}>
              <input
                className={`${CheckInput} !mr-2`}
                type="checkbox"
                id={filter.id}
                checked={selectedStatus[filter.id] || false}
                onChange={handleStatusChange}
              />
              <span className={MarketLabel}>{filter.label}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
