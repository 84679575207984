import { useQuery } from '@tanstack/react-query';
import { loadLiquidityData } from '../backend';
import useBackendUserData from './useBackendUserData';
import { TEN_SECONDS } from '../time';

const useHouseProvisionsDataLoader = ({ enabled }: { enabled: boolean }) => {
  const { data: user } = useBackendUserData()

  const sgeAddress = user?.walletAddr
  const userId = user?.id

  return useQuery({
    queryKey: ['houseProvisionsDataLoader', userId],
    // Only once the user has the sgeAddress stored, it means he/she is onboarded
    // and the backend is aware of the id
    enabled: enabled && !!userId && !!sgeAddress,
    queryFn: () => {
      if (userId && sgeAddress) {
        return loadLiquidityData(userId);
      }
      return null;
    },
    refetchInterval: TEN_SECONDS
  });
};

export default useHouseProvisionsDataLoader;
