import useBalance from '#/hooks/useBalance';
import { convertToSge } from '#/utils/sgeUtils/sgeUtils';
import { DecimalText, WalletBtnIcon } from '../NavBar/NavBar.styled';
import { ConnectAllChainsWallet } from '#/components/graz/connect-wallet';
import Button from '#/components/common/Buttons/Button/Button.component';
import WalletDetailsCard, {
  WalletDetailsContents,
} from '../WalletDetailsCard/WalletDetailsCard.component';
import { useState } from 'react';
import {
  DropDownInnerWrapper,
  DropDownStyle,
} from '../WalletDetailsCard/WalletDetailsCard.styled';
import useCosmosKitWallet from '#/hooks/useCosmosKitWallet';
import { walletLogo } from '#/utils/wallets';
import useRewardsDataLoader from '#/utils/dataLoaders/useRewardBalance';
import useClickOutside from '#/hooks/useClickOutside';

const WalletBtn = `flex justify-center items-center rounded-md bg-card-bg-1 px-4 py-2 text-white font-normal text-sm xxxs:text-base 3xl:text-2xl h-8 xxxs:h-9 3xl:h-auto`;

function WalletConnector() {
  const { account } = useCosmosKitWallet();

  return (
    <>{account !== undefined ? <UserWallet /> : <ConnectAllChainsWallet />}</>
  );
}

const Balance = ({ balance }: { balance: string }): JSX.Element => {
  const balanceSge = convertToSge(balance);
  const [integerPart, decimalPart = ''] = balanceSge.split('.');

  return (
    <span>
      {integerPart}
      {decimalPart && '.'}
      <span className={DecimalText}>{decimalPart.slice(0, 4)}</span>
    </span>
  );
};

function UserWallet() {
  const { wallet } = useCosmosKitWallet();
  const { data: balance, isFetched } = useBalance();
  useRewardsDataLoader();

  const [isOpen, setIsOpen] = useState(false);

  const [showWalletDropdown, setShowWalletDropdown] = useState(false);
  const toggleWalletDropdown = () => {
    setShowWalletDropdown((prev) => !prev);
  };

  const dropdownRef = useClickOutside<HTMLDivElement>(() => setShowWalletDropdown(false));

  return (
    <>
      <span className="block lg:hidden">
        <Button
          onClick={() => setIsOpen(true)}
          dataId="wallet_details_open"
          className={`${WalletBtn} inline-flex items-center ${isFetched ? '' : 'opacity-50'}`}
        >
          <img
            className={WalletBtnIcon}
            src={walletLogo(wallet)}
            alt="Wallet Icon"
          />

          {isFetched && balance ? (
            <>
              <Balance balance={balance.amount} />
              <span className="ml-1">SGE</span>
            </>
          ) : (
            <span>Loading...</span>
          )}
        </Button>

        <WalletDetailsCard isOpen={isOpen} setIsOpen={setIsOpen} />
      </span>

      <span className="hidden lg:block relative">
        <Button
          onClick={toggleWalletDropdown}
          dataId="wallet_details_open"
          className={`${WalletBtn} inline-flex items-center ${isFetched ? '' : 'opacity-50'}`}
        >
          <img
            className={WalletBtnIcon}
            src={walletLogo(wallet)}
            alt="Wallet Icon"
          />

          {isFetched && balance ? (
            <>
              <Balance balance={balance.amount} />
              <span className="ml-1 3xl:ml-3">SGE</span>
            </>
          ) : (
            <span>Loading...</span>
          )}
        </Button>

        {showWalletDropdown && (
          <div ref={dropdownRef} className={DropDownStyle}>
            <div className={DropDownInnerWrapper}>
              <WalletDetailsContents />
            </div>
          </div>
        )}
      </span>
    </>
  );
}

export default WalletConnector;
