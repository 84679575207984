import { useState } from 'react';
import {
  CollapseCardChild,
  CollapseCardContainer,
  CollapseCardHeader,
  CollapseCardHeaderElement,
  CollapseCardNode,
  CollapseContent,
} from './CollapseCard.styled';
import type { CardProps } from './CollapseCard.types';
import ActionTag from '../ActionTags.component';

export default function CollapseCard({
  title,
  children,
  expanded = true,
  className = '',
}: CardProps) {
  const [expand, setExpand] = useState(expanded);

  return (
    <div className={`${CollapseCardContainer} ${className}`}>
      {typeof title === 'string' ? (
        <ActionTag
          type="div"
          className={CollapseCardHeader}
          onClick={() => setExpand(!expand)}
          dataId="collapse_card_click"
          ariaExpanded={expand}
        >
          {title}
        </ActionTag>
      ) : (
        <div className={CollapseCardNode}>
          <ActionTag
            type="div"
            className={CollapseCardHeaderElement}
            onClick={() => setExpand(!expand)}
            dataId="collapse_card_click"
            ariaExpanded={expand}
          >
            {title}
          </ActionTag>
        </div>
      )}

      <div className={CollapseContent({ expand })} id={`${expand}-${title}`}>
        <div className={CollapseCardChild}>{children}</div>
      </div>
    </div>
  );
}
