import { LoaderBg, LoaderCardContainer } from "../common/LoaderStyle/LoaderStyle.styled";

const LoadingComponent = () => {
  return (
    <div className="px-0">
      <div className={`${LoaderCardContainer} mb-4`}>
        <div>
          <div className="w-full flex justify-between items-center mb-2">
            <div className={`${LoaderBg} h-6 w-1/4`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-1/3 mb-1`}></div>
          <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
          <div className={`${LoaderBg} h-5 w-11/12 mb-2`}></div>
          <div className="w-full flex items-center">
            <div className={`${LoaderBg} h-5 w-2/5 mr-16`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
        </div>
      </div>
      <div className={`${LoaderCardContainer} mb-4`}>
        <div>
          <div className="w-full flex justify-between items-center mb-2">
            <div className={`${LoaderBg} h-6 w-1/4`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-1/3 mb-1`}></div>
          <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
          <div className={`${LoaderBg} h-5 w-11/12 mb-2`}></div>
          <div className="w-full flex items-center">
            <div className={`${LoaderBg} h-5 w-2/5 mr-16`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
        </div>
      </div>
      <div className={`${LoaderCardContainer} mb-4`}>
        <div>
          <div className="w-full flex justify-between items-center mb-2">
            <div className={`${LoaderBg} h-6 w-1/4`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-1/3 mb-1`}></div>
          <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
          <div className={`${LoaderBg} h-5 w-11/12 mb-2`}></div>
          <div className="w-full flex items-center">
            <div className={`${LoaderBg} h-5 w-2/5 mr-16`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
        </div>
      </div>
      <div className={`${LoaderCardContainer} mb-4`}>
        <div>
          <div className="w-full flex justify-between items-center mb-2">
            <div className={`${LoaderBg} h-6 w-1/4`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
          <div className={`${LoaderBg} h-5 w-1/3 mb-1`}></div>
          <div className={`${LoaderBg} h-5 w-1/2 mb-2`}></div>
          <div className={`${LoaderBg} h-5 w-11/12 mb-2`}></div>
          <div className="w-full flex items-center">
            <div className={`${LoaderBg} h-5 w-2/5 mr-16`}></div>
            <div className={`${LoaderBg} h-5 w-1/3`}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingComponent;
