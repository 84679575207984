import type {
  MultipleOddsContainerProp,
} from './MarketCard.types';

export const FlexCenterJustify = `flex items-center justify-between`;
export const OddName = `flex-1 text-xs 3xl:text-base 4xl:text-lg 5xl:text-2xl 6xl:text-3xl text-text-gray-2 mr-1 md:mr-4 lg:mx-1 xl:mr-4 flex items-center w-16 lg:w-auto`;
export const MultipleOddsWrapper = `mb-0`;
export const OddHeaderMultiple = `text-base font-semibold text-text-gray-4 uppercase flex-1`;
export const OddChoicesContainer = `text-xs 3xl:text-base 4xl:text-lg 5xl:text-2xl 6xl:text-3xl text-text-gray-2 mr-3 lg:mr-6 2xl:mr-7 4xl:mr-14`;
export const Separator = `h-0 border border-solid border-t-0 border-border-bg-1`;
export const SeparatorNew = `h-0 border border-solid border-t-0 border-border-bg-1 my-2`;

export const MultipleOddsContainer = ({
  hasMoreThanTwoOdds,
  hasthreeOdds,
  $isDisabled,
}: MultipleOddsContainerProp) => `grid grid-cols-2 gap-3 w-full
    ${hasMoreThanTwoOdds ? ' grid-cols-3 sm:grid-cols-4 md:grid-cols-5 relative' : ''}
    ${hasthreeOdds ? '!grid-cols-3 !w-full' : ''}
    ${' justify-between'}
      ${$isDisabled ? ' opacity-25 pointer-events-none' : ''}`;

export const FlexJustify = `flex justify-between`;
export const MarginContainer = `mx-4 mt-4 mb-1 lg:m-0`;

export const LimitMsg = `text-center text-sm text-text-danger-1 p-2.5`;
export const OddsContainer = `flex flex - 1 items - center justify - between lg: justify - center text - base font - normal lg: font - bold w - full lg: w - full`;
export const CardHeader = `flex justify-between items-center pt-4 pb-4 px-4`;

export const CardContainer = `mb-4 rounded-llg bg-primary-bg-2`;
