import Button from '#/components/common/Buttons/Button/Button.component';
import { ARROW_BACK } from '#/constants/common/images.constants';
import { useState } from 'react';
import {
  BackBtn,
  BackBtnIcon,
  BgContainer,
  BgInnerContainer,
  ButtonNext,
  ButtonWhite,
  ButtonWrapper,
  ModalContentWrapper,
  NavDotsActive,
  NavDotsInactive,
  NavDotsWrapper,
  StepContents,
  VideoWrapper,
  WalletContentText,
  HeadTitle,
  IframeContainer,
} from './WalletTutorials.styled';
import CenterModal from '#/components/common/CenterModal.component';

const WalletTutorials = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [setCurrentStep, setShowCurrentStep] = useState(0);

  const handleButtonNext = () => {
    setShowCurrentStep(setCurrentStep + 1);
  };

  const handleButtonBack = () => {
    setShowCurrentStep(setCurrentStep - 1);
  };
  const navigationDots = () => {
    const numbers = [0, 1, 2, 3, 4];
    return numbers.map((number) => (
      <span
        key={number}
        className={setCurrentStep === number ? NavDotsActive : NavDotsInactive}
      />
    ));
  };
  return (
    <CenterModal isOpen={isOpen} onClose={onClose}>
      <div className={BgContainer}>
        <div className={BgInnerContainer}>
          <div
            className={VideoWrapper}
            style={{ display: setCurrentStep === 4 ? 'block' : 'none' }}
          >
            <iframe
              className={IframeContainer}
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/zi1BEREK8tE?si=Bsx7HgI3I-lHIU3b"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        </div>
      </div>
      <div className={ModalContentWrapper}>
        <div
          className={StepContents}
          style={{ display: setCurrentStep === 0 ? 'block' : 'none' }}
        >
          <h2 className={HeadTitle}>What is a wallet?</h2>
          <p className={WalletContentText}>
            A wallet is like a digital bank account for your cryptocurrency. It
            keeps your funds safe and lets you send or receive crypto. You`ll
            need to make one to store vour cryptocurrency winnings securely.
          </p>
          <div className={ButtonWrapper}>
            <span />
            <Button
              className={`${ButtonWhite} ${ButtonNext}`}
              onClick={handleButtonNext}
              dataId="wallet_info_next"
            >
              Next
            </Button>
          </div>
        </div>
        <div
          className={StepContents}
          style={{ display: setCurrentStep === 1 ? 'block' : 'none' }}
        >
          <h2 className={HeadTitle}>How do I get a wallet?</h2>
          <p className={WalletContentText}>
            To get started, simply choose a wallet—we&apos;ll recommend one if you&apos;re
            unsure. Then, install the browser extension and follow the provided
            instructions to set up your account.
          </p>
          <div className={ButtonWrapper}>
            <Button className={BackBtn} onClick={handleButtonBack} dataId="wallet_info_back">
              <span className="flex items-center">
                <img
                  className={BackBtnIcon}
                  src={ARROW_BACK}
                  alt="Arrow icon"
                />{' '}
                Back
              </span>
            </Button>
            <Button
              className={`${ButtonWhite} ${ButtonNext}`}
              onClick={handleButtonNext}
              dataId="wallet_info_next"
            >
              Next
            </Button>
          </div>
        </div>
        <div
          className={StepContents}
          style={{ display: setCurrentStep === 2 ? 'block' : 'none' }}
        >
          <h2 className={HeadTitle}>Save your recovery phrase</h2>
          <p className={WalletContentText}>
            Make sure to securely store your recovery phrase in a safe place.
            This phrase is crucial for recovering your wallet if you ever lose
            access to your device.
          </p>
          <div className={ButtonWrapper}>
            <Button className={BackBtn} onClick={handleButtonBack} dataId="wallet_info_back">
              <span className="flex items-center">
                <img
                  className={BackBtnIcon}
                  src={ARROW_BACK}
                  alt="Arrow icon"
                />{' '}
                Back
              </span>
            </Button>
            <Button
              className={`${ButtonWhite} ${ButtonNext}`}
              onClick={handleButtonNext}
              dataId="wallet_info_next"
            >
              Next
            </Button>
          </div>
        </div>
        <div
          className={StepContents}
          style={{ display: setCurrentStep === 3 ? 'block' : 'none' }}
        >
          <h2 className={HeadTitle}>Return to the platform</h2>
          <p className={WalletContentText}>
            Once your wallet is set up, navigate to your account on the betting
            platform. This will allow you to link your newly created wallet to
            your betting account and securely manage your funds.
          </p>
          <div className={ButtonWrapper}>
            <Button className={BackBtn} onClick={handleButtonBack} dataId="wallet_info_back">
              <span className="flex items-center">
                <img
                  className={BackBtnIcon}
                  src={ARROW_BACK}
                  alt="Arrow icon"
                />{' '}
                Back
              </span>
            </Button>
            <Button
              className={`${ButtonWhite} ${ButtonNext}`}
              onClick={handleButtonNext}
              dataId="wallet_info_next"
            >
              Next
            </Button>
          </div>
        </div>
        <div
          className={StepContents}
          style={{ display: setCurrentStep === 4 ? 'block' : 'none' }}
        >
          <h2 className={HeadTitle}>Video Tutorial</h2>
          <p className={WalletContentText}>
            Here&apos;s a step-by-step video tutorial guiding you through the process
            of creating your wallet.
          </p>
          <div className={ButtonWrapper}>
            <Button className={BackBtn} onClick={handleButtonBack} dataId="wallet_info_back">
              <span className="flex items-center">
                <img
                  className={BackBtnIcon}
                  src={ARROW_BACK}
                  alt="Arrow icon"
                />{' '}
                Back
              </span>
            </Button>
            <Button
              className={`${ButtonWhite} ${ButtonNext}`}
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
              dataId="wallet_info_end"
            >
              Continue
            </Button>
          </div>
        </div>
        <div className={NavDotsWrapper}>{navigationDots()}</div>
      </div>
    </CenterModal>
  );
};

export default WalletTutorials;
