import React from 'react';
import { ApplyBtn, ButtonWrapper, ClearBtn } from './FiltersButton.styled';
import Button from '#/components/common/Buttons/Button/Button.component';

type FiltersButtonProps = {
  clearFilters: () => void;
  applyFilters: () => void;
};

const FiltersButton: React.FC<FiltersButtonProps> = ({
  clearFilters,
  applyFilters,
}) => {
  return (
    <div className={ButtonWrapper}>
      <Button className={ClearBtn} onClick={clearFilters} dataId="clear_all_filters">
        Clear All
      </Button>
      <Button className={ApplyBtn} onClick={applyFilters} dataId="apply_filters">
        Apply
      </Button>
    </div>
  );
};

export default FiltersButton;
