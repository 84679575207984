export const ContentContainer = `w-full mt-2 mb-12`;
export const Header = `w-full flex items-center`;
export const PageTitle = `text-white font-normal text-sm xxxs:text-xl 2xl:text-2xl 3xl:text-2xl 4xl:text-3xl tracking-wider`;
export const HeaderIcon = `w-5 mr-4`;
export const ContentText = `text-sm 2xl:text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl text-text-gray-4 font-light`;
export const QRCodeImg = `w-48 mx-auto`;
export const FlexWrapper = `flex items-center justify-center`;
export const AddressText = `text-base xl:text-lg 3xl:text-xl 4xl:text-2xl text-white font-normal xl:tracking-wider xl:font-light`;
export const CopyIcon = `w-4.5 xl:w-5 ml-2`;
export const QRImgWrapper = `my-5 xl:mt-8 flex justify-center`;
export const ContentWrapper = `xl:mt-12 xl:mb-20`;
