export const CardContainer = `w-full`;
export const InnerContainer = `w-full flex justify-center items-center px-10 mt-12`;
export const TopBorder = `h-1 rounded-md w-32 bg-white mx-auto lg:hidden`;
export const ImgContainer = `w-full text-center mb-4`;
export const HeaderTitle = `text-base text-white font-bold tracking-wider mb-2`;
export const HeaderSubTitle = `text-sm text-white font-normal tracking-wider`;
export const InstallImg = `w-14 mx-auto`;
export const StepsWrapper = `w-full flex text-left mb-4`;
export const StepsNumber = `text-2xl text-text-brand-1 font-bold mr-4`;
export const InstallBtn = `w-full block text-center tracking-wider rounded-md px-4 py-3 text-black font-bold text-sm xxxs:text-base bg-brand-bg-1 mt-6`;
export const RedirectImg = `w-full border border-white rounded-md py-1 mt-5 mb-5`;
