import { Fragment, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import Button from '#/components/common/Buttons/Button/Button.component';
import AnimatedModal from '#/components/common/AnimatedModal.component';
import { WALLET_POPUP_ICON } from '#/constants/common/images.constants';
import { CONNECT_WALLET, LEARN_TEXT } from './ConnectWallet.constants';
import {
  CardContainer,
  IconWrapper,
  CardInnerContainer,
  CardHeader,
  HeaderTitle,
  CardIcon,
  ContentContainer,
  ContentTitle,
  ContentSubTitle,
  LearnLink,
  WalletIconWrapper,
  WalletNameText,
  PopularWalletWrapper,
  WalletPopular,
  WalletIconPopular,
  WalletOptionWrapper,
} from './ConnectWallet.styled';
import WalletTutorials from '../WalletTutorials/WalletTutorials.component';
import { instrumentWalletConnection } from '#/utils/sentry';
import useCosmosKitWallet from '#/hooks/useCosmosKitWallet';
import useCosmosKitWallets from '#/hooks/useCosmosKitWallets';
import { ChainWalletBase } from '@cosmos-kit/core';
import {
  WalletName,
  isInstalled,
  isSupported,
  walletLogo,
} from '#/utils/wallets';
import env from '#/utils/env';
import InstallKeplrPopup from '../InstallKeplrPopup/InstallKeplrPopup.component';
import platform, {
  KEPLR_ANDROID_APP,
  KEPLR_CHROME_EXTENSION,
  KEPLR_FIREFOX_EXTENSION,
  KEPLR_IOS_APP,
  LEAP_ANDROID_APP,
  LEAP_CHROME_EXTENSION,
  LEAP_IOS_APP,
} from '#/utils/platform';

export function getWalletInstallLink(walletName: string): string | undefined {
  switch (platform.platform.type) {
    case 'desktop': {
      switch (platform.browser.name) {
        case 'Firefox': {
          switch (walletName) {
            case WalletName.KeplrExtension:
              return KEPLR_FIREFOX_EXTENSION;
            case WalletName.LeapExtension:
              return undefined;
            case WalletName.LeapMobile:
              return undefined;
            case WalletName.KeplrMobile:
              return undefined;
          }

          break;
        }

        case 'Chrome': {
          switch (walletName) {
            case WalletName.KeplrExtension:
              return KEPLR_CHROME_EXTENSION;
            case WalletName.LeapExtension:
              return LEAP_CHROME_EXTENSION;
            case WalletName.LeapMobile:
              return undefined;
            case WalletName.KeplrMobile:
              return undefined;
          }

          break;
        }

        default:
          return undefined;
      }

      break;
    }

    case 'mobile': {
      switch (platform.os.name) {
        case 'Mac': {
          switch (platform.browser.name) {
            case 'Firefox': {
              switch (walletName) {
                case WalletName.KeplrExtension:
                  return KEPLR_FIREFOX_EXTENSION;
                case WalletName.LeapExtension:
                  return undefined;
                case WalletName.LeapMobile:
                  return LEAP_IOS_APP;
                case WalletName.KeplrMobile:
                  return KEPLR_IOS_APP;
              }

              break;
            }

            case 'Chrome': {
              switch (walletName) {
                case WalletName.KeplrExtension:
                  return KEPLR_CHROME_EXTENSION;
                case WalletName.LeapExtension:
                  return LEAP_CHROME_EXTENSION;
                case WalletName.LeapMobile:
                  return LEAP_IOS_APP;
                case WalletName.KeplrMobile:
                  return KEPLR_IOS_APP;
              }

              break;
            }

            default:
              return undefined;
          }

          break;
        }
        case 'Android': {
          switch (platform.browser.name) {
            case 'Firefox': {
              switch (walletName) {
                case WalletName.KeplrExtension:
                  return 'https://addons.mozilla.org/en-US/firefox/addon/keplr/';
                case WalletName.LeapExtension:
                  return undefined;
                case WalletName.LeapMobile:
                  return LEAP_ANDROID_APP;
                case WalletName.KeplrMobile:
                  return KEPLR_ANDROID_APP;
              }

              break;
            }

            case 'Chrome': {
              switch (walletName) {
                case WalletName.KeplrExtension:
                  return 'https://chromewebstore.google.com/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap';
                case WalletName.LeapExtension:
                  return 'https://chromewebstore.google.com/detail/leap-cosmos-wallet/fcfcfllfndlomdhbehjjcoimbgofdncg';
                case WalletName.LeapMobile:
                  return LEAP_ANDROID_APP;
                case WalletName.KeplrMobile:
                  return KEPLR_ANDROID_APP;
              }

              break;
            }

            default:
              return undefined;
          }

          break;
        }

        default:
          return undefined;
      }

      break;
    }

    default:
      return undefined;
  }
}

const ConnectWallet = ({
  isOpen,
  setOpen,
}: {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}) => {
  const [openTutorial, setOpenTutorial] = useState(false);
  const [instructionsForWallet, setInstructionsForWallet] = useState<
    ChainWalletBase | undefined
  >();

  const { connect } = useCosmosKitWallet();

  const connectOrInstall = useCallback(
    async (wallet: ChainWalletBase, walletInstalled: boolean | undefined) => {
      // undefined means "we don't know" here
      if (walletInstalled === false) {
        const link = getWalletInstallLink(wallet.walletName);

        if (link) {
          window.open(link, '_blank', 'noopener,noreferrer');
        } else {
          env.displayConnectionErrors &&
            alert(`No download link for ${wallet.walletName}`);
        }
      } else {
        try {
          await instrumentWalletConnection(wallet.walletName, async () => {
            await connect(wallet);
          });
        } catch (error: unknown) {
          const errorMessage = 'Error connecting to wallet';
          env.displayConnectionErrors && alert(JSON.stringify(error));

          toast.error(errorMessage);
        }
      }
    },
    [connect],
  );

  const { wallets } = useCosmosKitWallets();

  if (openTutorial) {
    return (
      <WalletTutorials
        isOpen={openTutorial}
        onClose={() => setOpenTutorial(false)}
      />
    );
  }

  if (instructionsForWallet) {
    switch (instructionsForWallet.walletName) {
      case WalletName.KeplrMobile:
        return (
          <InstallKeplrPopup
            isOpen={true}
            // Always treat the wallet as installed
            onClick={() => connectOrInstall(instructionsForWallet, true)}
            onClose={() => setInstructionsForWallet(undefined)}
          />
        );
    }
  }

  return (
    <AnimatedModal title="" isOpen={isOpen} onClose={() => setOpen(false)}>
      <div className={CardContainer}>
        <div className="text-left px-4 lg:px-6 pb-4 mb-4">
          <div className={IconWrapper}>
            <img
              className={CardIcon}
              src={WALLET_POPUP_ICON}
              alt="Wallet Icon"
            />
          </div>
          <div className={ContentContainer}>
            <h3 className={ContentTitle}>New to Crypto?</h3>

            <p className={ContentSubTitle}>
              A wallet is like a digital bank account for your cryptocurrency.
              It keeps your funds safe and lets you send or receive crypto.
            </p>
            <div className="mt-3">
              <Button
                className={LearnLink}
                onClick={() => setOpenTutorial(true)}
                dataId="learn_wallet_tutorial"
              >
                {LEARN_TEXT}
              </Button>
            </div>
          </div>
        </div>

        <div className={CardInnerContainer}>
          <div className={CardHeader}>
            <h2 className={HeaderTitle}>{CONNECT_WALLET}</h2>
          </div>
          <div className="mt-3">
            <div className={`${PopularWalletWrapper} mt-3`}>
              {wallets.length > 0 && (
                <div className={WalletOptionWrapper}>
                  {wallets.map((wallet) => {
                    if (isSupported(wallet)) {
                      const installed = isInstalled(wallet);

                      return (
                        <Button
                          key={wallet.walletName}
                          className={`${WalletPopular} ${installed ? 'highlighted' : ''} ${
                            installed ? '' : 'not-installed'
                          }`}
                          dataId="wallet_icon_connect"
                          onClick={() => {
                            switch (wallet.walletName) {
                              case WalletName.KeplrMobile:
                                setInstructionsForWallet(wallet);
                                break;
                              default:
                                connectOrInstall(wallet, installed);
                            }
                          }}
                          // disabled={isConnecting}
                        >
                          <span className={WalletIconWrapper}>
                            <img
                              className={WalletIconPopular}
                              src={walletLogo(wallet)}
                              alt={`${wallet.walletName} Logo`}
                            />
                          </span>
                          <span className={WalletNameText}>
                            {wallet.walletPrettyName}
                          </span>
                        </Button>
                      );
                    } else {
                      return <Fragment key={wallet.walletName}></Fragment>;
                    }
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AnimatedModal>
  );
};

export default ConnectWallet;
