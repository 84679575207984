import { isMobile } from '#/utils/platform';
import { useSearchParams } from 'react-router-dom';
import {
  ContentContainer,
  CheckInput,
  HeaderWrapper,
  Title,
  HeadDivider,
  FilterCardContainer,
  MarketLabel,
  MarketItemContainer,
} from './Transactions.styled';

interface TypeFilterProps {
  selectedType: { [key: string]: boolean };
  setSelectedType: (type: { [key: string]: boolean }) => void;
}

export default function TypeFilter({ selectedType, setSelectedType }: TypeFilterProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setSelectedType({
      ...selectedType,
      [id]: checked,
    });
    if (!isMobile) {
      let typeFilters = searchParams.get('typeFilter')?.split(',') || [];

      if (checked) {
        if (!typeFilters.includes(id)) {
          typeFilters.push(id);
        }
      } else {
        typeFilters = typeFilters.filter((filter) => filter !== id);
      }
      if (typeFilters.length > 0) {
        searchParams.set('typeFilter', typeFilters.join(','));
      } else {
        searchParams.delete('typeFilter');
      }

      setSearchParams(searchParams);
    }
  };

  return (
    <div className={ContentContainer}>
      <div className={HeaderWrapper}>
        <span className={Title}>
          <span>T</span>
          <span>y</span>
          <span>p</span>
          <span>e</span>{' '}
        </span>
      </div>
      <hr className={HeadDivider} />
      <div className={FilterCardContainer}>
        <ul>
          <li className={MarketItemContainer}>
            <input
              className={`${CheckInput} !mr-2`}
              type="checkbox"
              id="bet_placement"
              checked={selectedType.bet_placement || false}
              onChange={handleTypeChange}
            />
            <span className={MarketLabel}>Bet Placement</span>
          </li>
          <li className={MarketItemContainer}>
            <input
              className={`${CheckInput} !mr-2`}
              type="checkbox"
              id="reward"
              checked={selectedType.reward || false}
              onChange={handleTypeChange}
            />
            <span className={MarketLabel}>Reward</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
