import { SelectedLeagueFilter } from '#/components/Filters/Filters.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SportEnums } from '../backend';

export type LeagueFilterType = {
  marketType: number;
  leagueIds: number[];
};

export type QueryParams = {
  search: string;
  leagues: SelectedLeagueFilter[];
  filters: LeagueFilterType;
  offset?: number;
  sport?: SportEnums;
  startsAfter?: string;
  startsBefore?: string;
  favouritesOf?: string;
};

const initialState: QueryParams = {
  search: '',
  offset: 0,
  filters: {
    marketType: 1,
    leagueIds: [],
  },
  leagues: [], // Internal list of League objects
  sport: undefined,
  startsAfter: undefined,
  startsBefore: undefined,
  favouritesOf: undefined,
};

const searchSlice = createSlice({
  name: 'query',
  initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setOffset(state, action: PayloadAction<number | null>) {
      state.offset = action.payload || 0;
    },
    setMarketType(state, action: PayloadAction<number>) {
      state.filters.marketType = action.payload;
    },
    setLeagues(state, action: PayloadAction<SelectedLeagueFilter[]>) {
      state.leagues = action.payload;
      state.filters.leagueIds = action.payload.map((league) => league.Id); // Automatically update the IDs
    },
    removeLeague(state, action: PayloadAction<number>) {
      state.leagues = state.leagues.filter(
        (league) => league.Id !== action.payload,
      );
      state.filters.leagueIds = state.filters.leagueIds.filter(
        (id) => id !== action.payload,
      );
    },
    setSearchSport(state, action: PayloadAction<SportEnums | undefined>) {
      state.sport = action.payload || undefined;
    },
    setDateRange(
      state,
      action: PayloadAction<{
        startsAfter: string | null;
        startsBefore: string | null;
      }>,
    ) {
      state.startsAfter = action.payload.startsAfter || undefined;
      state.startsBefore = action.payload.startsBefore || undefined;
    },
    setFavouritesFilter(state, action: PayloadAction<string | null>) {
      state.favouritesOf = action.payload || undefined;
    },
  },
});

export const {
  setSearch,
  setOffset,
  setMarketType,
  setLeagues,
  removeLeague,
  setSearchSport,
  setDateRange,
  setFavouritesFilter,
} = searchSlice.actions;
export default searchSlice.reducer;
