import { useState, useCallback } from 'react';
import useBalance from '#/hooks/useBalance';
import env from '#/utils/env';
import { ExecuteInstruction, ExecuteResult } from '@cosmjs/cosmwasm-stargate';
import { instrumentTransaction } from '#/utils/sentry';
import {
  InsufficientGasError,
  MarketNotOperatingError,
  getWalletType,
  walletErrorParsers,
} from '#/errors/WalletError';
import useCosmosKitWallet from './useCosmosKitWallet';
import { toast } from 'react-toastify';
import { AppToast } from '#/components/common/ToastNotification/ToastConfig';
import * as Sentry from '@sentry/react';

/**
 * This file provides a custom hook for executing multiple contract messages and managing
 * centralized state for transaction execution. It exists because:
 * 1. Graz's executeContract doesn't handle multiple messages natively.
 * 2. It provides centralized state management for transaction status and errors.
 */

export type PartialExecuteInstruction = Omit<
  ExecuteInstruction,
  'contractAddress'
>;

export enum TxStatus {
  Idle = 'Idle',
  WalletNotReady = 'Wallet Not Ready',
  Executing = 'Executing Contract',
  Success = 'Success',
  Error = 'Error',
}

/**
 * Custom hook for executing multiple contract messages and managing transaction state.
 * @returns An object containing the executeMultiple function and transaction state.
 */
export const useExecute = () => {
  const contractAddress = env.contract;
  const { account, cosmWasmClient, wallet } = useCosmosKitWallet();
  const [status, setStatus] = useState<TxStatus>(TxStatus.Idle);
  const [error, setError] = useState<Error | null>(null);
  const [txHash, setTxHash] = useState<string | null>(null);
  const { refetch } = useBalance();

  /**
   * Executes multiple contract messages in a single transaction.
   * @param instructions - Array of partial execute instructions (without contract address).
   * @param options - Optional callbacks for success and error handling.
   * @returns A promise that resolves to the execution result.
   * @throws Error if the wallet is not ready or if the execution fails.
   */
  const executeMultiple = useCallback(
    async (
      instructions: PartialExecuteInstruction[],
      options?: {
        onSuccess?: (result: ExecuteResult) => void;
        onError?: (error: Error) => void;
      },
    ) => {
      const address = account?.address;

      if (!address || !cosmWasmClient || !wallet) {
        setStatus(TxStatus.WalletNotReady);
        throw new Error('Wallet is not ready');
      }

      setStatus(TxStatus.Executing);
      setError(null);
      setTxHash(null);

      try {
        const result = await instrumentTransaction(
          contractAddress,
          wallet.walletName,
          instructions,
          async () => {
            return await cosmWasmClient.executeMultiple(
              address,
              instructions.map((msg) => ({ ...msg, contractAddress })),
              'auto',
            );
          },
        );

        if (!result) {
          throw new Error('No data from contract execution');
        }

        setTxHash(result.transactionHash);
        setStatus(TxStatus.Success);
        options?.onSuccess?.(result);
        refetch();
        return result;
      } catch (err) {
        setStatus(TxStatus.Error);
        let error: Error;

        Sentry.captureException(err, {
          extra: {
            txInstructions: instructions
          }
        })

        if (
          err instanceof Error ||
          (err && typeof err === 'object' && 'message' in err)
        ) {
          const walletType = getWalletType(wallet.walletName);

          const parser =
            walletErrorParsers[
            walletType as unknown as keyof typeof walletErrorParsers
            ] || walletErrorParsers.default;

          const parsedError = parser.parse(err as Error);
          error = parsedError || (err as Error);
        } else {
          error = new Error('Unknown error during contract execution');
        }

        if (error instanceof InsufficientGasError) {
          toast.error(<AppToast id={'InsufficientGas'} />, { icon: false });
        }

        // The user should see which market it was in the slips drawer
        // in a very short period of time due to polling
        if (error instanceof MarketNotOperatingError) {
          toast.error(<AppToast id={'MarketNotOperational'} />, { icon: false });
        }

        setError(error);
        options?.onError?.(error);
        throw error;
      } finally {
        // Set back to idle after execution completes
        setStatus(TxStatus.Idle);
      }
    },
    [account?.address, cosmWasmClient, wallet, contractAddress, refetch],
  );

  return { executeMultiple, status, error, txHash };
};
