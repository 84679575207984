import { SearchedList } from '#/components/common/Search/SearchResults/SearchResults.types';
import { Sport } from '#/components/SportsFilter/SportsFilter.component';
import {
  CRICKET_ICON_DARK,
  CRICKET_ICON_LIGHT,
  FOOTBALL_ICON_DARK,
  FOOTBALL_ICON_LIGHT,
} from './images.constants';

export const SGE = 'sge';
export const FIXED_DECIMAL_PLACES = 4;
export const MOB_FIXED_DECIMAL_PLACES = 2;
export const TWO_DECIMAL_PLACES = 2;
export const BACK_ICON = 'Back icon';
export const FAV_ICON = 'Favourites icon';
export const LOG_OUT = 'Log Out';
export enum MODAL_ACTIONS {
  MINIMIZE = 'minimize',
  DISCONNECT = 'disconnect',
  CLOSE = 'close',
  NONE = 'none',
}
export enum MODAL_SIZES {
  SM = 'small',
  MD = 'medium',
  LG = 'large',
}
export const ODD_CHANGE_INTERVAL = 1000;
export const DEFAULT_ODD_TYPE = {
  label: 'Decimal',
  key: '1',
};
export const MAX_CAP_TOOLTIP_MSG = 'Bet capacity full. Check back later.';
// export const CAPACITY_MESSAGE_TIMEOUT = 1000;
export const DECIMAL = '1';
export const FRACTIONAL = '2';
export const AMERICAN = '3';
export const ODDS_DISPLAY_OPTIONS = [
  {
    label: 'Decimal',
    key: '1',
  },
  {
    label: 'Fractional',
    key: '2',
  },
  {
    label: 'American',
    key: '3',
  },
];
export const BET_MODE = 'bet';
export const HOUSE_MODE = 'house';
export const BET_LIMIT_REACHED = 'Bet Limit Reached!';
export const DEPOSIT_LIMIT_REACHED = 'Deposit Limit Reached!';
export const TEAM_NAME_LENGTH = 35;
export const TEAM_NAME_LENGTH_MOB = 18;
export const FAVOURITES = 'favourites';

export const BASE_PAGE_COUNT = 1;
export const DEFAULT_PAGE_SIZE = 10;

export const MAIN_MARKET_BANNER_TEXT = `• ELIGIBLE FOR UNLOCKING REWARD TOKENS •`;

export const SAMPLE_SPORT = {
  sport_uid: 'Soccer',
  name: 'football',
  image_dark: 'football-icon-dark.svg',
};

export const SPORTS_LIST: Sport[] = [
  {
    sport_uid: 'Soccer',
    name: 'football',
    image_light: FOOTBALL_ICON_LIGHT,
    image_dark: FOOTBALL_ICON_DARK,
    is_default: true,
  },
  {
    sport_uid: 'Cricket',
    name: 'cricket',
    image_light: CRICKET_ICON_LIGHT,
    image_dark: CRICKET_ICON_DARK,
    is_default: false,
  },
];

export const mockSearchedList: SearchedList[] = [];

export const DEFAULT_MARKET_TYPE = 1;
export const MAX_SLIP_LENGTH = 10;
export const MIN_BET_SLIP_AMOUNT = '1';
export const MIN_HOUSE_SLIP_AMOUNT = '10';
export const HOUSE_FEE_PERCENTAGE = 0.005;
