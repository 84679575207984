import {
  BANNER_DESKTOP, BANNER_MOBILE,
} from '#/constants/common/images.constants';

export default function PromotionBanner() {
  return (
    <div className='px-4 pb-4 3xl:pb-6 4xl:pb-8'>
      <a
        className='hidden md:block'
        href={"https://support.sixsigmasports.app/hc/en-us/articles/4558867838623-1st-Bet-Bonus-200-Promotion-0001"}
        target='_blank'
        rel="noreferrer"
      >
        <img className='w-full' src={BANNER_DESKTOP} alt="Banner" />
      </a>
      <a className='block md:hidden'
        href={"https://support.sixsigmasports.app/hc/en-us/articles/4558867838623-1st-Bet-Bonus-200-Promotion-0001"}
        target='_blank'
        rel="noreferrer"
      >
        <img className='w-full' src={BANNER_MOBILE} alt="Banner" />
      </a>
    </div>
  );
}
