import {
  getTodayRange,
  getTomorrowRange,
  getUpcomingRange,
} from '#/utils/datetime/datetime';
import {
  LeagueFilterType,
  setDateRange,
  setFavouritesFilter,
} from '#/utils/slices/searchSlice';
import { 
  AppState, 
  RedirectLoginOptions 
} from "@auth0/auth0-react";
import { Dispatch } from '@reduxjs/toolkit';

interface ApplyFilterOptions {
  isAuthenticated: boolean;
  user?: { sub: string }; // User sub type from Auth0
  loginWithRedirect: (options?: RedirectLoginOptions<AppState>) => Promise<void>
  timezone: string;
  filters: LeagueFilterType;
  filterParam: string | null;
}

/**
 * Helper to set the date range based on leagues selection and filter parameter.
 */
const handleDateRange = (
  startsAfter: string | null,
  startsBefore: string | null,
  dispatch: Dispatch,
  leagueIds: number[],
  currentFilter: string,
  filterParam: string | null,
) => {
  // If leagues are selected and filterParam is not the current filter, only set startsAfter
  if (leagueIds.length > 0 && filterParam !== currentFilter) {
    dispatch(setDateRange({ startsAfter, startsBefore: null }));
  } else {
    dispatch(setDateRange({ startsAfter, startsBefore }));
  }
};

/**
 * Applies the selected filter (today, tomorrow, upcoming, or favourite).
 * @param {string} filterType - The filter type to apply (today, tomorrow, etc.).
 * @param {Dispatch} dispatch - The Redux dispatch function to update state.
 * @param {ApplyFilterOptions} options - Additional options like authentication and timezone.
 */
export const applyFilter = (
  filterType: string,
  dispatch: Dispatch,
  { isAuthenticated, user, timezone, filters, filterParam, loginWithRedirect }: ApplyFilterOptions
): void => {
  const now = new Date();
  const clearFavourites = () => {
    dispatch(setFavouritesFilter(null));
  };

  switch (filterType) {
    case 'today': {
      const { startsAfter, startsBefore } = getTodayRange(now, timezone);
      handleDateRange(
        startsAfter,
        startsBefore,
        dispatch,
        filters.leagueIds,
        'today',
        filterParam,
      );
      clearFavourites();
      break;
    }
    case 'tomorrow': {
      const { startsAfter, startsBefore } = getTomorrowRange(now, timezone);
      handleDateRange(
        startsAfter,
        startsBefore,
        dispatch,
        filters.leagueIds,
        'tomorrow',
        filterParam,
      );
      clearFavourites();
      break;
    }
    case 'upcoming': {
      const { startsAfter } = getUpcomingRange(now, timezone);
      handleDateRange(
        startsAfter,
        null,
        dispatch,
        filters.leagueIds,
        'upcoming',
        filterParam,
      );
      clearFavourites();
      break;
    }
    case 'favourite': {
      if (isAuthenticated && user && user.sub) {
        dispatch(setFavouritesFilter(user.sub));
        handleDateRange(
          null,
          null,
          dispatch,
          filters.leagueIds,
          'favourite',
          filterParam,
        );
      } else {
        loginWithRedirect();
      }
      break;
    }
    default: {
      // Reset the filters if an unrecognized filterType is passed
      dispatch(setDateRange({ startsAfter: null, startsBefore: null }));
      clearFavourites();
    }
  }
};
