declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer?: Record<string, any>[];
  }
}

type OddTypeProps = {
  oddsType: string;
  oddValues: {
    american: string;
    decimal: string;
    fractional: string;
  };
  extended?: boolean;
};

import {
  AMERICAN,
  DECIMAL,
  FIXED_DECIMAL_PLACES,
  FRACTIONAL,
  ODDS_DISPLAY_OPTIONS,
  SGE,
  TWO_DECIMAL_PLACES,
} from '#/constants/common/global-constants';

// export const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

export function roundToDecimalPlaces(oddsValue: string, fixed: number) {
  // Handling for SGE case
  if (!oddsValue) {
    return `0 ${SGE}`;
  }
  if (oddsValue.includes(SGE)) {
    const [preSge, postSge] = oddsValue.split(' ');
    const [wholePart, decimalPart = ''] = preSge.split('.');
    const paddedDecimalPart = (decimalPart + '0'.repeat(fixed)).substring(
      0,
      fixed,
    );
    return `${wholePart}.${paddedDecimalPart} ${postSge}`;
  }

  // General case
  const [wholePart, decimalPart = ''] = oddsValue.split('.');
  const paddedDecimalPart = (decimalPart + '0'.repeat(fixed)).substring(
    0,
    fixed,
  );
  return `${wholePart}.${paddedDecimalPart}`;
}

export const getDisplayOddValue = ({
  oddsType,
  oddValues,
  extended = false,
}: OddTypeProps) => {
  const selected = ODDS_DISPLAY_OPTIONS.find(
    (item) => item.key === String(oddsType),
  );
  if (!selected?.key) {
    return roundToDecimalPlaces(
      oddValues.decimal,
      extended ? FIXED_DECIMAL_PLACES : TWO_DECIMAL_PLACES,
    );
  }
  if (selected?.key === DECIMAL)
    return roundToDecimalPlaces(
      oddValues.decimal,
      extended ? FIXED_DECIMAL_PLACES : TWO_DECIMAL_PLACES,
    );
  if (selected?.key === FRACTIONAL) return oddValues.fractional;
  if (selected?.key === AMERICAN)
    return (oddValues.american.includes('-') ? '' : '+') + oddValues.american;
  return roundToDecimalPlaces(oddValues.decimal, TWO_DECIMAL_PLACES);
};


export const gtmEvent = ({
  action,
  category = "",
  label = "",
  value = "",
  excessparams = {},
}: {
  action: string;
  category?: string;
  label?: string;
  value?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  excessparams?: Record<string, any>;
}) => {
  if (!window.dataLayer) {
    // eslint-disable-next-line no-console
    console.warn(
      "window.dataLayer is not defined. This could mean your google analytics script has not loaded on the page yet.",
    );
    return;
  }
  if (action === "click") {
    window.dataLayer.push({
      event_category: category,
      event_label: label,
      value,
      event: action,
    });
    return;
  }
  window.dataLayer.push({
    ...excessparams,
    event: action,
  });
};