import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HouseSlips, HouseSlip } from '../Slip';
import * as localStorageUtils from '../localStorage';
import { stringToBigdecimal } from '../bigdecimal';
import { toast } from 'react-toastify';
import { MAX_SLIP_LENGTH } from '#/constants/common/global-constants';
import { AppToast } from '#/components/common/ToastNotification/ToastConfig';
import { FixtureSummary } from '../backend';

const initialState: HouseSlips = {};

const slice = createSlice({
  name: 'houseSlips',
  initialState,
  reducers: {
    updateAmount: (
      state,
      data: PayloadAction<{ marketId: number; amount: string }>,
    ) => {
      if (state[data.payload.marketId]) {
        const parsedAmount = stringToBigdecimal(data.payload.amount);
        const amount = parsedAmount ? parsedAmount.toString() : '0';

        state[data.payload.marketId] = {
          ...state[data.payload.marketId],
          amount,
        };

        localStorageUtils.setHouseSlips(state);
      }
    },
    remove: (state, data: PayloadAction<string>) => {
      delete state[data.payload];

      localStorageUtils.setHouseSlips(state);
    },
    selectOrDeselect: (state, slip: PayloadAction<HouseSlip>) => {
      if (
        Object.keys(state).length >= MAX_SLIP_LENGTH &&
        !state[slip.payload.market.id]
      ) {
        toast.error(AppToast({ id: 'HouseslipFull' }), { icon: false });
        return;
      }
      state[slip.payload.market.id]
        ? delete state[slip.payload.market.id]
        : (state[slip.payload.market.id] = {
            amount: '0',
            slip: slip.payload,
          });

      localStorageUtils.setHouseSlips(state);
    },
    set: (state, slips: PayloadAction<HouseSlips>) => {
      Object.keys(slips.payload).forEach((marketId) => {
        const parsedAmount = stringToBigdecimal(slips.payload[marketId].amount);
        const amount = parsedAmount ? parsedAmount.toString() : '0';

        state[marketId] = {
          ...slips.payload[marketId],
          amount,
        };
      });
    },
    clear: (state) => {
      Object.keys(state).forEach((marketId) => {
        delete state[marketId];
      });

      localStorageUtils.setHouseSlips(state);
    },
  },
});

export const { remove, selectOrDeselect, updateAmount, set, clear } =
  slice.actions;

export const fixtureSummaryToHouseSlip = (fixtureSummary: FixtureSummary) : HouseSlip => {
  return {
    fixture: {
      id: fixtureSummary.fixtureId,
    },
    market: {
      id: fixtureSummary.id,
      name: fixtureSummary.marketTypeName,
      marketTypeId: fixtureSummary.marketTypeId,
    },
    homeTeam: {
      name: fixtureSummary.teams.Home.Name,
    },
    awayTeam: {
      name: fixtureSummary.teams.Away.Name,
    },
  }
}

export default slice.reducer;
