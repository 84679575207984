export const Flex1Container = `flex-1 w-full lg:cursor-pointer 2xl:self-stretch`;
export const FlexContainer = `flex`;
export const MatchNameContainer = `flex flex-wrap 7xl:flex-row 7xl:items-center`;
export const MatchSeparatorHidden = `hidden 7xl:inline mx-1 xl:mx-2 text-text-gray-2`;
export const MatchSeparatorMobile = `7xl:mx-2 text-text-gray-1 text-sm xl:text-base 2xl:text-lg 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl 6xl:my-1 7xl:hidden`;
export const Break = `md:hidden`;
export const MatchName = `text-xs sm:text-sm xl:text-base 2xl:text-lg 3xl:text-xl 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-text-gray-1 font-normal 7xl:mb-2 whitespace-nowrap lg:whitespace-normal xl:whitespace-nowrap`;
// Style for V2
export const MatchLabel = `text-sm xxxs:text-base xl:text-base 2xl:text-lg 3xl:text-xl 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-white font-normal tracking-wider whitespace-nowrap`;
export const LostBadge = `flex-none h-7 text-center uppercase text-sm text-text-danger-1 font-bold rounded-md px-2 py-1 bg-danger-bg-2 mr-2`;
export const WonBadge = `flex-none h-7 text-center uppercase text-sm text-text-success-1 font-bold rounded-md px-2 py-1 bg-success-bg-2 mr-2`;
