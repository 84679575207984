import env from "#/utils/env";
import { useAppDispatch, useAppSelector } from "#/utils/store";
import * as kadoEvents from "#/utils/slices/kadoSlice";
import AnimatedModal from "./common/AnimatedModal.component";

const Kado = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const isOpen = useAppSelector((state) => state.kado.open)

    return (
        <AnimatedModal backgroundClassName='kado' isOpen={env.kado.enabled && isOpen} title='Kado Money' onClose={() => { dispatch(kadoEvents.setOpen(false)) }}>
            <iframe src={`https://app.kado.money?apiKey=${env.kado.apiKey}&onPayCurrency=USD&onRevCurrency=USDC&offPayCurrency=USDC&offRevCurrency=USD&onPayAmount=100&offPayAmount=50&onToAddress=osmo...&cryptoList=USDC,USDT&fiatList=USD,CAD&network=osmosis&product=BUY`} width="500" height="700" style={{ border: '0px' }}></iframe>
        </AnimatedModal>
    );
};

export default Kado
