import {
  BET_EMPTYDATA_ERROR_ICON,
  EMPTY_DATA_ICON,
  HOUSE_EMPTYDATA_ERROR_ICON,
  NOTIFICATIONS_EMPTYDATA_ERROR_ICON,
  REWARDS_EMPTYDATA_ERROR_ICON,
  TRANSACTION_ERROR_ICON,
} from '#/constants/common/images.constants';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '#/components/common/Buttons/Button/Button.component';
import Link from '../Link.component';

const MainContainer = `flex h-full w-full justify-center items-center flex-col`;
const InnerContainer = `flex justify-center items-center flex-col`;
const Icon = `w-16 3xl:w-20 4xl:w-24 mb-5`;
const Title = `text-base font-normal text-white mb-2`;
const SubTitle = `text-sm xl:text-base 3xl:text-lg 4xl:text-2xl font-normal text-white`;
const ExploreText = `text-base 3xl:text-xl 4xl:text-3xl font-bold text-white`;

interface EmptyListConfig {
  title: string;
  subText: string;
  iconSrc: string;
  buttonText: string;
  buttonLink: string;
  buttonId: string;
  loginAction?: boolean;
}

const EmptyListConfig: Record<string, EmptyListConfig> = {
  bets: {
    title: 'Your bets will appear here',
    subText: 'Make your first bet to see it listed here.',
    iconSrc: BET_EMPTYDATA_ERROR_ICON,
    buttonText: '',
    buttonLink: '',
    buttonId: '',
  },
  betsLoggedOut: {
    title: 'You are not logged in.',
    subText: 'Log in and make your first bet to see it listed here.',
    iconSrc: BET_EMPTYDATA_ERROR_ICON,
    buttonText: 'Login',
    buttonLink: '',
    buttonId: 'empty_state_login',
    loginAction: true,
  },
  houseDeposits: {
    title: 'Your house activities will appear here',
    subText: 'Make your first engagement to see it listed here.',
    iconSrc: HOUSE_EMPTYDATA_ERROR_ICON,
    buttonText: '',
    buttonLink: '',
    buttonId: '',
  },
  houseDepositsLoggedOut: {
    title: 'You are not logged in.',
    subText: 'Log in and make your first engagement to see it listed here.',
    iconSrc: HOUSE_EMPTYDATA_ERROR_ICON,
    buttonText: 'Login',
    buttonLink: '',
    buttonId: 'empty_state_login',
    loginAction: true,
  },
  houseEngagements: {
    title: 'No Bets to Show',
    subText: 'Once a bet is matched with your provision, it will show here.',
    iconSrc: BET_EMPTYDATA_ERROR_ICON,
    buttonText: '',
    buttonLink: '',
    buttonId: '',
  },
  notifications: {
    title: 'Your notifications will appear here',
    subText: 'Check back here for updates.',
    iconSrc: NOTIFICATIONS_EMPTYDATA_ERROR_ICON,
    buttonText: '',
    buttonLink: '',
    buttonId: '',
  },
  rewards: {
    title: 'Your rewards will appear here',
    subText: 'Start exploring to earn rewards!',
    iconSrc: REWARDS_EMPTYDATA_ERROR_ICON,
    buttonText: '',
    buttonLink: '',
    buttonId: '',
  },
  search: {
    title: 'Swish and a miss!',
    subText: 'No results found. Try another query.',
    iconSrc: EMPTY_DATA_ICON,
    buttonText: 'EXPLORE ALL MATCHES',
    buttonLink: '/explore',
    buttonId: 'see_all_matches',
  },
  transactions: {
    title: 'Your transactions will appear here',
    subText: 'Make your first transaction to see it listed here.',
    iconSrc: TRANSACTION_ERROR_ICON,
    buttonText: '',
    buttonLink: '',
    buttonId: '',
  },
  walletDeposit: {
    title: 'No wallet connected',
    subText: 'Connect your wallet to see your wallet qr code here.',
    iconSrc: REWARDS_EMPTYDATA_ERROR_ICON,
    buttonText: '',
    buttonLink: '',
    buttonId: '',
  },
  homepage: {
    title: '',
    subText: 'No matches available',
    iconSrc: EMPTY_DATA_ICON,
    buttonText: '',
    buttonLink: '',
    buttonId: '',
  },
};

export default function EmptyList({
  id,
  mini = false,
}: {
  id: keyof typeof EmptyListConfig;
  mini?: boolean;
}): JSX.Element {
  const { title, subText, iconSrc, buttonText, buttonLink, buttonId, loginAction } =
    EmptyListConfig[id];
  const { loginWithRedirect } = useAuth0();

  return (
    <div className={MainContainer}>
      <div className={`${InnerContainer} ${mini ? 'my-4' : 'my-20'}`}>
        <div>
          <img className={Icon} src={iconSrc} alt="Icon" />
        </div>
        <div className="text-center">
          <h2 className={Title}>{title}</h2>
          <h3 className={SubTitle}>{subText}</h3>
        </div>
        {buttonText && buttonLink ? (
          <div className={`text-center ${mini ? 'mt-4' : 'mt-20'}`}>
            <Link to={buttonLink} dataId={buttonId}>
              <p className={ExploreText}>{buttonText}</p>
            </Link>
          </div>
        ) : null}
        {loginAction && buttonText ? (
          <div className={`text-center ${mini ? 'mt-4' : 'mt-20'}`}>
            <Button onClick={loginWithRedirect} dataId={buttonId} className={'hover:text-brand-bg-1'}>
              <span className={`${ExploreText} hover:text-brand-bg-1`}>{buttonText}</span>
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
