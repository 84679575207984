import { useCallback, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import {
  Title,
  TitleText,
  GroupMatchesCardContainer,
  GroupWrapper,
  TitleWrapper,
} from './GroupedMatches.styled';
import MatchCard from '../MatchCard/MatchCard.component';
import MatchShimmer from '../MatchShimmer/MatchShimmer.component';
import { FixtureSummary } from '#/utils/backend';
import EmptyList from '#/components/common/EmptyStates/EmptyState.component';
import { AppToast } from '#/components/common/ToastNotification/ToastConfig';
import { toast } from 'react-toastify';
import { useInfiniteSearchLoader } from '#/utils/dataLoaders/useInfiniteSearchLoader';
import { useAppSelector } from '#/utils/store';
import { useUpdateOutcomesAndMarkets } from '#/hooks/useUpdateOutcomesAndMarkets';
import useBackendUserData from '#/utils/dataLoaders/useBackendUserData';
import { DEFAULT_ODD_TYPE } from '#/constants/common/global-constants';

export default function GroupMatches() {
  const { data: user } = useBackendUserData();
  const searchQuery = useAppSelector((state) => state.search);
  const isSearchResult = !!searchQuery.search.length;
  const oddType = user?.oddsFormat ?? DEFAULT_ODD_TYPE.key;

  const getMatchCardProps = useCallback(
    (item: FixtureSummary) => {
      return { fixture: item, oddType };
    },
    [oddType]
  );

  const { ref, inView } = useInView();

  const {
    status,
    data,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteSearchLoader({ query: searchQuery });

  useUpdateOutcomesAndMarkets({ data });

  useEffect(() => {
    if (error?.message) {
      toast.error(<AppToast id={'UnableToFetch'} />, { icon: false });
    }
  }, [error?.message]);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView, hasNextPage, isFetchingNextPage]);

  const fixtureIds = new Set();

  const allData =
    data?.pages.reduce((acc, page) => {
      page.fixtures.forEach((fixture) => {
        if (!fixtureIds.has(fixture.fixtureId)) {
          acc.push(fixture);
          fixtureIds.add(fixture.fixtureId);
        }
      });
      return acc;
    }, [] as FixtureSummary[]) ?? [];

  return (
    <div className={GroupMatchesCardContainer}>
      <div className={GroupWrapper}>
        {status === 'success' && (
          <HeaderComponent totalItems={allData.length} isSearchResult={isSearchResult} />
        )}

        <div>
          {allData.map((fixture) => (
            <MatchCard key={fixture.id} {...getMatchCardProps(fixture)} />
          ))}
        </div>

        <div ref={ref}></div>

        <div>
          {(status === 'pending' || status === 'error' || isFetchingNextPage) && (
            <LoadingComponent count={allData.length === 0 ? 5 : 1} />
          )}
        </div>
      </div>
    </div>
  );
}

const LoadingComponent = ({ count }: { count: number }): JSX.Element => {
  const loaders = new Array(count).fill(1);
  return (
    <>
      {loaders.map((_, idx) => (
        <MatchShimmer key={idx} />
      ))}
    </>
  );
};

type HeaderComponentProps = {
  totalItems: number;
  isSearchResult: boolean;
};

const HeaderComponent = ({ totalItems, isSearchResult }: HeaderComponentProps): JSX.Element => {
  return (
    <>
      {totalItems === 0 ? (
        <EmptyList id={isSearchResult ? 'search' : 'homepage'} />
      ) : isSearchResult ? (
        <div className={TitleWrapper}>
          <span className={Title}>Search Results:</span>
          <span className={TitleText}>
            {`${totalItems} ${totalItems === 1 ? ' match' : ' matches'}`}
          </span>
        </div>
      ) : null}
    </>
  );
};
