import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MarketLiveData } from '../backend';

export type Markets = Record<string, MarketLiveData>;

interface State {
  markets: Markets;
}

const initialState: State = {
  markets: {},
};

const slice = createSlice({
  name: 'markets',
  initialState,
  reducers: {
    updateMarkets: (state, data: PayloadAction<Markets>) => {
      state.markets = {
        ...state.markets,
        ...data.payload,
      };
    },
  },
});

export const { updateMarkets } = slice.actions;
export default slice.reducer;

