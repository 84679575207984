export const AddressText = `text-base 3xl:text-xl text-white font-normal`;
export const DisconnectBtn = `text-base 3xl:text-xl text-black font-bold px-4 py-1.5 rounded-md bg-white text-center flex-none relative`;
export const AvailBalance = `flex space-x-3 text-3xl 4xl:text-4xl text-white font-bold px-4 mt-8`;
export const AvailBalanceText = `text-sm 4xl:text-xl text-text-brand-1 font-normal px-4 text-left`;
export const DecimalText = `text-2xl text-white font-normal`;
export const CardContainer = `w-full bg-primary-bg-3 rounded-t-2xl lg:rounded-t-llg -mt-4 pt-6`;
export const CardHeader = `flex justify-between px-4`;
export const RewardsBalance = `flex space-x-3 text-2xl 4xl:text-3xl text-white font-bold`;
export const TotalDecimalText = `text-lg text-white font-normal`;
export const InnerContainer = `w-full rounded-t-2xl lg:rounded-t-llg bg-primary-bg-1 lg:bg-primary-bg-2 py-4 lg:px-4 mt-3 divide-y divide-border-bg-1`;
export const CardFooter = `w-full flex justify-around pt-6`;
export const IconContainer = `w-full flex flex-col items-center text-xs 4xl:text-lg text-white font-light`;
export const CardFooterIcon = `w-5 4xl:w-7 mb-2`;
export const CopyIcon = `w-4.5 ml-2`;
export const FlexWrapper = `flex items-center`;
export const RewardsBalanceText = `text-sm 4xl:text-xl text-white font-normal px-4 text-left`;

export const DropDownStyle = `bg-primary-bg-3 overflow-hidden rounded-llg pt-4 absolute top-0 -right-2 mt-12 3xl:mt-16 h-auto z-[2] w-[420px] 3xl:w-[480px] [box-shadow:0px_0px_15px_5px_#FFFFFF1A;]`;
export const DropDownInnerWrapper = `overflow-y-auto max-h-[570px]`;
