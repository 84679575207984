/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  ContentContainer,
  ContentText,
  FieldContainer,
  InputLabel,
  InputField,
  BackIcon,
  PageTabTitle,
  SelectField,
} from '../ResponsibleGambling.styled';
import { NEXT_BRAND_ICON } from '#/constants/common/images.constants';
import SaveButton from './SaveButton.component';
import { toast } from 'react-toastify';
import { mapNumberToFrequency } from '../ResponsibleGambling.utils';
import CentralSpinLoader from '#/components/common/CentralSpinLoader';
import { setTimeLimit } from '#/utils/slices/responsibleGamblingSlice';
import { useDispatch } from 'react-redux';
import useBackendUserData from '#/utils/dataLoaders/useBackendUserData';
import ImgActionTag from '#/components/common/ImageActionTag.component';
import { gtmEvent } from '#/utils/appUtils';

export default function TimeLimit() {
  const dispatch = useDispatch();
  const { data: user, isLoading } = useBackendUserData();
  // Local state to manage the unsaved reminder frequency
  const [reminderFrequency, setReminderFrequency] = useState<string>('None');

  useEffect(() => {
    if (
      user &&
      user.responsibleGambling.timeLimit &&
      user.responsibleGambling.timeLimit !== undefined
    ) {
      dispatch(
        setTimeLimit({ reminderFrequency: user.responsibleGambling.timeLimit }),
      );
      const frequencyString = mapNumberToFrequency(
        user.responsibleGambling.timeLimit,
      );
      setReminderFrequency(frequencyString); // Set the local state without dispatching immediately
    }
  }, [user, dispatch]);

  // Handle changes in the reminder frequency (local state)
  const handleFrequencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!user) {
      toast.info(`Please login to save reminder frequency`);
      return;
    }
    const newFrequency = e.target.value;
    setReminderFrequency(newFrequency); // Update local state, not global state
    gtmEvent({ action: "time_limit_changed" });
  };

  const [, setSearchParams] = useSearchParams();

  // Handle navigation back
  const handleBackClick = () => {
    setSearchParams({});
  };

  return (
    <div className={ContentContainer}>
      <h3 className={`${PageTabTitle} mb-4`}>
        <ImgActionTag
          className={BackIcon}
          src={NEXT_BRAND_ICON}
          alt="Back Icon"
          onClick={handleBackClick}
          dataId="navigate_back"
        />
        Time Limit
      </h3>
      <p className={ContentText}>
        {`To support you in managing the amount of time you spend playing, you can set up a Reality Check on your account. Once this is set, a pop-up alert will be displayed as a reminder that you have been logged into your account for the specified period of time. The Reality Check timer starts when you log in. If you make any changes to this, the new setting will only take effect the next time you log in. If you wish to reduce your Reality Check setting, the changes selected will be applied straight away. When the Reality Check alert is received you can choose to remain logged in, view your account history or log out of your account. If you choose to remain logged in then vou will receive another alert after the same length of time so that you can keep track of your total time spent playing.`}
      </p>
      <div className="mt-5 relative">
        <div className={FieldContainer}>
          <label htmlFor="reminder_frequency" className={InputLabel}>
            Reminder Frequency
          </label>
          <select
            className={`${InputField} ${SelectField}`}
            id="reminder_frequency"
            value={reminderFrequency}
            onChange={handleFrequencyChange}
          >
            <option value="None">None</option>
            <option value="Every 30 Mins">Every 30 Mins</option>
            <option value="Every 1 Hour">Every 1 Hour</option>
            <option value="1 Day">1 Day</option>
          </select>
        </div>
        <SaveButton reminderFrequency={reminderFrequency} />{' '}
        {/* Use the local handleSave */}
      </div>
      {isLoading ? <CentralSpinLoader /> : null}
    </div>
  );
}
