import { FILTER_ICON_LIGHT } from '#/constants/common/images.constants';
import ActionTag from './ActionTags.component';

const ExploreIcon = `w-6 mr-2`;
export default function FilterButton({ onClick }: { onClick: () => void }) {
  return (
    <ActionTag
      className="inline-block ml-2"
      dataId="filter_button"
      onClick={onClick}
      type="div"
    >
      <img src={FILTER_ICON_LIGHT} className={ExploreIcon} alt="Filter Icon" />
    </ActionTag>
  );
}
