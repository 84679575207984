import { toast } from 'react-toastify';
import { AppToast } from '../common/ToastNotification/ToastConfig';
import {
  Fixture,
  FixtureSummary,
  Odds,
  outcomeId,
} from '#/utils/backend';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '#/utils/store';
import { getAllMarketCategories } from '#/utils/marketsMap';
import MatchDetailsHeader from '../MatchDetails/MatchDetails.component';
import FixtureTabs from '../MatchDetails/MatchDetailsFixtureTab/MatchDetailsFixtureTab.component';
import Markets from '../Markets';
import { fixtureSummaryToHouseSlip, selectOrDeselect as selectOrDeselectHouse } from '#/utils/slices/houseSlipsSlice';
import * as houseTutorialEvents from '#/utils/slices/houseTutorialSlice';
import { selectOrDeselect as selectOrDeselectBet } from '#/utils/slices/betSlipsSlice';
import useFixtureData from '#/utils/dataLoaders/useFixtureData';
import { Helmet } from 'react-helmet-async';
import { isProductionEnv } from '#/utils/env';
import { DataState } from '../WithHandledStates.component';

function MatchDetailComponent({
  dataState
}: {
  dataState: DataState<Fixture>
}) {
  const [fixtureTabs, setFixtureTabs] = useState(getAllMarketCategories([]));
  const [fixtureTabSelected, selectFixtureTab] = useState(fixtureTabs[0]);
  const dispatch = useAppDispatch();
  const rewardCalculation = useAppSelector((state) => state.rewardsCalculation);
  const showHouseTutorials = useAppSelector((state) => state.houseTutorial.showHouseTutorialsOnAddHouseSlip)

  useEffect(() => {
    if (!dataState.isLoading && dataState.data) {
      setFixtureTabs(getAllMarketCategories(Object.keys(dataState.data.markets)));
    }
  }, [dataState.isLoading, dataState.data]);

  function addHouseSlip(fixtureSummary: FixtureSummary) {
    if(showHouseTutorials) {
      dispatch(
        houseTutorialEvents.open(fixtureSummary)
      )
    } else {
      dispatch(
        selectOrDeselectHouse(
          fixtureSummaryToHouseSlip(fixtureSummary)
        )
      );    
    }
  }

  function addBetSlip(market: FixtureSummary, odd: Odds) {
    const slip = {
      fixture: {
        id: market.fixtureId,
      },
      market: {
        id: market.id,
        name: market.marketTypeName,
        marketTypeId: market.marketTypeId,
      },
      outcome: {
        id: outcomeId(odd),
        position: odd.outcomePosition,
        name: odd.outcomeName,
        acceptedRate: odd.value,
      },
      homeTeam: {
        name: market.teams.Home.Name,
      },
      awayTeam: {
        name: market.teams.Away.Name,
      },
      slippageParams: {
        slippage: '0',
        odds: odd.value,
      },
      league: {
        id: market.league.Id,
      },
      sport: {
        id: market.sport,
      },
    };

    dispatch(
      selectOrDeselectBet({
        slip,
        campaigns: rewardCalculation.campaignsApplicable,
        totalRewardsAvailable: rewardCalculation.totalRewardsAvailable,
      }),
    );
  }

  const result = dataState.data

  return (
    <div className="px-0">
      <Helmet>
        {result?.teams && (
          <title>{`${result.teams.Home.Name} vs ${result.teams.Away.Name} | SixSigmaSports`}</title>
        )}
        {!isProductionEnv() ? <meta name="robots" content="noindex" /> : null}
      </Helmet>

      <MatchDetailsHeader data={dataState} />

      <div className="w-full">
        <div className="px-4 mt-4">
          <FixtureTabs
            fixtureTabNames={fixtureTabs}
            selectFixtureTab={selectFixtureTab}
            fixtureTabSelected={fixtureTabSelected}
          />
        </div>
        <div className="pt-2 px-4">
          <Markets
            data={dataState}
            fixtureTabSelected={fixtureTabSelected}
            addBetSlip={addBetSlip}
            addHouseSlip={addHouseSlip}
          />
        </div>
      </div>
    </div>
  );
}

export default function FixtureDetails() {
  const { fixtureId } = useParams();

  const fixtureQuery = useFixtureData({
    id: fixtureId || '',
    enabled: !!fixtureId,
  });

  const { error, data, isLoading } = fixtureQuery;

  if (!fixtureId) {
    return <div>No fixture id</div>;
  }

  if (error) {
    toast.error(<AppToast id={'UnableToFetch'} />, { icon: false });
  }

  return (
    <div>
      <MatchDetailComponent dataState={fixtureQuery} />
    </div>
  );
}
