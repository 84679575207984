/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  CheckInput,
  HeaderWrapper,
  Title,
  HeadDivider,
  FilterCardContainer,
  MarketItemContainer,
  MarketLabel,
} from '../FilterCards/FilterCards.styled';
import { MARKET_TYPES_MAP } from '#/utils/marketsMap';
import ActionTag from '../common/ActionTags.component';
import { setMarketTypeIdFilter, toggleMarketTypeIdFilterAndSynchUrl } from '#/utils/slices/activityFiltersSlice';
import { isMobile } from '#/utils/platform';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const MarketFilters = ({
  localMarketTypeIds,
  setLocalMarketTypeIds,
}: {
  localMarketTypeIds: number[];
  setLocalMarketTypeIds: (ids: number[]) => void;
}) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const handleMarketChange = (marketTypeId: number) => {
    const updatedFilters = localMarketTypeIds.includes(marketTypeId)
      ? localMarketTypeIds.filter((id) => id !== marketTypeId)
      : [...localMarketTypeIds, marketTypeId];
    if (isMobile) {
      setLocalMarketTypeIds(updatedFilters);
    } else {
      dispatch(setMarketTypeIdFilter(updatedFilters));
      dispatch(toggleMarketTypeIdFilterAndSynchUrl({
        marketTypeIds: updatedFilters,
        searchParams,
        setSearchParams,
      }))
    }
  };

  return (
    <>
      <div className={HeaderWrapper}>
        <span className={Title}>Market</span>
      </div>
      <hr className={HeadDivider} />
      <div className={FilterCardContainer}>
        <ul>
          {Object.values(MARKET_TYPES_MAP.map).map((marketType) => (
            <ActionTag
              key={marketType.id}
              className={MarketItemContainer}
              onClick={() => handleMarketChange(marketType.id)}
              dataId={`${marketType.marketName.toLowerCase().replaceAll(" ", "_")}_filter_clicked`}
            >
              <input
                className={`${CheckInput} !mr-0`}
                type="checkbox"
                id={String(marketType.id)}
                checked={localMarketTypeIds.includes(marketType.id)}
              />
              <span className={MarketLabel}>{marketType.marketName}</span>
            </ActionTag>
          ))}
        </ul>
      </div>
    </>
  );
};

export default MarketFilters;
