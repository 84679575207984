import { useSearchParams } from 'react-router-dom';
import { TabsSubItems, TabsWrapper } from './ActivityTab.styled';
import Button from '#/components/common/Buttons/Button/Button.component';
import { BET_ACTIVITY, HOUSE_ACTIVITY, OUTCOME_OPTIONS, SubItem, Tab } from '#/components/routes/MyBets';
import { useAppDispatch } from '#/utils/store';
import { setOutcomeFilters, toggleOutcomeFilterAndSynchUrl } from '#/utils/slices/activityFiltersSlice';

export function SubTabs({ activeTab }: { activeTab: Tab }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const activeSubItem = (searchParams.get('subItem') as SubItem) ||
    (activeTab === BET_ACTIVITY ? 'active' : 'engagement');

  const handleSubItemClick = (subItem: SubItem) => {
    searchParams.set('subItem', subItem);
    setSearchParams(searchParams);
    dispatch(setOutcomeFilters(OUTCOME_OPTIONS[activeTab][subItem]));
    dispatch(
      toggleOutcomeFilterAndSynchUrl({
        outcomeFilters: OUTCOME_OPTIONS[activeTab][subItem],
        searchParams,
        setSearchParams,
      })
    );
  };

  return (
    <>
      {activeTab === BET_ACTIVITY && (
        <div className={`${TabsWrapper} mb-4`}>
          <Button
            className={TabsSubItems({
              isActive: activeSubItem === 'active',
            })}
            onClick={() => handleSubItemClick('active')}
            dataId="active_bet_activity_tab"
          >
            <span>Active Bets</span>
          </Button>
          <Button
            className={TabsSubItems({
              isActive: activeSubItem === 'settled',
            })}
            onClick={() => handleSubItemClick('settled')}
            dataId="settled_bet_activity_tab"
          >
            <span>Settled Bets</span>
          </Button>
        </div>
      )}

      {activeTab === HOUSE_ACTIVITY && (
        <div className={`${TabsWrapper} mb-4`}>
          <Button
            className={TabsSubItems({
              isActive: activeSubItem === 'active',
            })}
            onClick={() => handleSubItemClick('active')}
            dataId="active_house_activity_tab"
          >
            <span>Active</span>
          </Button>
          <Button
            className={TabsSubItems({
              isActive: activeSubItem === 'settled',
            })}
            onClick={() => handleSubItemClick('settled')}
            dataId="settled_house_activity_tab"
          >
            <span>Settled</span>
          </Button>
        </div>
      )}
    </>
  );
}
