import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterCards from '../FilterCards';
import Modal from '../common/Modal.component';
import FiltersButton from './FiltersButton.component';
import {
  ContinentLeaguesMap,
  FiltersProps,
  SelectedLeagueFilter,
} from './Filters.types';
import { setLeagues, setMarketType } from '#/utils/slices/searchSlice';
import { RootState } from '#/utils/store';
import { DEFAULT_MARKET_TYPE } from '#/constants/common/global-constants';
import CollapseCard from '../CollapseCard';
import { loadLeagues } from '#/utils/backend';
import { HeadDivider } from '../FilterCards/FilterCards.styled';
import { useQuery } from '@tanstack/react-query';

const ContinentTitle = `text-base text-white font-light uppercase`;

const Filters: React.FC<FiltersProps> = ({ isOpen, onClose }) => {
  return (
    <Modal title="Filter" isOpen={isOpen} onClose={onClose}>
      <ExploreFilterContents onClose={onClose} />
    </Modal>
  );
};
export default Filters;


export function ExploreFilterContents({ onClose }: { onClose: () => void }) {
  const selectedLeagues = useSelector(
    (state: RootState) => state.search.filters.leagueIds,
  );
  const { data: leagues, isLoading } = useQuery({
    queryKey: ['leagues'],
    queryFn: async () => {
      return loadLeagues();
    },
  });

  const [leagueFilters, setLeagueFilters] = useState<number[]>(
    selectedLeagues || [],
  );
  const dispatch = useDispatch();

  const clearFilters = () => {
    dispatch(setLeagues([]));
    dispatch(setMarketType(DEFAULT_MARKET_TYPE));
    setLeagueFilters([]);
  };

  const setSelectedLeagues = (newLeagues: number[]) => {
    const leaguesSelected = newLeagues
      .map((id) => leagues?.find((league) => league.Id === id))
      .filter((league): league is SelectedLeagueFilter => !!league); // Filter out any undefined values

    dispatch(setLeagues(leaguesSelected));
  }

  const applyFilters = () => {
    setSelectedLeagues(leagueFilters);
    onClose();
  };

  const leaguesByContinent = useMemo((): ContinentLeaguesMap => {
    if (!leagues) return {};

    // Filter leagues to only include those with HasOperationalMarkets === true
    const filteredLeagues = leagues.filter(
      (league) => league.HasOperationalMarkets,
    );

    return filteredLeagues.reduce(
      (acc: ContinentLeaguesMap, league: SelectedLeagueFilter) => {
        const continent = league.Country?.continent || 'Unknown';
        if (!acc[continent]) {
          acc[continent] = [];
        }
        acc[continent].push(league);
        return acc;
      },
      {},
    );
  }, [leagues]);


  return (
    <div className="w-full p-5">
      <div className="mb-5 overflow-y-auto space-y-2 max-h-[400px] pr-3" >
        <FilterCards
          title="Top Leagues"
          clearFilters={clearFilters}
          setLeagueFilters={setLeagueFilters}
          leagueFilters={leagueFilters}
          isLoading={isLoading}
          leagues={
            leagues?.filter((league) => league.HasOperationalMarkets) || []
          }
          setSelectedLeagues={setSelectedLeagues}
        />
        {Object.keys(leaguesByContinent).map((continent) => (
          <>
            <CollapseCard
              key={continent}
              title={<span className={ContinentTitle}>{continent}</span>}
            >
              <FilterCards
                title="Leagues"
                clearFilters={clearFilters}
                setLeagueFilters={setLeagueFilters}
                leagueFilters={leagueFilters}
                isLoading={isLoading}
                leagues={leaguesByContinent[continent]}
                isContinentFilter={true}
                setSelectedLeagues={setSelectedLeagues}
              />
            </CollapseCard>
            <hr className={HeadDivider} />
          </>
        ))}
      </div>
      <FiltersButton
        clearFilters={clearFilters}
        applyFilters={applyFilters}
      />
    </div>
  );
}
