import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BetSlip, BetSlips } from '../Slip';
import * as localStorageUtils from '../localStorage';
import { stringToBigdecimal } from '../bigdecimal';
import { toast } from 'react-toastify';
import { MAX_SLIP_LENGTH } from '#/constants/common/global-constants';
import { AppToast } from '#/components/common/ToastNotification/ToastConfig';
import {
  checkRewardSpendingEligibility,
  redistributeRewards,
} from '../sgeUtils/sgeUtils';
import { CampaignDetails } from '../backend';

const initialState: BetSlips = {};

const slice = createSlice({
  name: 'betSlips',
  initialState,
  reducers: {
    updateAmount: (
      state,
      data: PayloadAction<{
        outcomeId: string;
        amount: string;
        totalRewardsAvailable: number;
      }>,
    ) => {
      if (state[data.payload.outcomeId]) {
        const parsedAmount = stringToBigdecimal(data.payload.amount);
        const amount = parsedAmount ? parsedAmount.toString() : '0';

        state[data.payload.outcomeId] = {
          ...state[data.payload.outcomeId],
          amount,
        };

        const redistributed = redistributeRewards(
          state,
          data.payload.totalRewardsAvailable,
        );
        redistributed.forEach((slipItem) => {
          state[slipItem.outcomeId] = {
            ...state[slipItem.outcomeId],
            rewardAmount: slipItem.rewardAmount,
          };
        });

        localStorageUtils.setBetSlips(state);
      }
    },
    updateAcceptedRate: (
      state,
      data: PayloadAction<{
        outcomeId: string;
        acceptedRate: string;
        campaigns: CampaignDetails[];
        totalRewardsAvailable: number;
      }>,
    ) => {
      if (state[data.payload.outcomeId]) {
        const parsedRate = stringToBigdecimal(data.payload.acceptedRate);
        const newAcceptedRate = parsedRate ? parsedRate.toString() : '0';

        const newOutcome = {
          ...state[data.payload.outcomeId].slip.outcome,
          acceptedRate: newAcceptedRate,
        };

        const rewardSpendingEligibility = checkRewardSpendingEligibility(
          {
            ...state[data.payload.outcomeId].slip,
            outcome: newOutcome,
          },
          data.payload.campaigns,
        );

        state[data.payload.outcomeId] = {
          ...state[data.payload.outcomeId],
          slip: {
            ...state[data.payload.outcomeId].slip,
            outcome: newOutcome,
          },
          rewardSpendingEligibility: rewardSpendingEligibility,
        };

        const redistributed = redistributeRewards(
          state,
          data.payload.totalRewardsAvailable,
        );
        redistributed.forEach((slipItem) => {
          state[slipItem.outcomeId] = {
            ...state[slipItem.outcomeId],
            rewardAmount: slipItem.rewardAmount,
          };
        });

        localStorageUtils.setBetSlips(state);
      }
    },
    updateUsingRewards: (
      state,
      data: PayloadAction<{
        outcomeId: string;
        usingRewards: boolean;
        totalRewardsAvailable: number;
      }>,
    ) => {
      if (state[data.payload.outcomeId]) {
        state[data.payload.outcomeId] = {
          ...state[data.payload.outcomeId],
          usingRewards: data.payload.usingRewards,
        };

        const redistributed = redistributeRewards(
          state,
          data.payload.totalRewardsAvailable,
        );
        redistributed.forEach((slipItem) => {
          state[slipItem.outcomeId] = {
            ...state[slipItem.outcomeId],
            rewardAmount: slipItem.rewardAmount,
          };
        });

        localStorageUtils.setBetSlips(state);
      }
    },
    remove: (state, data: PayloadAction<string>) => {
      delete state[data.payload];

      localStorageUtils.setBetSlips(state);
    },
    selectOrDeselect: (
      state,
      data: PayloadAction<{
        slip: BetSlip;
        totalRewardsAvailable: number;
        campaigns: CampaignDetails[];
      }>,
    ) => {
      if (
        Object.keys(state).length >= MAX_SLIP_LENGTH &&
        !state[data.payload.slip.outcome.id]
      ) {
        toast.error(AppToast({ id: 'BetslipFull' }), { icon: false });
        return;
      }
      if (state[data.payload.slip.outcome.id]) {
        delete state[data.payload.slip.outcome.id];

        const redistributed = redistributeRewards(
          state,
          data.payload.totalRewardsAvailable,
        );
        redistributed.forEach((slipItem) => {
          state[slipItem.outcomeId] = {
            ...state[slipItem.outcomeId],
            rewardAmount: slipItem.rewardAmount,
          };
        });
      } else {
        state[data.payload.slip.outcome.id] = {
          amount: '0',
          slip: data.payload.slip,
          rewardSpendingEligibility: checkRewardSpendingEligibility(
            data.payload.slip,
            data.payload.campaigns,
          ),
          rewardEarningEligibility: false,
          rewardAmount: 0,
          usingRewards: false,
        };
      }

      localStorageUtils.setBetSlips(state);
    },
    set: (state, slips: PayloadAction<BetSlips>) => {
      Object.keys(slips.payload).forEach((outcomeId) => {
        const parsedAmount = stringToBigdecimal(
          slips.payload[outcomeId].amount,
        );
        const amount = parsedAmount ? parsedAmount.toString() : '0';

        state[outcomeId] = {
          ...slips.payload[outcomeId],
          amount,
        };
      });
    },
    clear: (state) => {
      Object.keys(state).forEach((oddsId) => {
        delete state[oddsId];
      });

      localStorageUtils.setBetSlips(state);
    },
  },
});

export const {
  remove,
  selectOrDeselect,
  updateAmount,
  updateAcceptedRate,
  set,
  clear,
  updateUsingRewards,
} = slice.actions;
export default slice.reducer;
