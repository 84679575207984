import { SearchListContainer2 } from './SearchResults.styled';
import GroupMatches from '#/components/MatchCard/GroupedMatches/GroupedMatches.component';

const SearchResults = () => {
  return (
    <div className={SearchListContainer2}>
      <GroupMatches />
    </div>
  );
};

export default SearchResults;
